import actions from "./actions";

const standard = {
	saveLoad: [actions.general.SAVE, actions.general.LOAD],
	prevNext: [actions.general.PREV, actions.general.NEXT, actions.strategy.LATEST],
	exFold: [actions.strategy.EXPAND, actions.strategy.FOLD, actions.strategy.EXPORT],
	summary: [actions.strategy.STRATEGYSUMMARY, actions.strategy.LEGEND, actions.strategy.CYCLE],
	visualControlMenu: [
		actions.strategy.EDGEWIDTH,
		actions.strategy.TOOLTIP,
		actions.strategy.FLEXPATHS,
		actions.strategy.HIGHLIGHTFLEXPATHS,
	],
};

const standardSaveDisable = {
	saveLoad: [actions.general.SAVEDISABLED, actions.general.LOAD],
	...standard,
};

const savedList = {
	...standard,
	summary: [actions.strategy.STRATEGYSUMMARY],
	saveLoad: [],
};

const queueList = {
	...savedList,
};

const load = {
	...savedList,
	prevNext: [],
};

const search = {
	...standard,
};

const recommended = {
	...standard,
};

const plan = {
	...standard,
};

const topLevel = {
	...standard,
};

const configCombination = {
	standard,
	standardSaveDisable,
	savedList,
	queueList,
	load,
	search,
	recommended,
	plan,
	topLevel,
};

const config = (configType) => {
	const actionConfig = configCombination[configType ?? "standard"];
	return {
		items: [
			{
				key: "saveLoad",
				type: "buttonGroup",
				items: actionConfig.saveLoad,
			},
			{
				key: "prevNext",
				type: "buttonGroup",
				items: actionConfig.prevNext,
			},
			{
				key: "summary",
				type: "buttonGroup",
				items: actionConfig.summary,
			},
			{
				key: "rightGroup",
				type: "group",
				classes: "flex ml-auto",
				items: [
					{
						key: "exFold",
						type: "buttonGroup",
						items: actionConfig.exFold,
					},
					{
						key: "visualControlMenu",
						type: "menu",
						icon: "tune",
						button: {
							color: "secondary",
							variant: "text",
							classes: { root: "" },
						},
						classes: "",
						items: actionConfig.visualControlMenu,
					},
				],
			},
		],
		classes: "",
	};
};

export default config;
