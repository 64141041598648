import { useMemo, useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { showSnackbar } from "app/main/utils/snackbarUtil";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { increaseNotification, fetchTransactions } from "app/store/transactions/transactionsSlice";

import SmarthopFormView from "@smarthop/form/SmarthopFormView";

import { createInternalLoadFrom } from "./config/internalLoadConfig";

import { createInternalLoads } from "app/services/brokersServices.js";

const DEFAULT_DESTINATIONS = [
	{
		value: "Miami, FL",
		label: "Miami, FL",
		description: "FL",
		metadata: {
			coords: {
				lat: "25.773890",
				long: "-80.193890",
			},
			city: "Miami",
			state: "FL",
			country: "USA",
		},
	},
	{
		value: "New York, NY",
		label: "New York, NY",
		description: "NY",
		metadata: {
			coords: {
				lat: "40.713578",
				long: "-74.005755",
			},
			city: "New York",
			state: "NY",
			country: "USA",
		},
	},
	{
		value: "Chicago, IL",
		label: "Chicago, IL",
		description: "IL",
		metadata: {
			coords: {
				lat: "41.849717",
				long: "-87.648611",
			},
			city: "Chicago",
			state: "IL",
			country: "USA",
		},
	},
	{
		value: "Houston, TX",
		label: "Houston, TX",
		description: "TX",
		metadata: {
			coords: {
				lat: "29.763060",
				long: "-95.363060",
			},
			city: "Houston",
			state: "TX",
			country: "USA",
		},
	},
	{
		value: "Los Angeles, CA",
		label: "Los Angeles, CA",
		description: "CA",
		metadata: {
			coords: {
				lat: "34.052220",
				long: "-118.242780",
			},
			city: "Los Angeles",
			state: "CA",
			country: "USA",
		},
	},
];

const DEFAULT_BROKERS = [
	{
		value: "63db01ed5bb9fc4ff8b0ec87",
		label: "Coyote Logistics Llc",
		description: "MC 561135",
		metadata: {
			mcnumber: 561135,
			clientid: "Coyote Logistics Llc",
		},
	},
	{
		value: "63db01ec5bb9fc4ff8b0ebec",
		label: "Echo Global Logistics Inc",
		description: "MC 511639",
		metadata: {
			mcnumber: 511639,
			clientid: "Echo Global Logistics Inc",
		},
	},
	{
		value: "63da76810ad9d30d6e433054",
		label: "CH Robinson",
		description: "MC 384859",
		metadata: {
			mcnumber: 384859,
			clientid: "CH Robinson",
		},
	},
	{
		value: "63d9b06ce92beb4b874a38c3",
		label: "Emerge Transportation",
		description: "MC 945637",
		metadata: {
			mcnumber: 945637,
			clientid: "Emerge Transportation",
		},
	},
];

const LOAD_DEFAULTS = {
	rpm__min: 1.5,
	rpm__max: 3.0,
	miles__min: 100,
	miles__max: 1200,
	total_loads: 20,
	book_loads: 2,
	bid_loads: 1,
	best_loads: 1,
	weight: 44000,
	location_destination__view: DEFAULT_DESTINATIONS,
	brokers__view: DEFAULT_BROKERS,
};

function InternalLoadView(props) {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const user = useSelector(({ auth }) => auth.user);
	const onDone = props.onDone;
	const carrierId = props.carrierId ?? props?.dataIds?.carrierId;
	const mode = props?.mode ?? props?.dataIds?.mode ?? "CREATE";
	const searchData = props?.dataIds;

	const [processing, setProcessing] = useState(false);
	const [processingError, setProcessingError] = useState(null);

	useEffect(() => {
		props?.setTitle?.(
			props?.dataIds?.historyData
				? "View Load"
				: mode === "EDIT"
				? "Edit Load"
				: mode === "CREATE"
				? "Add Test Loads"
				: "Delete Load"
		);
		props?.setSize?.(mode === "DELETE" ? "max-w-xl" : "max-w-xl");
		// eslint-disable-next-line
	}, [mode]);

	const handleFormSubmit = async (model) => {
		try {
			setProcessingError(null);
			setProcessing(true);

			if (mode === "CREATE") {
				//Quick action
				if (model.quick_action) {
					const dataToSend = {
						...model,
						userId: searchData.userId,
						searchId: searchData.searchId,
						total_loads: 1,
						best_loads: 0,
						book_loads: 0,
						bid_loads: 0,
						equipment: searchData.equipment,
						location_origin: searchData.location_origin__view.value,
						pickup_date: searchData.pickup_date,
						location_destination: DEFAULT_DESTINATIONS.map((location) => location.value),
						delivery_date: searchData.delivery_date,
						rpm: [LOAD_DEFAULTS.rpm__min, LOAD_DEFAULTS.rpm__max],
						miles: [LOAD_DEFAULTS.miles__min, LOAD_DEFAULTS.miles__max],
						brokers__view: DEFAULT_BROKERS,
					};

					await createInternalLoads(dataToSend);
					showSnackbar(snackbar, "Quick action sent", "success");
				} else {
					await createInternalLoads(model);
					showSnackbar(snackbar, "Adding New Internal Loads", "success");
					dispatch(incrementDataRevision({ event: "internalLoadsRevision" }));
					dispatch(increaseNotification());
					dispatch(fetchTransactions({ userId: user._id }));
				}
				onDone?.();
			}
		} catch (err) {
			setProcessingError(err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...");
			showSnackbar(snackbar, err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...", "error");
			setProcessing(false);
		}
	};

	const formContent = useMemo(() => createInternalLoadFrom({ mode }), [mode]);

	return (
		<>
			{searchData.searchId && (
				<div className={" relative " + (processing ? " opacity-60 pointer-events-none " : "")}>
					<SmarthopFormView
						key="add_load"
						content={formContent.quickActions}
						noInitValidation={true}
						errors={processingError ? [{ type: "generic", message: processingError }] : null}
						mode={"CREATE"}
						dataIds={{ carrierId }}
						onSubmit={handleFormSubmit}
					/>
				</div>
			)}
			<div className={" relative " + (processing ? " opacity-60 pointer-events-none " : "")}>
				<SmarthopFormView
					key="add_load"
					content={formContent.loadGeneration}
					noInitValidation={true}
					data={{ ...LOAD_DEFAULTS, ...searchData }}
					errors={processingError ? [{ type: "generic", message: processingError }] : null}
					mode={"CREATE"}
					dataIds={{ carrierId }}
					onSubmit={handleFormSubmit}
				/>
			</div>
		</>
	);
}

export default InternalLoadView;
