import {
	convertModelToURLParams,
	convertURLParamsToModel,
	readURLParameters,
	rewriteURLParameters,
	STRATEGY_URL_PARAMS_MAPPING,
} from "app/main/utils/urlUtils";
import _ from "lodash";
import moment from "moment";
import { strategyResponseToModel } from "./strategyPanelMapper";

const cityStateExpr = /([A-Za-z]+(?: [A-Za-z]+)*),? ([A-Za-z]{2})/g;

export const getCityStateFromStr = (str) => {
	if (!str) return [null, null];
	const matches = [...str.matchAll(cityStateExpr)];
	if (!matches) return [null, null];

	return matches[0][1] && matches[0][2] ? [matches[0][1], matches[0][2]] : [null, null];
};

const getSummary = (strategy, levels, validatedPaths) => {
	if (!strategy?.paths) return;
	const summaryArr = [...Array(levels).keys()]
		.map((n) => getNLevelLoadedMoves(strategy.paths, n + 1, validatedPaths))
		.filter((a) => a.length)
		.map((m) => transformMovesForPlan(m));
	return {
		summaryArr,
		statistics: strategy.response.statistics,
	};
};

const getLoadedMoveIndex = (path, ordinal) => {
	const filteredMoves = path.moves.map((m, i) => ({ ...m, i })).filter((m) => !m.empty);
	return filteredMoves[ordinal - 1]?.i;
};

const getNLevelLoadedMoves = (paths, ordinal, validatedPaths) => {
	let filteredPaths = paths.filter((path) => !isNaN(getLoadedMoveIndex(path, ordinal)));

	if (validatedPaths && !_.isEmpty(validatedPaths)) {
		const validatedPathIds = Object.keys(validatedPaths);
		filteredPaths = paths
			.filter((path) => validatedPathIds.includes(path.path_id.toString()))
			.map((path) => {
				const validatedMoves = path.moves.slice(0, validatedPaths[path.path_id].length);
				return { ...path, moves: validatedMoves };
			})
			.filter((path) => !isNaN(getLoadedMoveIndex(path, ordinal)));
	}

	const moves = filteredPaths.map((path) => {
		const loadedMoveIndexAtLevel = getLoadedMoveIndex(path, ordinal);
		let move = path.moves[loadedMoveIndexAtLevel];
		const previousMove = path.moves[loadedMoveIndexAtLevel - 1];
		const originIfPreviousEmpty = previousMove?.empty ? path.moves[loadedMoveIndexAtLevel - 2]?.o_city : null;
		if (previousMove) {
			move.prev_ocity = previousMove.o_city;
		}
		if (previousMove && previousMove.empty) {
			let additional_empty = previousMove.empty_miles;
			let loss = previousMove.profit;
			move.empty_miles += additional_empty;
			move.rpm = move.payment / (move.loaded_miles + move.empty_miles);
			move.profit = move.profit + loss;
			move.prevIsEmpty = true;
			move.empty_ocity = previousMove.o_city;
			move.prev_ocity = originIfPreviousEmpty;
		}
		move.path_id = path.path_id;
		return move;
	});
	return moves;
};

const transformMovesForPlan = (moves) => {
	const transformedMoves = {};
	for (let m of moves) {
		if (m.empty) continue;
		const { _id, lane, start_date, end_date, path_str_bit, path_id, prev_ocity, prevIsEmpty, empty_ocity } = m;
		const { ocluster, dcluster, o_city, d_city, payment, profit, avg_volume, rpm, loaded_miles, empty_miles } = lane;

		if (!transformedMoves[`${ocluster}${prev_ocity}${prevIsEmpty ? empty_ocity : ""}`]) {
			transformedMoves[`${ocluster}${prev_ocity}${prevIsEmpty ? empty_ocity : ""}`] = {
				...lane,
				city: o_city,
				prev_ocity,
				dests: {},
				prevIsEmpty,
				empty_ocity,
			};
		}

		let loadedRPM = Number(loaded_miles) > 0 ? Number(payment) / Number(loaded_miles) : 0;
		const rawData = {
			origin: o_city,
			destination: d_city,
			payment,
			profit,
			volume: avg_volume,
			rpm,
			loadedRPM,
			loadedMiles: Number(loaded_miles),
			emptyMiles: Number(empty_miles),
			empty: false,
		};

		if (!transformedMoves[`${ocluster}${prev_ocity}${prevIsEmpty ? empty_ocity : ""}`].dests[`${dcluster}`]) {
			transformedMoves[`${ocluster}${prev_ocity}${prevIsEmpty ? empty_ocity : ""}`].dests[`${dcluster}`] = {
				...lane,
				cluster: dcluster,
				city: d_city,
				moveId: _id,
				path_str_bit,
				start_date,
				end_date,
				path_id,
				pathIds: [path_id],
				rawData,
			};
		} else {
			transformedMoves[`${ocluster}${prev_ocity}${prevIsEmpty ? empty_ocity : ""}`].dests[`${dcluster}`].pathIds.push(
				path_id
			);
		}
	}
	return transformedMoves;
};

export const flattenSelectedPaths = (tablePaths) => {
	if (!tablePaths || tablePaths.length === 0) return [];
	tablePaths = { ...tablePaths };
	const allSelectedPaths = Object.values(tablePaths).reduce((acc, curr) => {
		if (curr.selected) acc.push(...curr.ids);
		return acc;
	}, []);
	return allSelectedPaths;
};

export const createSelectedPathsCartPayload = (selectedPathsCart) => {
	if (_.isEmpty(selectedPathsCart)) return;
	const selectedPathsCartPayload = Object.entries(selectedPathsCart).reduce(
		(acc, [pathId, legs]) => ({
			...acc,
			[pathId]: { length: legs?.length, leg_ids: legs.map((leg) => leg?.data?.stop?.lane?._id) },
		}),
		{}
	);
	return selectedPathsCartPayload;
};

export const formatEndDate = (end_date) => (end_date ? moment(end_date).format("YYYY-MM-DD") : null);

export const formatStartDate = (start_date, dayUpperBound = 6) => {
	const datesDiff = moment(start_date).diff(moment(), "days");
	const formattedStartDate = !start_date
		? moment()
		: Math.abs(datesDiff) <= dayUpperBound
		? moment(start_date)
		: moment();
	return formattedStartDate.format("YYYY-MM-DD");
};

export const rewriteURLWithMapping = (data, mapping) => {
	const copy = JSON.parse(JSON.stringify(data));
	rewriteURLParameters(convertModelToURLParams(copy, mapping));
};

export const getModelWithTruckInfo = (truckInfo) => {
	const truckPlan = truckInfo?.plan__view?.metadata;
	const [city, state] = getCityStateFromStr(truckPlan?.current_location);
	const [dcity, dstate] = getCityStateFromStr(truckPlan?.current_destination) || [null, null];
	const { current_available_date } = truckPlan;
	const start_date = formatStartDate(current_available_date);
	const location_origin = city ? `${city}, ${state}` : "";
	const location_destination = dcity ? `${dcity}, ${dstate}` : "";

	// Get driver preferences for blacklisted states
	const driverPreferences = truckInfo?.driver__view?.metadata?.blacklisted_states_ids
		? {
				blacklisted_states_ids: truckInfo.driver__view.metadata.blacklisted_states_ids,
				blacklisted_states_ids__view: truckInfo.driver__view.metadata.blacklisted_states_ids__view,
		  }
		: {};

	const data = {
		start_date,
		pickup_ds__after: start_date,
		location_origin__view: {
			label: location_origin,
			value: location_origin,
		},
		sort_by: "balanced_profit",
		min_profit: truckPlan?.goals?.profit_remaining || 0,
		min_profit_per_leg: truckPlan?.goals?.last_profit < 0 ? 0 : -truckPlan?.goals?.last_profit || 0,
		end_market_profit: truckPlan?.goals?.profit_wtd < 0 ? 0 : -truckPlan?.goals?.profit_wtd || 0,
		...(dcity && dstate
			? {
					location_destination__view: {
						label: location_destination,
						value: location_destination,
					},
			  }
			: {}),
		...driverPreferences, // Add blacklisted states
	};
	return data;
};

export const getLoadedModel = (result) => {
	let data = {};
	if (!_.isEmpty(result)) {
		const { truckInfo } = result?.resData;

		// Get driver preferences for blacklisted states if available
		const driverPreferences = truckInfo?.truck__view?.metadata?.driver__view?.metadata?.blacklisted_states_ids
			? {
					blacklisted_states_ids: truckInfo.truck__view.metadata.driver__view.metadata.blacklisted_states_ids,
					blacklisted_states_ids__view:
						truckInfo.truck__view.metadata.driver__view.metadata.blacklisted_states_ids__view,
			  }
			: {};

		data = {
			...result?.resData?.strategy?.response?.queryData,
			...(result?.resData?.strategy?._id ? { _id: result?.resData?.strategy?._id } : {}),
			carrier__view: sanitizeView(truckInfo?.carrier__view),
			truck__view: sanitizeView(truckInfo?.truck__view),
			...driverPreferences, // Add blacklisted states
		};
	}
	try {
		return strategyResponseToModel(data);
	} catch (error) {
		console.error(error);

		return;
	}
};

export const sanitizeView = (view) => {
	if (!view) return;
	const { metadata, ...sanitizedView } = view;

	// Preserve driver preferences metadata if available
	if (metadata?.driver__view?.metadata?.blacklisted_states_ids) {
		sanitizedView.metadata = {
			driver__view: {
				metadata: {
					blacklisted_states_ids: metadata.driver__view.metadata.blacklisted_states_ids,
					blacklisted_states_ids__view: metadata.driver__view.metadata.blacklisted_states_ids__view,
				},
			},
		};
	}

	return sanitizedView;
};

export const getLoadedModelWithURL = () => {
	let data = convertURLParamsToModel(readURLParameters(), STRATEGY_URL_PARAMS_MAPPING);
	if (!data) return {};

	// Ensure blacklisted_states_ids is properly formatted if it exists in URL
	if (data.blacklisted_states_ids && !data.blacklisted_states_ids__view) {
		// Import toStateViews if not already imported
		const { toStateViews } = require("app/main/utils/stateUtils");
		data.blacklisted_states_ids__view = toStateViews(data.blacklisted_states_ids);
	}

	try {
		return strategyResponseToModel(data);
	} catch (error) {
		console.error(error);
		return {};
	}
};

export const getPathIds = (summary) => {
	const paths = {};
	Object.values(summary?.summaryArr?.[0] ?? {}).forEach((origin) => {
		Object.values(origin.dests).forEach((dest) => {
			paths[dest.path_id] = { ids: dest.pathIds, selected: true };
		});
	});
	return paths;
};

export const dataMapModel = (equipment, truckPlan) => ({
	equipment: equipment,
	locations: [
		{ location: truckPlan.current_location },
		...(truckPlan?.current_destination ? [{ location: truckPlan?.location_destination }] : []),
	],
	origin: truckPlan?.current_location,
	destination: truckPlan?.location_destination,
	activeLocations: true,
	activeHeatMap: true,
});

export const createLocationView = (location_origin) => ({
	description: location_origin,
	label: location_origin,
	value: location_origin,
});

export const ordinals = [
	"first",
	"second",
	"third",
	"fourth",
	"fifth",
	"sixth",
	"seventh",
	"eighth",
	"nineth",
	"tenth",
];

export const createPatterns = (svg, identifier) => {
	const diagonalPatternId = `diagonalPattern-${identifier}`;
	const filledDiagonalPatternId = `filledDiagonalPattern-${identifier}`;
	const pattern = svg.append("pattern").attr("id", diagonalPatternId);
	pattern
		.attr("width", 10)
		.attr("height", 10)
		.attr("patternUnits", "userSpaceOnUse")
		.attr("patternTransform", "rotate(45)");
	pattern
		.append("line")
		.attr("x1", 0)
		.attr("y1", 0)
		.attr("x2", 0)
		.attr("y2", 10)
		.style("stroke", "rgba(255,125, 0, .5)")
		.style("stroke-width", 2);

	const filledPattern = svg.append("pattern").attr("id", filledDiagonalPatternId);
	filledPattern
		.attr("width", 10)
		.attr("height", 10)
		.attr("patternUnits", "userSpaceOnUse")
		.attr("patternTransform", "rotate(45)");
	filledPattern
		.append("line")
		.attr("x1", 0)
		.attr("y1", 0)
		.attr("x2", 0)
		.attr("y2", 10)
		.style("stroke", "rgba(255,125, 0, .5)")
		.style("stroke-width", 2);

	filledPattern
		.append("rect")
		.attr("width", 10)
		.attr("height", 10)
		.attr("x", 0)
		.attr("y", 0)
		.attr("fill", "rgba(255,125, 0, .2)");
	return { diagonalPatternId, filledDiagonalPatternId };
};

export default getSummary;
