import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { useDispatch } from "react-redux";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";

import CellEmptyRow from "./CellEmptyRow";
function StrategyCellView({ item, view, mobile, variant, config }) {
	const dispatch = useDispatch();

	if (!item.paths && !item.strategy && !item.response) {
		return <CellEmptyRow mobile={mobile} variant={variant} />;
	}
	return (
		<CellEmptyRow>
			<Button
				onClick={() =>
					dispatch(
						openLoadedFormDialog({
							viewId: "STRATEGY_VISUALIZER",
							dataIds: {
								strategyId: item._id,
								virtualContractId: item.virtualContractId,
								truckId: item.truck,
								carrierId: item.carrier,
								entryPoint: "savedList",
							},
						})
					)
				}
				className={"overflow-visible ml-1 py-6 px-9 bg-blue-100"}
			>
				<Typography className={"whitespace-nowrap font-medium text-black text-11"}> View </Typography>
			</Button>
		</CellEmptyRow>
	);
}

export default StrategyCellView;
