import { Typography, Link } from "@material-ui/core";
import { DisplaySection, createIconBadge, renderDataItem } from "app/main/utils/uiUtils";
import { createTooltip, formatCurrency } from "../utils/tableUtils";
import TruckPlanSearches from "./TruckPlanSearches";
import TruckPlanSearchChart from "./TruckPlanSearchChart";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { openTutorialDialog } from "app/store/tools/tutorialDialogSlice";

const BadgeCounter = ({ icon, count }) => {
	return (
		<div className="flex flex-row space-x-4">
			{icon}
			{count}
		</div>
	);
};

const TruckPlanViewActions = ({ history, planData, onEdit }) => {
	const dispatch = useDispatch();
	const [mobile, setMobile] = useState(window.innerWidth < 650);

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 650 && !mobile) {
				setMobile(true);
			} else if (window.innerWidth > 650 && mobile) {
				setMobile(false);
			}
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	const openLoadScoutTutorial = () => {
		dispatch(openTutorialDialog({ tutorialId: "LOAD_SCOUT_TUTORIAL", type: "permanent" }));
	};

	if (history === false) {
		return (
			<div className="flex w-full h-full items-center justify-center">
				<Typography>Loading...</Typography>
			</div>
		);
	}

	return (
		<div className="flex flex-col">
			{!mobile && (
				<div className="flex flex-row w-full">
					<div className="w-full mb-0 md:mb-16 flex flex-row bg-blue-100 items-center rounded-lg p-12 md:-ml-2">
						<Typography component="div">
							Load Scout allows you to proactively search for loads in the target market{" "}
							<Link onClick={openLoadScoutTutorial}>learn more</Link>
						</Typography>
					</div>
				</div>
			)}
			<div className="flex w-full flex-col md:flex-row md:space-x-16">
				<div className={`flex flex-col w-full`}>
					<DisplaySection
						classes={{ root: "mx-0 mt-0 mb-28", divider: "mb-0" }}
						title={"Settings"}
						actions={[{ icon: "edit", onClick: () => onEdit("loadscout") }]}
					>
						{renderDataItem("Loud Scout", planData?.plan?.advancedFilters ? "Enabled" : "Disabled")}
						<div className="flex flex-row w-full space-x-20">
							{renderDataItem(
								"Min Rate",
								planData?.plan?.rate_total_preferred ? formatCurrency(planData?.plan?.rate_total_preferred) : undefined
							)}
							{renderDataItem(
								"Min RPM",
								planData?.plan?.rate_per_mile_preferred
									? formatCurrency(planData?.plan?.rate_per_mile_preferred)
									: undefined
							)}
						</div>
						<div className="flex flex-row w-full space-x-20">
							{renderDataItem("Max DH-Origin", planData?.plan?.dh_origin_preferred, "mi")}
							{renderDataItem("Max DH-Dest.", planData?.plan?.dh_destination_preferred, "mi")}
							{renderDataItem(
								"Miles (DH + Loaded)",
								planData?.plan?.miles_preferred__min && planData?.plan?.miles_preferred__max
									? `${planData?.plan?.miles_preferred__min} mi - ${planData?.plan?.miles_preferred__max} mi`
									: undefined
							)}
						</div>
						<div className="flex flex-row w-full space-x-20">
							{renderDataItem(
								"Min Profit",
								planData?.plan?.profit_preferred && planData?.plan?.profit_preferred !== -1000
									? formatCurrency(planData?.plan?.profit_preferred)
									: undefined
							)}
							{renderDataItem(
								"Min MPI",
								planData?.plan?.min_rank_mci ? `${planData?.plan?.min_rank_mci} / 100` : undefined
							)}
						</div>
						<div className="flex flex-row w-full space-x-20">
							{renderDataItem(
								"Min Credit Score",
								planData?.plan?.min_credit_score ? `${planData?.plan?.min_credit_score} / 100` : undefined
							)}
							{renderDataItem(
								"Max Stops",
								planData?.plan?.extra_stops_preferred && planData?.plan?.extra_stops_preferred !== 5
									? planData?.plan?.extra_stops_preferred
									: undefined
							)}
							{renderDataItem(
								"Duration",
								planData?.plan?.duration_preferred && planData?.plan?.duration_preferred !== 6
									? `${planData?.plan?.duration_preferred} Days`
									: undefined
							)}
						</div>
						{renderDataItem(
							"Load Age",
							planData?.plan?.age_mins_max_preferred && planData?.plan?.age_mins_max_preferred !== 120
								? `${planData?.plan?.age_mins_max_preferred} Minutes`
								: undefined
						)}
						{renderDataItem(
							"Pause Notifications Outside Business Hours",
							planData?.plan?.notificationPreferences?.pauseOutsideBusinessHours ?? false ? "Yes" : "No"
						)}
						{renderDataItem("Min HOP loads to notify me", planData?.plan?.notificationPreferences?.minHopLoadsCount)}
						{renderDataItem(
							"Min matching loads to notify me",
							planData?.plan?.notificationPreferences?.minMatchingPrefCount
						)}
					</DisplaySection>
				</div>
				<div className="hidden md:flex flex-col w-full">
					<DisplaySection
						title="Loads Scouted"
						classes={{ root: "mx-0 mt-0 mb-28", divider: "mb-0" }}
						views={
							history?.items?.[0]
								? [
										<div className="flex flex-row space-x-8 border-bottom-1 -mt-8 items-center justify-center p-4">
											<BadgeCounter
												icon={createTooltip(createIconBadge("MATCH_PLAN"), "Loads Matching Filters")}
												count={history?.items?.[0]?.analytics?.counters?.planMatch}
											/>
											<BadgeCounter
												icon={createTooltip(createIconBadge("BEST"), "HOP Loads")}
												count={history?.items?.[0]?.analytics?.counters?.best}
											/>
										</div>,
								  ]
								: []
						}
					>
						<TruckPlanSearchChart history={history} data={planData} />
					</DisplaySection>
				</div>
			</div>
			<TruckPlanSearches data={planData} />
		</div>
	);
};

export default TruckPlanViewActions;
