import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import WithControlPanel from "app/main/tree-visualizer/control-panel/WithControlPanel";
import strategySubPanelConfig from "app/main/tree-visualizer/control-panel/configs/strategySubPanelConfig";
import { strategyConnect } from "../../providers/StrategyContextProvider";
import StrategyLegend from "../strategy-legend/StrategyLegend";
import {
	changeControlPanel,
	changeModel,
	changeResult,
	cleanControlPanel,
	saveStrategy,
	latestStrategy,
} from "../../providers/reducer/actions";
import { getStrategyTreeById } from "app/services/strategyServices";
import { useSelector } from "react-redux";
import { getLoadedModel } from "app/main/strategy/utils/strategyUtils";
import StrategySummaryContainer from "../strategy-summary/StrategySummaryContainer";

const StrategySubpanel = ({
	result,
	model,
	saveLoading,
	changeControlPanel,
	changeResult,
	saveStrategy,
	latestStrategy,
	loadStrategy,
	cleanControlPanel,
	entryPoint,
}) => {
	const controlDefaultValues = {
		folded: true,
		flexPathsEnabled: true,
		save: false,
		edgeWidthEnabled: true,
		tooltipEnabled: false,
		highlightFlexEnabled: false,
	};
	const stack = useSelector(({ tools }) => tools.formDialog.formStack ?? []);
	const [mountStackLength] = useState(stack.length);

	const [SmarthopControlPanel, useControlPanelState, setControlPanelValue] = WithControlPanel({
		defaultValues: controlDefaultValues,
		content: strategySubPanelConfig(entryPoint),
	});

	useEffect(() => {
		changeControlPanel({ useControlPanelState, setControlPanelValue });
		return () => cleanControlPanel();
		//eslint-disable-next-line
	}, []);

	const legendRef = useRef();
	const summaryRef = useRef();

	const handlePrev = async () => changeResult(await getStrategyTreeById(result?.prevStrategyId));
	const handleNext = async () => changeResult(await getStrategyTreeById(result?.nextStrategyId));
	const handleLoad = () => loadStrategy();
	const handleSave = () => {
		summaryRef.current?.open();
		summaryRef.current?.setTitle("Saving Summary");
		summaryRef.current?.setActions([
			{
				label: "Assign Contract",
				onClick: () => {
					saveStrategy();
				},
				closeOnClick: true,
			},
		]);
		summaryRef.current?.setMessage("You're about to save this contract with the summary details outlined below.");
		summaryRef.current?.setSaving(true);
	};

	const handleLatestStrategy = () => {
		latestStrategy();
	};

	const handleOpenSummary = () => {
		summaryRef.current?.open();
		summaryRef.current?.setTitle("Strategy Summary");
		summaryRef.current?.setActions([]);
		summaryRef.current?.setMessage("");
		summaryRef.current?.setSaving(false);
	};

	const handleExport = () => {
		if (_.isEmpty(result)) return;

		// Extract the strategy data from the result
		const strategy = result?.resData?.strategy;

		// Check if contracts data exists
		if (!strategy?.response?.contracts || !strategy.response.contracts.length) {
			// Show error message if no contracts data
			alert("No contract data available to export.");
			return;
		}

		// Use the contracts data directly with minimal processing
		const exportData = strategy.response.contracts.map((contract) => ({
			"Contract Id": contract["Contract Id"] || contract.id || "",
			Type: contract["Type"] || contract.type || "",
			"Origin city": contract["Origin city"] || contract.origin_city || "",
			"Origin st": contract["Origin st"] || contract.origin_state || "",
			"Date Pickup": contract["Date Pickup"] || contract.date_pickup || "",
			"Delivery city": contract["Delivery city"] || contract.delivery_city || "",
			"Delivery state": contract["Delivery state"] || contract.delivery_state || "",
			"Date Delivery": contract["Date Delivery"] || contract.date_delivery || "",
			Duration: contract["Duration"] || contract.duration || 0,
			"Empty miles": contract["Empty miles"] || contract.empty_miles || 0,
			"Loaded miles": contract["Loaded miles"] || contract.loaded_miles || 0,
			"Min payment": contract["Min payment"] || contract.min_payment || 0,
			Payment: contract["Payment"] || contract.payment || 0,
			"Max payment": contract["Max payment"] || contract.max_payment || 0,
			RPM: contract["RPM"] || contract.rpm || 0,
			Visited: contract["Visited"] || contract.visited || [],
		}));

		// Define fields based on the contract structure
		const fields = [
			{ label: "Contract Id", value: "Contract Id" },
			{ label: "Type", value: "Type" },
			{ label: "Origin city", value: "Origin city" },
			{ label: "Origin st", value: "Origin st" },
			{ label: "Date Pickup", value: "Date Pickup" },
			{ label: "Delivery city", value: "Delivery city" },
			{ label: "Delivery state", value: "Delivery state" },
			{ label: "Date Delivery", value: "Date Delivery" },
			{ label: "Duration", value: "Duration" },
			{ label: "Empty miles", value: "Empty miles" },
			{ label: "Loaded miles", value: "Loaded miles" },
			{ label: "Min payment", value: "Min payment" },
			{ label: "Payment", value: "Payment" },
			{ label: "Max payment", value: "Max payment" },
			{ label: "RPM", value: "RPM" },
			{ label: "Visited", value: "Visited" },
		];

		// Export directly to Excel
		exportToExcel(exportData, fields);
	};

	// Function to export data to Excel
	const exportToExcel = async (data, fields) => {
		try {
			// Import ExcelJS dynamically
			const ExcelJS = await import("exceljs").catch(() => {
				throw new Error("ExcelJS library not available");
			});

			// Create a new workbook and worksheet
			const workbook = new ExcelJS.Workbook();
			const worksheet = workbook.addWorksheet("Strategy Contracts");

			// Add column headers
			const headerRow = worksheet.addRow(fields.map((field) => field.label));
			headerRow.font = { bold: true };
			headerRow.fill = {
				type: "pattern",
				pattern: "solid",
				fgColor: { argb: "FFE0E0E0" },
			};

			// Set column widths
			fields.forEach((field, index) => {
				const column = worksheet.getColumn(index + 1);
				column.width = Math.max(field.label.length, 15) + 2;

				// Set number format for numeric columns
				if (["Duration", "Empty miles", "Loaded miles"].includes(field.value)) {
					column.numFmt = "#,##0";
				} else if (["Min payment", "Payment", "Max payment"].includes(field.value)) {
					column.numFmt = '"$"#,##0.00';
				} else if (["RPM"].includes(field.value)) {
					column.numFmt = "0.000";
				}
			});

			// Add data rows
			data.forEach((item) => {
				const rowData = fields.map((field) => {
					// Handle arrays (like Visited) by joining with commas
					if (Array.isArray(item[field.value])) {
						return item[field.value].join(", ");
					}
					return item[field.value];
				});
				worksheet.addRow(rowData);
			});

			// Generate Excel file
			const buffer = await workbook.xlsx.writeBuffer();
			const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
			const url = URL.createObjectURL(blob);

			// Create a detailed filename based on strategy parameters
			const strategy = result?.resData?.strategy;
			const truckInfo = result?.resData?.truckInfo;
			const params = strategy?.response?.params || {};
			const today = new Date().toISOString().slice(0, 10);

			// Get carrier name
			const carrierName =
				truckInfo?.carrier_name ||
				model?.carrier__view?.label ||
				model?.carrier__view?.carrier_company_name ||
				"unknown_carrier";

			// Sanitize carrier name (remove spaces and special characters)
			const safeCarrierName = carrierName.replace(/[^a-zA-Z0-9]/g, "_");

			// Get strategy parameters
			const equipment = params.equipment || strategy?.equipment || "unknown_equipment";
			const startDate = params.start_date || "unknown_date";
			const startCluster =
				params.start_cluster != undefined
					? params.start_city + "_" + params.start_state + "_" + params.start_cluster
					: "unknown_cluster";
			const analysisMethod = params.analysis_method || "unknown_method";
			const minVolume = params.min_volume || "unknown_volume";
			const minDestMarketProfit = params.min_dest_market_profit || "unknown_profit";
			const topDestMarkets = params.n_markets || "unknown_markets";

			// Create filename
			const dest_markets = minDestMarketProfit ? `profit_${minDestMarketProfit}` : `top_${topDestMarkets}`;
			const filename = `${safeCarrierName}_${today}_${equipment}_${startDate}_start_${startCluster}_${analysisMethod}_vol_${minVolume}_${dest_markets}.xlsx`;

			// Create download link
			const link = document.createElement("a");
			link.href = url;
			link.download = filename;
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url); // Clean up
		} catch (error) {
			console.error("Error exporting to Excel:", error);
			// Show error message with alert instead of using summaryRef
			alert(`Failed to export contracts: ${error.message}`);
		}
	};

	const handleToggleLegend = () => legendRef.current?.toggle();
	const emptyResult = _.isEmpty(result);
	const savedResult = result?.resData?.isMongo;
	const disableLatest = () => {
		const hasResult = !emptyResult;
		const hasTruckId = !!(result?.resData?.strategy?.truck || model.truck);
		const hasNextId = !!result?.nextStrategyId;
		return (!hasResult && !hasTruckId) || (hasResult && savedResult && !hasNextId);
	};

	return (
		<>
			<SmarthopControlPanel
				callbacks={{
					previous: handlePrev,
					next: handleNext,
					load: handleLoad,
					save: handleSave,
					strategySummary: handleOpenSummary,
					latest: handleLatestStrategy,
					legend: handleToggleLegend,
					export: handleExport,
				}}
				disabled={{
					previous: !result?.prevStrategyId,
					next: !result?.nextStrategyId,
					save: emptyResult || result?.resData?.isMongo,
					load: emptyResult,
					strategySummary: emptyResult,
					expanded: emptyResult,
					folded: emptyResult,
					export: emptyResult,
					latest: disableLatest(),
				}}
				loading={{ save: saveLoading }}
			/>
			<StrategyLegend ref={legendRef} />
			<StrategySummaryContainer ref={summaryRef} mountStackLength={mountStackLength} />
		</>
	);
};

const mapStateToProps = (state) => ({
	model: state.model,
	result: state.result,
	saveLoading: state.saveLoading,
	entryPoint: state.entryPoint,
});

const mapDispatchToProps = (dispatch, ownProps, state) => ({
	changeControlPanel: (controlPanel) => dispatch(changeControlPanel(controlPanel)),
	changeResult: (result) => dispatch(changeResult(result)),
	saveStrategy: () => dispatch(saveStrategy({ dispatch })),
	latestStrategy: () => dispatch(latestStrategy({ dispatch, onStrategySubmitted: ownProps.onStrategySubmitted })),
	loadStrategy: () => dispatch(changeModel(getLoadedModel(state.result))),
	cleanControlPanel: () => dispatch(cleanControlPanel()),
});

export default strategyConnect(mapStateToProps, mapDispatchToProps)(StrategySubpanel);
