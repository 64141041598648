import { useState, useRef } from "react";
import { formatAge, formatTimeAgo } from "app/main/utils/dateUtils";
import { Typography } from "@material-ui/core";
import { SmarthopConfirmDialog } from "@smarthop/form";
import LoadSearchViewLocationsView from "./LoadSearchViewLocationsView";
import LoadSearchViewLocationsEdit from "./LoadSearchViewLocationsEdit";

function LoadSearchSummaryDetailsView(props) {
	const load = props.load;
	const loading = props.loading;
	const onLocationChange = props.onLocationChange;
	const [locationsEditOpen, setLocationsEditOpen] = useState(false);
	const locationsRef = useRef(null);

	const onEditRouteNew = () => {
		setLocationsEditOpen(true);
	};

	const onUpdateLocations = (update) => {
		setLocationsEditOpen(false);
		onLocationChange(update);
	};

	const renderDataItem = (label, value, type, options) => {
		return (
			<div
				className={
					"flex " +
					(options?.vertical ? " flex-col " : " flex-row border-b-1 border-grey-300 ") +
					(options?.classes?.root ?? "")
				}
			>
				<Typography className="flex w-full text-black text-12 ml:text-13 break-normal mt-10">{label}</Typography>
				<Typography
					className={
						"text-12 ml:text-13  break-normal  " +
						(!options?.vertical ? " mt-10 font-semibold text-primary-700 whitespace-nowrap " : " text-grey-700 ") +
						(!options?.animate ? "" : loading ? " animate-pulse " : "")
					}
				>
					{!value ? "-" : value} {!value ? "" : type}
				</Typography>
			</div>
		);
	};

	return (
		<div className={"flex flex-col"}>
			<div className={"flex flex-col -mt-10 " + (!props.hideLocation ? " mb-10 " : "")}>
				{renderDataItem("Load Age", formatAge(load?.updated))}
				{renderDataItem("First Posted", loading && !load?.entered ? "Loading..." : formatTimeAgo(load?.entered), null, {
					animate: true,
				})}
			</div>
			{!props.hideLocation && <LoadSearchViewLocationsView load={load} onEditRoute={onEditRouteNew} />}
			<SmarthopConfirmDialog
				title={`Route`}
				open={locationsEditOpen}
				maxWidth="lg"
				onClose={() => {
					setLocationsEditOpen(false);
				}}
				onAccept={() => {
					const data = locationsRef.current?.getValues();
					const { price, rpm, profitData, ...dataToUpdate } = data;
					if (dataToUpdate) onUpdateLocations(dataToUpdate);
				}}
			>
				<LoadSearchViewLocationsEdit data={load} innerRef={locationsRef} />
			</SmarthopConfirmDialog>
			{renderDataItem("Total Miles", Number(load?.total_miles) > 0 ? load?.total_miles : 0, "mile(s)", {
				animate: true,
				classes: { root: "mt-20" },
			})}
			{renderDataItem("Duration", load?.duration, "day(s)", { animate: true })}
			{renderDataItem("Weight", load?.weight)}
			{!props.hideNotes && !!load?.specinfo?.trim()?.length
				? renderDataItem("Notes", load?.specinfo, null, { vertical: true, animate: true })
				: null}
			{!props.hideNotes && !!load?.specinfo?.trim()?.length && !!load?.analysis?.trim()?.length
				? renderDataItem("Analysis: ", load?.analysis, null, { vertical: true, animate: true })
				: null}
		</div>
	);
}

export default LoadSearchSummaryDetailsView;
