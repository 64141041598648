import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Typography, Icon, makeStyles, IconButton, Slide, Box, Menu, MenuItem } from "@material-ui/core";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";

// Tools
import { closeMenuPanel, setHighlightItem } from "app/store/tools/menuSlice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { createBadgeView } from "app/main/search/searchUtils";

// Utils
import { showErrorSnackbar } from "app/main/utils/snackbarUtil";
import { DisplaySection } from "app/main/utils/uiUtils";
import { openDialogTitle, openDialogMessage } from "app/main/search/config/suggestUtils";
import LoadingDialog from "app/main/tools/LoadingDialog";
import { calculateProfit } from "app/main/profile/cost-structure/calculator/CostStructureCalculatorSettings";
import { createSourceView } from "app/main/utils/brokerUtils";
import { descriptionLoadId } from "app/main/search/searchUtils";

// View components
import LoadSearchSummaryDetailsView from "./LoadSearchSummaryDetailsView";
import LoadSearchSummaryRateView from "./LoadSearchSummaryRateView";
import LoadSearchSummaryBrokerView from "./LoadSearchSummaryBrokerView";
import LoadSearchAvailability from "./LoadSearchViewAvailability";
import WarningConfirmDialog from "app/main/common/WarningConfirmDialog.js";
import LoadSearchBidBookModalView from "./LoadSearchBidBookModalView";

// Services
import { getBrokerSearch } from "app/services/brokersServices";
import { isHelpEnabled, isRoleExternal } from "app/services/LoginService";
import { getLoadForDispatcherRefreshed } from "app/services/searchServices";
import { selectLoadsById } from "app/store/loads/loadsSlice";
import { runAction, updateLoad, getProfitData, onLocationChange } from "./LoadSearchViewHelper";
import LoadSearchViewActions from "./LoadSearchViewActions";
import { hasRequiredGateKeepers, roleRestrictions } from "app/main/utils/rolesUtils";

const useStyles = makeStyles((theme) => {
	return {
		rootTable: {
			width: "100%",
			height: "calc(100vh - 250px) !important",
		},
		rootGridExt: {
			width: "100%",
			height: "calc(100vh - 210px) !important",
		},
		rootGridInt: {
			width: "100%",
			height: "calc(100vh - 150px) !important",
		},
		container: {
			width: "100%",
		},
		containerInformation: {
			height: "-webkit-fill-available",
		},
		"@keyframes flicker": { from: { opacity: 1 }, to: { opacity: 0.2 } },
		flicker: {
			animationName: "$flicker",
			animationDuration: "600ms",
			animationIterationCount: "infinite",
			animationDirection: "alternate",
			animationTimingFunction: "ease-in-out",
		},
		gradient: {
			"--tw-gradient-from": "#fff var(--tw-gradient-from-position)",
			"--tw-gradient-from-position": " ",
			"--tw-gradient-to": "rgb(255 255 255 / 0) var(--tw-gradient-from-position)",
			"--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)",
		},
	};
});

function LoadSearchSummary(props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const snackbar = useSnackbar();

	const revision = useSelector(({ tools }) => tools.revision["searchSummaryRevision"]);
	const menuPanel = useSelector(({ tools }) => tools.menu.menuPannel ?? []);
	const user = useSelector(({ auth }) => auth.user);
	const isUserExternal = useMemo(() => isRoleExternal(), []);

	const data = menuPanel[0]?.data;
	const dataIds = menuPanel[0]?.dataIds;

	const smallScreen = props.smallScreen;
	const truckData = props.truckData;
	const cActive = props?.dataIds?.carrierActive;
	const tActive = props?.dataIds?.truckActive;
	const loadId = dataIds?.loadId;
	const userId = dataIds?.userId;
	const searchId = dataIds?.searchId;
	const requestRef = useRef(false);
	const [request, setRequest] = useState(null);
	const [result, setResult] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	// Trip Warnings
	const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
	const [ignoreWarnings, setIgnoreInvoiceWarnings] = useState(false);
	const [tripInvoiceWarnings, setTripInvoiceWarnings] = useState();

	const editedLoad = useSelector((state) => selectLoadsById(state.loads, loadId) ?? {});

	const loadToUse = useMemo(() => {
		const loadToUse = { ...(result?.load ?? {}), ...editedLoad };

		const pdata = getProfitData(loadToUse, truckData);
		const res = calculateProfit(pdata);

		loadToUse.profitData = { data: pdata, ...res };

		loadToUse.total_miles = res.totalMiles;
		loadToUse.duration = res.duration;
		loadToUse.stopsNumber = 2 + (loadToUse.locations?.length ?? 0);
		return loadToUse;
	}, [editedLoad, result, truckData]);

	const [open, setOpen] = useState(false);
	const [progress, setProgress] = useState({ flag: false, message: null });
	const [flicker, setFlicker] = useState(false);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(null);
	const [confirmDialog, setConfirmDialog] = useState({
		title: null,
		prevTitle: null,
		flag: false,
		action: null,
		isWarning: false,
	});
	const [openBidDialog, setOpenBidDialog] = useState(false);
	const loadIdRef = useRef(null);

	const hasBookPermission = roleRestrictions.permission_book_on.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_book_on: true })
		: true;
	const hasRatePermission = roleRestrictions.permission_rates_on.includes(user.role)
		? hasRequiredGateKeepers(user, { permission_rates_on: true })
		: true;
	const permission = {
		book: hasBookPermission,
		help: isHelpEnabled(),
		price: hasRatePermission,
	};
	const restrictions = {
		bookCarrierActive: cActive && tActive,
	};
	const onRateChange = (newRate) => {
		updateLoad(searchId, dataIds?.loadId, { price: newRate, originalPrice: loadToUse.price });
	};

	useEffect(() => {
		if (!data) return;
		if (loadIdRef?.current === data?.tripid) return;
		setOpen(false);
		loadIdRef.current = null;

		setTimeout(() => {
			setOpen(true);
			setResult({ load: data });
			loadIdRef.current = data.tripid;
			dispatch(setHighlightItem({ id: data.tripid }));
		}, 150);
		// eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		if (!userId || !searchId || !loadId || !requestRef) {
			return;
		}
		if (
			requestRef.current?.userId !== userId ||
			requestRef.current?.searchId !== searchId ||
			requestRef.current?.loadId !== loadId
		) {
			// Workaround to make sure we never call load request
			// 2 times for the same input params
			requestRef.current = { userId, searchId, loadId };
			setRequest({ userId, searchId, loadId });
		}
		// eslint-disable-next-line
	}, [userId, searchId, loadId]);

	// get updated load data
	useEffect(() => {
		let inactive = false;
		if (!request || data?.deleted) {
			return;
		}

		setError(null);
		setFlicker(true);

		const options = { tollDiscouraged: true };
		getLoadForDispatcherRefreshed(request.userId, request.searchId, request.loadId, options).then(
			(load) => {
				if (inactive) return;
				dispatch(incrementDataRevision({ event: "searchResultsChange" + request.searchId }));

				if (loadIdRef.current === data?.tripid) {
					setFlicker(false);
					setResult({ request, load: { ...load, fullLoadRecord: true } });
				}
				if (load.availabilityError) console.log(`[Load check availability] Error ${load.availabilityError}`);
			},
			(error) => {
				if (inactive) return;
				setFlicker(false);
				setError(error.errors?.[0]?.message ?? "Opps, operation failed...");
			}
		);

		return () => {
			inactive = true;
		};
		// eslint-disable-next-line
	}, [request, revision]);

	const showBrokerSummary = async () => {
		try {
			const { mcnumber } = data;
			const broker = await getBrokerSearch(mcnumber, loadToUse.carrier);
			if (!broker) {
				showErrorSnackbar(snackbar, "Broker Not Found");
			}
			const dataIds = { brokerId: broker._id, carrierId: loadToUse.carrier, cache: broker };
			dispatch(
				openLoadedFormDialog({
					viewId: "BROKER_INFO_VIEW",
					dataIds: dataIds,
					mode: "VIEW",
				})
			);
		} catch (error) {
			showErrorSnackbar(snackbar, error);
		}
	};

	const openFullScreen = () => {
		const dialog = {
			viewId: "LOAD_SUGGEST_VIEW",
			dataIds: {
				truckData: truckData,
				load: loadToUse,
				loadId: data.tripid,
				userId: dataIds.userId,
				searchId: dataIds.searchId,
				carrierId: dataIds.carrierId,
				carrier__view: dataIds.carrier__view,
				truckId: dataIds.truckId,
				truck__view: dataIds.truck__view,
				driverId: dataIds.driverId,
				driver__view: dataIds.driver__view,
				hidePrice: !permission.price,
				restrictions: restrictions,
				isFromSearch: true,
				filters: props?.filters,
			},
		};
		dispatch(openLoadedFormDialog(dialog));
	};

	const handleMenuClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const onAcceptWarningChanges = () => {
		cleanTripInvoiceWarnings();
		setIgnoreInvoiceWarnings(true);
	};

	const cleanTripInvoiceWarnings = () => {
		setTripInvoiceWarnings();
	};

	const executeAction = useCallback(
		async (action, data) => {
			try {
				setOpenBidDialog(false);
				const response = await runAction(
					action,
					setConfirmDialog,
					dispatch,
					setProgress,
					{ ...loadToUse, ...data },
					dataIds,
					snackbar,
					setSaving,
					ignoreWarnings
				);
				setIgnoreInvoiceWarnings(false);
				return response;
			} catch (err) {
				const tripWarnings = err?.errors?.[0]?.metadata?.warnings;
				setTripInvoiceWarnings(tripWarnings);
				if (tripWarnings?.length) {
					setIsWarningDialogOpen(true);
				}
			}
		},
		[dataIds, loadToUse, dispatch, snackbar, setSaving, ignoreWarnings]
	);

	useEffect(() => {
		if (ignoreWarnings) {
			executeAction("TRIP");
		}
		// eslint-disable-next-line
	}, [ignoreWarnings]);

	const openConfirmDialog = async (type) => {
		if (type === "BID") {
			setOpenBidDialog(true);
			return;
		}

		const title = openDialogTitle(type);
		const message = openDialogMessage(type);
		let confirmDialog = { title: title, message: message, flag: true, action: type };

		if (["RESTRICTION_BOOK_CARRIER_HAS_MCNUMBER", "RESTRICTION_BOOK_CARRIER_STATUS"].includes(type)) {
			confirmDialog.isWarning = true;
		}

		setConfirmDialog(confirmDialog);
	};

	const showBadges = () => {
		const LIST_BADGES = createBadgeView(loadToUse, "SUMMARY");
		return LIST_BADGES;
	};

	if (!menuPanel.length > 0 || !loadToUse) {
		return null;
	}

	const variant = smallScreen ? "skinny" : null;
	const panelWidth = smallScreen ? 350 : 450;

	return (
		<Box
			className={
				"flex bg-white ml-10 overflow-y-scroll overflow-x-hidden z-10 fixed right-0 bottom-68 " +
				(isUserExternal ? "top-230" : "top-224")
			}
			style={{
				width: panelWidth + "px",
			}}
			in={!!open}
		>
			<Slide direction="left" in={!!open} mountOnEnter unmountOnExit>
				<div className={"flex bg-white flex-col mt-0  " + (smallScreen ? " mx-18 " : " mx-22 ") + classes.container}>
					<div
						className={"flex flex-col justify-between overflow-y-scroll scrollbar-hide " + classes.containerInformation}
					>
						<div className="flex flex-col sticky w-full z-40 top-0 ">
							<div className={"flex items-center bg-white " + (error ? "justify-end" : "justify-between")}>
								<IconButton
									size="small"
									onClick={() => {
										requestRef.current = null;
										loadIdRef.current = null;
										setResult(null);
										setOpen(false);
										dispatch(closeMenuPanel());
									}}
									title={"Close"}
									className="-ml-6"
								>
									<Icon className="text-22 text-grey">close</Icon>
								</IconButton>
								{!error && (
									<div className={"flex items-center justify-center "}>
										<div
											className={"flex flex-wrap items-center justify-center mr-5" + (flicker ? classes.flicker : "")}
										>
											{showBadges()}
										</div>
										<IconButton size="small" onClick={() => openFullScreen()} title={"Open Full View"} className="ml-8">
											<Icon className={"text-16"}>open_in_full</Icon>
										</IconButton>
										<IconButton
											size="small"
											title={"More actions"}
											className="ml-8"
											id="actions-button"
											aria-controls="actions-menu"
											aria-haspopup="true"
											aria-expanded={!!anchorEl}
											onClick={handleMenuClick}
										>
											<Icon className={"text-20"}>more_vert</Icon>
										</IconButton>
										<Menu
											id="actions-menu"
											aria-labelledby="actions-button"
											anchorEl={anchorEl}
											open={!!anchorEl}
											onClose={handleCloseMenu}
											transformOrigin={{ horizontal: "right", vertical: "top" }}
											anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
											getContentAnchorEl={null}
										>
											<MenuItem
												onClick={() => {
													handleCloseMenu();
													openConfirmDialog("COVERED");
												}}
											>
												Mark as Covered
											</MenuItem>
										</Menu>
									</div>
								)}
							</div>
							<div className={"h-10 w-full bg-gradient-to-b " + classes.gradient} />
						</div>
						{!error && (
							<div className="flex flex-col w-full">
								<DisplaySection
									title={"Broker MC " + loadToUse?.mcnumber}
									classes={{ root: "mx-0 mt-0", labelsContainer: "items-center" }}
									labels={[{ label: createSourceView(loadToUse), classes: { label: " pl-0 " } }]}
								>
									<LoadSearchSummaryBrokerView
										variant={variant}
										permission={permission}
										load={loadToUse}
										carrierId={dataIds?.carrierId}
										truckId={dataIds?.truckId}
										searchId={dataIds?.searchId}
										onShowBrokerSummary={showBrokerSummary}
									/>
								</DisplaySection>

								<DisplaySection title={descriptionLoadId(loadToUse)} classes={{ root: "-mt-20 mx-0" }}>
									<LoadSearchSummaryDetailsView
										variant={variant}
										permission={permission}
										load={loadToUse}
										loading={flicker}
										onLocationChange={(update) => onLocationChange(update, loadToUse, dataIds, dispatch)}
										onEditRoute={() => openFullScreen()}
									/>
								</DisplaySection>
							</div>
						)}
						{!error && (
							<div className="flex flex-col sticky bottom-0 w-full z-40">
								<div className={"h-20 w-full bg-gradient-to-t " + classes.gradient} />
								<div className="flex flex-col bg-white">
									<LoadSearchSummaryRateView
										permission={permission}
										load={loadToUse}
										loading={flicker}
										onUpdateRate={onRateChange}
										loadId={dataIds?.loadId}
									/>
									<div className="flex flex-row pb-8 justify-center ml:px-8">
										<LoadSearchAvailability
											load={loadToUse}
											loading={flicker}
											hasError={!!error || !!loadToUse.availabilityError}
										/>
									</div>
									<div className="flex flex-col pb-8">
										<LoadSearchViewActions
											flicker={flicker}
											loadToUse={loadToUse}
											permission={permission}
											restrictions={restrictions}
											handleAction={executeAction}
											openFullScreen={openFullScreen}
											openConfirmDialog={openConfirmDialog}
											fullDetailsMode={false}
											saving={saving}
											truckData={truckData}
										/>
									</div>
								</div>
							</div>
						)}
					</div>
					{error && (
						<Box className="flex h-full justify-center items-center">
							<Typography color="error" className="flex pt-10 pb-8 items-center justify-center">
								{error}
							</Typography>
						</Box>
					)}

					<LoadingDialog open={progress.flag} message={progress.message} />
					<LoadSearchBidBookModalView
						open={openBidDialog}
						price={!loadToUse?.bidnow && loadToUse.originalPrice ? loadToUse.originalPrice : loadToUse?.price}
						estBidPrice={loadToUse?.est_bid_price}
						bookNow={loadToUse?.booknow}
						bidNow={loadToUse?.bidnow}
						onClose={() => setOpenBidDialog(false)}
						onAccept={(action, data) => executeAction(action, data)}
						loadId={loadToUse?.tripid}
						bidOffline={loadToUse?.owner === "echo"}
						brokerBlacklisted={loadToUse?.brokerBlacklisted}
					/>
					<SmarthopConfirmDialog
						open={!!confirmDialog.flag}
						title={confirmDialog.prevTitle ?? confirmDialog.title}
						message={confirmDialog.message}
						handleClose={() =>
							setConfirmDialog({ title: null, message: null, flag: false, action: null, isWarning: false })
						}
						handleAccept={() => {
							if (!confirmDialog?.prevTitle) {
								executeAction(confirmDialog.action);
							} else {
								setConfirmDialog({ ...confirmDialog, prevTitle: null, message: null });
							}
						}}
						mode={confirmDialog?.isWarning ? "WARNING" : "NORMAL"}
					></SmarthopConfirmDialog>
					{tripInvoiceWarnings?.length && (
						<WarningConfirmDialog
							open={isWarningDialogOpen}
							warnings={tripInvoiceWarnings}
							onAccept={onAcceptWarningChanges}
							onClose={cleanTripInvoiceWarnings}
						/>
					)}
				</div>
			</Slide>
		</Box>
	);
}

export default LoadSearchSummary;
