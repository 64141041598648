import _ from "lodash";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { makeStyles } from "@material-ui/core/styles";
import { NARROW_FIELDS } from "@fuse/default-settings";
import { strategyConnect } from "../../providers/StrategyContextProvider";
import { getStrategyTreeByTruckId } from "app/services/strategyServices";

import createPanel from "../../configs/createPanel";

import {
	addCarrierId,
	addDiagnosticMessage,
	addTruckId,
	changeModel,
	changeResult,
	startLoading,
	stopLoading,
} from "../../providers/reducer/actions";

import {
	createLocationView,
	formatStartDate,
	formatEndDate,
	getCityStateFromStr,
	rewriteURLWithMapping,
	sanitizeView,
} from "app/main/strategy/utils/strategyUtils";

import { driverPreferencesToFilters } from "app/main/searchV3/utils/filtersUtils";
import { STRATEGY_URL_PARAMS_MAPPING } from "app/main/utils/urlUtils";
import { useSelector } from "react-redux";
import { getRoleLabel } from "app/services/LoginService";
import moment from "moment";

const useStyles = makeStyles((theme) => {
	return {
		buttonNormal: {
			borderRadius: "4px",
			height: NARROW_FIELDS ? "4.6rem" : "5.2rem",
		},
		buttonDisabled: {
			background: theme.palette.grey["400"] + " !important",
		},
	};
});

const noPanelEntryPoints = ["load", "savedList"];

const StrategyPanelContainer = (props) => {
	if (noPanelEntryPoints.includes(props.entryPoint)) return null;
	const { useControlPanelState, setControlPanelValue } = props?.controlPanel;

	return useControlPanelState ? (
		<StrategyPanel {...props} useControlPanelState={useControlPanelState} setControlPanelValue={setControlPanelValue} />
	) : null;
};

const StrategyPanel = ({
	truckPlan,
	dataIds,
	startLoading,
	stopLoading,
	changeResult,
	addCarrierId,
	addTruckId,
	changeModel,
	addDiagnosticMessage,
	model,
	useControlPanelState,
	onStrategySubmitted,
	entryPoint,
}) => {
	const classes = useStyles();
	const cycle = useControlPanelState("cycle");
	const user = useSelector(({ auth }) => auth.user);

	// getting role with most of the permissions
	const role = getRoleLabel();
	const hasSubaccounts = useSelector(({ auth }) => auth.account.subAccount?.hasSubaccounts);

	let form = createPanel(classes, { cycle, entryPoint, role, hasSubaccounts });
	const topLevel = entryPoint === "topLevel";

	const buildPlanData = (truckPlan, model) => {
		const planData = {};
		if (!_.isEmpty(truckPlan)) {
			planData.plan = truckPlan._id;
			planData.current_location = truckPlan.current_location;
			planData.available_date = truckPlan.current_available_date;

			// Include driver preferences from plan if available
			if (truckPlan.plan__view?.metadata?.driver__view?.metadata?.blacklisted_states_ids) {
				planData.blacklisted_states_ids = truckPlan.plan__view.metadata.driver__view.metadata.blacklisted_states_ids;
			}
		} else {
			planData.current_location = model.location_origin;
			planData.available_date = formatStartDate(model.start_date);
		}
		return planData;
	};

	const adaptModel = (model) => {
		// We destructure the views to avoid sending them in the request
		const {
			location_origin,
			location_destination,
			miles_range,
			carrier__view,
			truck__view,
			location_origin__view,
			location_destination__view,
			start_date,
			max_end_date,
			...rest
		} = model;
		const [city, state] = getCityStateFromStr(location_origin);
		const [dcity, dstate] = cycle ? [city, state] : getCityStateFromStr(location_destination) || [null, null];

		// Get driver preferences for blacklisted states
		const driverPreferences = model.truck__view?.metadata?.driver__view?.metadata
			? driverPreferencesToFilters(model.truck__view.metadata.driver__view.metadata)
			: {};

		return {
			...rest,
			start_date: formatStartDate(start_date),
			max_end_date: formatEndDate(max_end_date),
			carrier__view: sanitizeView(carrier__view),
			truck__view: sanitizeView(truck__view),
			city,
			state,
			dcity,
			dstate,
			min_distance: miles_range[0],
			max_distance: miles_range[1],
			use_delta: false,
			use_moving_avg: false,
			use_hist_avg_rpm_7d: false,
			use_nearby_clusters: false,
			//...driverPreferences, // Add blacklisted_states_ids and blacklisted_states_ids__view
		};
	};

	const buildParams = (model, truckPlan) => {
		const { carrierId } = dataIds;
		const requestModel = adaptModel(model);
		const planData = buildPlanData(truckPlan, model);
		return {
			carrier: carrierId,
			carrierId,
			...requestModel,
			...planData,
		};
	};

	const handleSubmit = async (model) => {
		try {
			const params = buildParams(model, truckPlan);
			startLoading();
			let { carrierId, truckId } = dataIds;

			const result = await getStrategyTreeByTruckId(truckId, carrierId, params);

			if (Object.keys(result).length === 1 && result?.warnings) {
				addDiagnosticMessage({
					type: "warning",
					componentName: "RequestError",
					props: { messages: ["Strategy is currently unavailable. Please try again later"] },
				});
				changeResult({});
				return;
			}

			if (result?.resData && topLevel) {
				const { carrier__view, truck__view } = model;
				const newModel = {
					...result?.resData?.strategy?.response?.queryData,
					...(result?.resData?.strategy?._id ? { _id: result?.resData?.strategy?._id } : {}),
					carrier__view: sanitizeView(carrier__view),
					truck__view: sanitizeView(truck__view),
				};
				rewriteURLWithMapping(newModel, STRATEGY_URL_PARAMS_MAPPING);
				onStrategySubmitted?.(newModel);
			}

			changeResult(result);
		} catch (error) {
			const messages = error.errors?.map((e) => e.message);
			addDiagnosticMessage({
				type: "warning",
				componentName: "RequestError",
				props: { messages },
			});
			console.error(error);
		} finally {
			stopLoading();
		}
	};

	const handleChange = (model, originalKey, valid, options, dirtyFields) => {
		const { carrier, truck } = model;
		if (carrier && carrier !== dataIds.carrierId) {
			addCarrierId(carrier);
		}
		if (truck && truck !== dataIds.truckId) {
			addTruckId(truck);
		}
		if (originalKey === "truck" || originalKey === "carrier") {
			let currentAvailableDate = moment(model?.truck__view?.metadata?.current_available_date ?? "").format(
				"YYYY-MM-DD"
			);
			currentAvailableDate = moment().diff(currentAvailableDate) > 0 ? moment() : currentAvailableDate;
			model.start_date = currentAvailableDate.valueOf();
			if (!truck) {
				model.start_date__tz_offset = null;
				model.start_date__tz = null;
			}
			const location_origin = model?.truck__view?.metadata?.current_location?.trim();
			model.location_origin = location_origin ?? null;
			model.location_origin__view = location_origin ? createLocationView(location_origin) : {};
			const current_destination = model?.truck__view?.metadata?.current_destination?.trim();
			model.location_destination = current_destination ?? null;
			model.location_destination__view = current_destination ? createLocationView(current_destination) : {};

			// Add driver preferences for blacklisted states
			if (model?.truck__view?.metadata?.driver__view?.metadata) {
				const driverPreferences = driverPreferencesToFilters(model.truck__view.metadata.driver__view.metadata);
				model.blacklisted_states = driverPreferences.blacklisted_states_ids;
				model.blacklisted_states__view = driverPreferences.blacklisted_states_ids__view;
			} else {
				// Clear blacklisted states if no driver preferences
				model.blacklisted_states = [];
				model.blacklisted_states__view = [];
			}
		}
		changeModel(model);
	};

	return (
		<SmarthopFormView
			content={form}
			mode={"CREATE"}
			dataIds={{ carrierId: dataIds?.carrierId, driverId: user?.driverId }}
			noInitValidation={true}
			onSubmit={handleSubmit}
			trackChangedFields={["ALL"]}
			onChangeCommitted={handleChange}
			data={model}
		/>
	);
};

const mapStateToProps = (state) => ({
	truckPlan: state.truckPlan,
	dataIds: state.dataIds,
	model: state.model,
	controlPanel: state.controlPanel,
	entryPoint: state.entryPoint,
});

const mapDispatchToProps = (dispatch) => ({
	startLoading: () => dispatch(startLoading()),
	stopLoading: () => dispatch(stopLoading()),
	changeResult: (result) => dispatch(changeResult(result)),
	addCarrierId: (carrier) => dispatch(addCarrierId(carrier)),
	addTruckId: (truck) => dispatch(addTruckId(truck)),
	changeModel: (model) => dispatch(changeModel(model)),
	addDiagnosticMessage: (message) => dispatch(addDiagnosticMessage(message)),
});

export default strategyConnect(mapStateToProps, mapDispatchToProps)(StrategyPanelContainer);
