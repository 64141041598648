import { useDispatch, useSelector } from "react-redux";

// Service
import { getCarrierId } from "app/services/LoginService";

// Tools
import FormSection from "app/main/tools/FormSection";
import PageSection from "app/main/common/PageSection";
import { openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import carrierDispatchingAccessForm from "@smarthop/form/configs/carrierDispatchingAccessForm";
import { incrementDataRevision } from "app/store/tools/revisionSlice";

import { isEnabled } from "app/services/featureStorageService";

function DispatcherPage(props) {
	const dispatch = useDispatch();

	const user = useSelector(({ auth }) => auth.user);

	localStorage.setItem("include_subaccounts", true);

	const viewerCarrierId = getCarrierId();
	const carrierId = props.carrierId ?? props?.params?.carrierId ?? viewerCarrierId;
	const isMyOwnerCarrier =
		carrierId === viewerCarrierId && ["CARRIER_OWNER", "CARRIER_DISPATCHER", "CARRIER_MANAGER"].includes(user.role);
	const isSubCarrier = user.linkedSubAccounts?.find((lk) => lk.carrier._id + "" === carrierId)?.carrier;
	const fromMainMenu = props.fromMainMenu;
	const isMainHandler = props.isMainHandler;
	const isAdmin = user.rolesLabels.includes("administrator");

	const listInternalAccessBtn = [];
	const listExternalAccessBtn = [];
	const generateDialog = { formId: "CARRIER_DISPATCHING_GENERATE_CODE_FORM", mode: "VIEW", dataIds: { carrierId } };
	const generateCodeBtn = {
		label: "Generate Access Code",
		action: {
			onClick: () => {
				dispatch(openLoadedFormDialog(generateDialog));
			},
		},
	};
	if ((user.mainAccount && isMyOwnerCarrier) || isAdmin) listInternalAccessBtn.push(generateCodeBtn);
	if (!user.mainAccount || isSubCarrier || isAdmin) listExternalAccessBtn.push(generateCodeBtn);

	const disconnectDialog = {
		formId: "CARRIER_DISPATCHING_DISCONNECT_FROM_CARRIER_FORM",
		mode: "DELETE",
		dataIds: { carrierId, dispatchingType: "EXTERNAL" },
	};
	const disconnectBtn = {
		label: "Disconnect Dispatcher",
		action: {
			onClick: () => {
				dispatch(openLoadedFormDialog(disconnectDialog));
			},
		},
	};
	if (user.carrierDispatcherAccess === "DISPATCHING" && isMyOwnerCarrier) listInternalAccessBtn.push(disconnectBtn);
	if (user.carrierExternalDispatcherAccess || isSubCarrier?.carrierExternalDispatcherAccess)
		listExternalAccessBtn.push(disconnectBtn);

	const onSetData = (value) => {
		localStorage.setItem("include_subaccounts", value);
		dispatch(incrementDataRevision({ event: "carrierDispatchingRevision" }));
	};

	const DispatcherAccessView = ({ main }) => {
		return (
			<>
				{main ? (
					<>
						<FormSection
							title={"Connect Access"}
							form={carrierDispatchingAccessForm({ onSetData })}
							dataIds={{ carrierId, dispatchingType: "EXTERNAL" }}
							actions={listInternalAccessBtn}
						/>
						<div className="flex flex-row w-full p-10"></div>
					</>
				) : (
					<>
						<FormSection
							title={"Parent Access"}
							formId={"CARRIER_DISPATCHING_ACCESS_FORM"}
							dataIds={{ carrierId, dispatchingType: "INTERNAL" }}
						/>
						{isEnabled("DISPATCHER_ASSISTANT_SUBACCOUNT") ? (
							<FormSection
								title={"Connect Access"}
								formId={"CARRIER_DISPATCHING_ACCESS_FORM"}
								dataIds={{ carrierId, dispatchingType: "EXTERNAL" }}
								actions={listExternalAccessBtn}
							/>
						) : (
							<div className="flex flex-row w-full p-10"></div>
						)}
					</>
				)}
			</>
		);
	};

	if (fromMainMenu && isMyOwnerCarrier) {
		return (
			<div className="flex flex-col w-full mt-24">
				<div className="flex flex-col md:flex-row items-start w-full space-x-0 md:space-x-10">
					<DispatcherAccessView main={user.mainAccount} />
				</div>
			</div>
		);
	}

	return (
		<div className="flex flex-col items-start w-full">
			<PageSection title="Dispatcher Access" classes={{ root: " min-h-auto " }}>
				<div className="flex flex-col md:flex-row items-start w-full space-x-0 md:space-x-10">
					<DispatcherAccessView main={isMainHandler} />
				</div>
			</PageSection>

			{isEnabled("TRIP_BILLING") && (
				<PageSection title="Dispatcher Plan" classes={{ root: " min-h-auto " }}>
					<div className="flex flex-col md:flex-row w-full space-x-0 md:space-x-10">
						<FormSection
							title={"Plan"}
							formId={"CARRIER_DISPATCHING_PLAN_FORM"}
							dataIds={{ carrierId }}
							actions={[
								{
									action: "edit",
									mode: "EDIT",
									label: "Assign Plan",
									auth: ["administrator", "carrier", "carrier_manager"],
								},
							]}
						/>
						<div className="flex flex-row w-full p-10"></div>
					</div>
				</PageSection>
			)}
		</div>
	);
}

export default DispatcherPage;
