import { useEffect, useRef, useState, useContext, useCallback } from "react";
import moment from "moment";

import {
	getStrategyTreeByTruckId,
	getStrategyTreeById,
	getLatestStrategyTreeByTruckId,
	saveStrategy,
	getStrategyFromTempVc,
} from "app/services/strategyServices";
import { setPreventDialogCloseCallback } from "app/store/tools/formToolsSlice";
import { closeFormDialog } from "app/store/tools/formDialogSlice";

import TreeVisualizerWithDrawer from "./TreeVisualizerWithDrawer";
import TreeVisualizerPanel from "./TreeVisualizerPanel";
import TreeVisualizerErrorsOrWarnings from "./TreeVisualizerErrorsOrWarnings";

import { loadingViewComponent } from "../common/LoadingView";
import SmarthopConfirmDialog from "@smarthop/form/SmarthopConfirmDialog";
//Market Map imports needs a better way
import MarketConditionsMap from "app/main/market-conditions/MarketConditionsMap";
import { makeStyles } from "@material-ui/core/styles";
import WithControlPanel from "app/main/tree-visualizer/control-panel/WithControlPanel";
import strategySubPanelConfig from "app/main/tree-visualizer/control-panel/configs/strategySubPanelConfig";
import { parseRolesInfo } from "app/main/utils/rolesUtils";
import { useSelector, useDispatch } from "react-redux";
import StrategySummaryWrapper from "./StrategySummaryWrapper";
import { getCurrentTruckPlan, getTrucksInformation } from "app/services/truckServices";
import _ from "@lodash";

import { getRoleLabel, isSearchEnabled } from "app/services/LoginService";

import {
	convertModelToURLParams,
	convertURLParamsToModel,
	readURLParameters,
	rewriteURLParameters,
	STRATEGY_URL_PARAMS_MAPPING,
} from "../utils/urlUtils";
import { ExpiredStrategyError, NoLatestStrategy } from "./errors/WarningsAndErrors";
import { responseToModel } from "../strategy/utils/strategyPanelMapper";
import StrategyContextProvider from "../strategy/providers/StrategyContextProvider";
import { StrategyActionContext, StrategyStateContext } from "../strategy/providers/StrategyContextProvider";
import { emptyCart, unselectPath } from "../strategy/providers/reducer/actions";
import StrategyLegend from "../strategy/components/strategy-legend/StrategyLegend";

const useStyles = makeStyles(() => ({
	map: {
		height: "50vh",
	},
}));

const cityStateExpr = /([A-Za-z]+(?: [A-Za-z]+)*),? ([A-Za-z]{2})/g;

const getCityStateFromStr = (str) => {
	if (!str) return [null, null];
	const matches = [...str.matchAll(cityStateExpr)];
	if (!matches) return [null, null];

	return matches[0][1] && matches[0][2] ? [matches[0][1], matches[0][2]] : [null, null];
};

function dataIdsToModel(dataIds) {
	const { truckPlan, truck } = dataIds;
	const { equipment } = dataIds.equipment ? dataIds : truck;
	const [city, state] = getCityStateFromStr(truckPlan?.current_location);
	const [dcity, dstate] = getCityStateFromStr(truckPlan?.current_destination) || [null, null];
	const { current_available_date: start_date } = truckPlan;
	const location_origin = city ? `${city}, ${state}` : "";
	const location_destination = dcity ? `${dcity}, ${dstate}` : "";
	const formModel = {
		...dataIds,
		equipment,
		start_date,
		pickup_ds__after: start_date,
		location_origin__view: {
			label: location_origin,
			value: location_origin,
		},
		sort_by: "balanced_profit",
		min_profit: truckPlan?.goals?.profit_remaining || 0,
		min_profit_per_leg: truckPlan?.goals?.last_profit < 0 ? 0 : -truckPlan?.goals?.last_profit || 0,
		end_market_profit: truckPlan?.goals?.profit_wtd < 0 ? 0 : -truckPlan?.goals?.profit_wtd || 0,
		// top_n: truckPlan?.goals?.profit_wtd  || 10 we need to find out where to land that does not dilute this profit
		...(dcity && dstate
			? {
					location_destination__view: {
						label: location_destination,
						value: location_destination,
					},
			  }
			: {}),
	};
	return formModel;
}

const dataMapModel = (equipment, truckPlan, roleStatus) => ({
	equipment: equipment,
	locations: [
		{ location: truckPlan.current_location },
		...(truckPlan?.current_destination ? [{ location: truckPlan?.location_destination }] : []),
	],
	origin: truckPlan?.current_location,
	destination: truckPlan?.location_destination,
	activeLocations: true,
	activeHeatMap: true,
});

const rewriteURLWithMapping = (data, mapping) => {
	const copy = JSON.parse(JSON.stringify(data));
	rewriteURLParameters(convertModelToURLParams(copy, mapping));
};

const flattenSelectedPaths = (tablePaths) => {
	if (!tablePaths || tablePaths.length === 0) return [];
	const allSelectedPaths = Object.values(tablePaths).reduce((acc, curr) => {
		if (curr.selected) acc.push(...curr.ids);
		return acc;
	}, []);
	return allSelectedPaths;
};

const createSelectedPathsCartPayload = (selectedPathsCart) => {
	if (_.isEmpty(selectedPathsCart)) return;
	const selectedPathsCartPayload = Object.entries(selectedPathsCart).reduce(
		(acc, [pathId, legs]) => ({
			...acc,
			[pathId]: { length: legs?.length, leg_ids: legs.map((leg) => leg?.data?.stop?.lane?._id) },
		}),
		{}
	);
	return selectedPathsCartPayload;
};

const TreeVisualizerView = ({
	dataIds,
	onDone,
	onProgress,
	propsErrors,
	nativeMobile,
	resultData = undefined,
	onStrategySubmitted,
	contentEmpty,
	contentHidden,
	contentListDisabled,
	setTitle,
}) => {
	useEffect(() => {
		setTitle?.("Strategy Analyzer");
		// eslint-disable-next-line
	}, []);

	const [loading, setLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [save, setSave] = useState(0);
	const [model, setModel] = useState(null);
	const [result, setResult] = useState(null);
	const [errors, setErrors] = useState(null);
	const { strategyId, controlPanel } = dataIds;
	const [targetProfit, setTargetProfit] = useState(0);
	const [openDialog, setOpenDialog] = useState(false);
	const [isComposed, setIsComposed] = useState(false);
	const [truckInfo, setTruckInfo] = useState(null);
	const [truckPlan, setTruckPlan] = useState(null);
	const [tablePaths, setTablePaths] = useState(null);

	const contextDispatch = useContext(StrategyActionContext);
	const { selectedPaths: selectedPathsCart } = useContext(StrategyStateContext);

	useEffect(() => {
		if (!(dataIds?.carrierId && dataIds?.truckId)) return;
		if (dataIds.truckPlan) return;
		const { carrierId, truckId } = dataIds;
		planDefaultValues(carrierId, truckId);
	}, []); //eslint-disable-line

	// TODO: [PATRICIO] Make topLevel evaluation more explicit
	const topLevel = _.isEmpty(dataIds);

	async function requestStrategyAndSetResult(truckId, carrierId, data) {
		setLoading(true);
		try {
			const { tree, dateRange, resData, saveSuccesful, warnings, prevStrategyId, nextStrategyId } =
				await getStrategyTreeByTruckId(truckId, carrierId, data);

			// write URL params if in top level strategy
			if (resData && topLevel) {
				const newModel = {
					...resData?.strategy?.response?.queryData,
					...(resData?.strategy?._id ? { _id: resData?.strategy?._id } : {}),
				};

				rewriteURLWithMapping(newModel, STRATEGY_URL_PARAMS_MAPPING);
				onStrategySubmitted?.(newModel);
			}
			if (warnings) {
				setErrors({ warnings });
				setLoading(false);
			} else if (tree) {
				setResult({ tree, dateRange, resData, prevStrategyId, nextStrategyId });
				setLoading(false);
			} else {
				setResult((result) => ({ ...result, prevStrategyId, nextStrategyId }));
				setSave(saveSuccesful);
				setSaveLoading(false);
			}
		} catch (error) {
			const errorMessages = error.errors?.map((e) => e.message);
			setErrors({ errorMessages });
			setLoading(false);
		}
	}

	async function requestStrategyWithId(strategyId) {
		setLoading(true);
		const result = await getStrategyTreeById(strategyId);
		setResult(result);
		setLoading(false);
	}

	async function getStrategyWithTempVirtualContractId(tempVirtualContractId, data) {
		try {
			setLoading(true);
			const { tree, dateRange, resData } = await getStrategyFromTempVc(tempVirtualContractId, data);
			setResult({ tree, dateRange, resData });
		} catch (err) {
			async function regenerate() {
				const { carrier, truck } = data;
				setErrors(null);
				await requestStrategyAndSetResult(truck, carrier, data);
			}
			setErrors({ warnings: [<ExpiredStrategyError regenerate={regenerate} />] });
		}
		setLoading(false);
	}

	const getTrucksInfo = useCallback(
		async (truckId) => {
			if (truckInfo) return;
			const trucks = await getTrucksInformation({ search: truckId });
			setTruckInfo(trucks?.items?.[0]);
		},
		[truckInfo]
	);

	async function planDefaultValues(carrierId, truckId) {
		const currentTruckPlan = await getCurrentTruckPlan(carrierId, truckId);
		if (!currentTruckPlan?.plan?.current_location) {
			setErrors({
				warnings: [
					"There is no plan available for this truck, you can still search for a strategy, but you will not be able to save it",
				],
			});
		} else {
			setTruckPlan(currentTruckPlan);
			setDefaultValues(dataIdsToModel({ truckPlan: currentTruckPlan.plan, truck: currentTruckPlan.truck }));
			!resultData &&
				!currentTruckPlan.truck.strategy_id &&
				setDataMap(dataMapModel(currentTruckPlan.truck.equipment, currentTruckPlan.plan, roleStatus));
		}
	}

	const summaryRef = useRef();
	const legendRef = useRef();

	const stack = useSelector(({ tools }) => tools.formDialog.formStack ?? []);
	const [mountStackLength] = useState(stack.length);
	const dispatch = useDispatch();

	useEffect(() => {
		// This waits for the params of the newly clicked tab to be loaded
		// in order to populate the strategy panel with the new data
		let timeOutId;
		if (topLevel) {
			timeOutId = setTimeout(() => {
				const data = convertURLParamsToModel(readURLParameters(), STRATEGY_URL_PARAMS_MAPPING);
				if (!_.isEmpty(data) && !contentHidden) {
					(async () => {
						const { carrier, truck } = data;
						const currentTruckPlan = await getCurrentTruckPlan(carrier, truck);
						loadDefaultValues({ ...data, carrierObj: currentTruckPlan?.carrier, truckObj: currentTruckPlan?.truck });
						setTruckPlan(currentTruckPlan);
					})();

					const { _id: strategyId, response: tempVirtualContractId, virtualContractId } = data;
					if (strategyId) {
						requestStrategyWithId(strategyId);
					} else if (tempVirtualContractId) {
						getStrategyWithTempVirtualContractId(tempVirtualContractId, data);
					} else if (virtualContractId) {
						console.log("virtualContractId", virtualContractId);
						getStrategyWithTempVirtualContractId(virtualContractId, data);
					}
				}
			}, 16);
		}
		return () => timeOutId && clearTimeout(timeOutId);
	}, [contentListDisabled]); //eslint-disable-line

	useEffect(() => {
		const truck = result?.resData?.strategy?.truck;
		truck && getTrucksInfo(truck);
	}, [result]); //eslint-disable-line

	useEffect(() => {
		if (resultData) getTrucksInfo(resultData.resData?.strategy?.truck);
	}, [resultData]); //eslint-disable-line

	useEffect(() => {
		if (!(dataIds?.carrierId && dataIds?.truckId) || dataIds.loadId) return;
		const { carrierId, truckId } = dataIds;
		planDefaultValues(carrierId, truckId);
	}, []); //eslint-disable-line

	useEffect(() => {
		setIsComposed(!save && !!result?.resData?.strategy?.composed);
	}, [result, save]);

	useEffect(() => {
		if (stack.length === mountStackLength && isComposed) {
			dispatch(
				setPreventDialogCloseCallback(() => {
					setOpenDialog("This strategy is not saved, would you like to exit the visualizer?");
				})
			);
		} else {
			dispatch(setPreventDialogCloseCallback(null));
		}

		return () => {
			dispatch(setPreventDialogCloseCallback(null));
		};
		// eslint-disable-next-line
	}, [isComposed, stack]);

	const controlDefaultValues = {
		folded: true,
		flexPathsEnabled: true,
		save: false,
		edgeWidthEnabled: true,
		tooltipEnabled: false,
		highlightFlexEnabled: false,
	};
	const [SmarthopControlPanel, useControlPanelState, setControlPanelValue] = WithControlPanel({
		defaultValues: controlDefaultValues,
		content: strategySubPanelConfig(controlPanel),
	});

	//HeatMap to select / un select markets and destinations
	const [dataMap, setDataMap] = useState(null);
	const user = useSelector(({ auth }) => auth.user);
	const roleStatus = parseRolesInfo(["carrier", "driver"], user);

	const roles = user.rolesLabels;

	// getting role with most of the permissions
	let role = roles.includes("administrator")
		? "administrator"
		: roles.includes("dispatcher")
		? "dispatcher"
		: roles.includes("ops support")
		? "ops support"
		: roles.includes("carrier")
		? "carrier"
		: roles.includes("driver")
		? "driver"
		: roles.includes("carrier_manager")
		? "carrier_manager"
		: roles.includes("carrier_dispatcher")
		? "carrier_dispatcher"
		: undefined;

	if (!role) {
		role = getRoleLabel();
	}

	const internalUser = ["administrator", "dispatcher", "ops support"].includes(role);
	const isNativeEnv = window?.location?.href?.includes("/native/");
	const classes = useStyles();
	const [defaultValues, setDefaultValues] = useState(null);
	const cycle = useControlPanelState("cycle");

	// Load latest strategy that corresponds to the current plan's available date and location
	useEffect(() => {
		if (!model && !strategyId && !resultData && !topLevel) {
			setLoading(true);
			const { truckId, carrierId } = dataIds;
			const getLatestStrategy = async () => {
				const { tree, dateRange, resData, nextStrategyId, prevStrategyId } = await getLatestStrategyTreeByTruckId(
					truckId,
					carrierId,
					{ compute_plan_data: true }
				);
				if (tree) {
					setResult({ tree, dateRange, resData, nextStrategyId, prevStrategyId });
					setDataMap(null);
				}
			};
			if (!propsErrors) {
				getLatestStrategy().catch((err) => {
					console.error(err);
				});
			} else {
				setErrors(propsErrors);
			}
			setLoading(false);
		}
	}, []); // eslint-disable-line

	/**
	 * Validate the prediction start_date query parameter using an upper bound.
	 * The upper bound abides by Axle's 7 day window forecast
	 * @param {String} start_date
	 * @param {Number} dayUpperBound
	 * @returns {String} Validated prediction date
	 */
	const formatStartDate = (start_date, dayUpperBound = 6) => {
		const datesDiff = moment(start_date).diff(moment(), "days");
		const formattedStartDate = !start_date
			? moment()
			: datesDiff <= dayUpperBound
			? moment(start_date)
			: moment().add(dayUpperBound, "days");
		return formattedStartDate.format("YYYY-MM-DD");
	};

	async function saveVirtualContract() {
		if (!result) return;
		setSaveLoading(true);
		const virtualContractId = result.resData?.strategy?.response?._id;

		const data = {
			selected_paths: flattenSelectedPaths(tablePaths),
			selected_paths_legs: createSelectedPathsCartPayload(selectedPathsCart),
		};
		try {
			const { saveSuccesful, prevStrategyId, nextStrategyId, strategyId, reloadStrategy } = await saveStrategy(
				virtualContractId,
				data
			);
			if (saveSuccesful) {
				contextDispatch(emptyCart());
			}
			setSave(saveSuccesful);
			setResult((result) => ({ ...result, prevStrategyId, nextStrategyId, strategyId }));
			if (topLevel) {
				const urlModel = convertURLParamsToModel(readURLParameters(), STRATEGY_URL_PARAMS_MAPPING);
				rewriteURLWithMapping({ ...urlModel, _id: strategyId }, STRATEGY_URL_PARAMS_MAPPING);
				// onStrategySubmitted?.({ ...urlModel, _id: strategyId });
			}
			if (reloadStrategy) {
				requestStrategyWithId(strategyId);
			}
		} catch (err) {
			setErrors({ errorMessages: err?.errors?.map((e) => e?.message) });
			setSave(0);
		}
		setSaveLoading(false);
	}

	async function virtualContractMini() {
		if (!model) return;
		if (!save) setLoading(true);
		const {
			equipment,
			start_date,
			path_length,
			n_markets,
			min_volume,
			min_profit,
			min_profit_per_leg,
			max_end_date,
			miles_range,
			max_paths,
			end_market_profit,
			blacklisted_states,
		} = model;
		const [city, state] = getCityStateFromStr(model?.location_origin);
		const [dcity, dstate] = getCityStateFromStr(cycle ? model?.location_origin : model?.location_destination) || [
			null,
			null,
		];
		const sort_by = model?.sort_by;

		// handle new panel calls with carrier and truck
		let carrierId, truckId, currentTruckPlan, plan;
		if (model.truck) {
			const { carrier, truck } = model;
			truckId = truck;
			carrierId = carrier || user.carrierId;
			currentTruckPlan = truckPlan || (await getCurrentTruckPlan(carrierId, truckId));
			plan = currentTruckPlan?._id;
			setTruckPlan(currentTruckPlan);
		} else {
			carrierId = dataIds?.carrierId;
			truckId = dataIds?.truckId;
			plan = truckPlan?.plan?._id;
		}

		const { current_location, current_available_date: available_date } = currentTruckPlan?.current_location
			? // TODO [PATRICIO] This will be address with the new strategy provider, remove then.
			  // check if truckPlan passed as object already
			  currentTruckPlan
			: // check if plan inside truckPlan object
			currentTruckPlan?.plan
			? currentTruckPlan?.plan
			: // finally try to get the data from the model
			  {
					current_location: model?.location_origin,
					current_available_date: model?.start_date,
			  };

		const data = {
			equipment,
			city,
			state,
			dcity,
			dstate,
			path_length,
			n_markets,
			min_volume,
			min_profit,
			min_profit_per_leg,
			end_market_profit,
			max_paths,
			sort_by,
			carrierId,
			carrier: carrierId,
			plan,
			current_location,
			available_date,
			start_date: formatStartDate(start_date),
			max_end_date: max_end_date ? formatStartDate(max_end_date) : undefined,
			min_distance: miles_range[0],
			max_distance: miles_range[1],
			//top_first_move,
			//first_leg_volume,
			//first_moves: !!first_leg_volume && !!top_first_move,
			blacklisted_states,
			save,
		};

		if (!internalUser && (!carrierId || !truckId)) {
			carrierId = user.carrierId;
			truckId = model.truck;
			data.carrier = data.carrierId = carrierId;
		}

		setTargetProfit(min_profit);
		await requestStrategyAndSetResult(truckId, carrierId, data);
	}

	useEffect(() => {
		setResult(null);
		if (strategyId) {
			requestStrategyWithId(strategyId);
		} else {
			virtualContractMini();
		}
		// eslint-disable-next-line
	}, [model]);

	useEffect(() => {
		if (save === 1) saveVirtualContract();
		// eslint-disable-next-line
	}, [save]);

	useEffect(() => {
		if (!isNativeEnv && !strategyId && truckPlan) {
			setDataMap(dataMapModel(truckPlan.truck.equipment, truckPlan, roleStatus));
		}
		// eslint-disable-next-line
	}, []);

	const handleSubmit = (model) => {
		setSave(0);
		setModel(model);
		contextDispatch(emptyCart());
	};

	const handleNext = async () => setResult(await getStrategyTreeById(result?.nextStrategyId));
	const handlePrev = async () => setResult(await getStrategyTreeById(result?.prevStrategyId));

	const handleLoad = async () => {
		if (result) {
			let data = {};
			if (topLevel && !contentEmpty) {
				data = { carrierObj: truckPlan.carrier, truckObj: truckPlan.truck };
			}
			loadDefaultValues({ ...result?.resData?.strategy?.response?.queryData, ...data });
		}
	};

	function loadDefaultValues(data) {
		try {
			data = typeof data === "string" ? JSON.parse(data) : data;
			setDefaultValues(data ? responseToModel(data) : null);
		} catch (error) {
			return;
		}
	}

	const disableSaving = () => {
		const disableForDriver = !isSearchEnabled();
		const fromStrategyId = strategyId || result?.resData?.isMongo || resultData?.resData?.isMongo;
		const hasPreviousOrNext = result?.prevStrategyId || result?.nextStrategyId;
		const alreadySaved = fromStrategyId || hasPreviousOrNext || !truckPlan;
		return Boolean(disableForDriver || alreadySaved);
	};

	const handleAccept = () => {
		setOpenDialog(false);
		dispatch(closeFormDialog());
	};

	const handleDialogClose = () => {
		setOpenDialog(false);
	};

	const handleSave = () => {
		summaryRef.current?.open();
		summaryRef.current?.setTitle("Saving Summary");
		summaryRef.current?.setActions([
			{
				label: "Save",
				onClick: () => {
					setSave(1);
				},
				closeOnClick: true,
			},
		]);
		summaryRef.current?.setMessage("You're about to save this strategy with the summary details outlined below.");
		summaryRef.current?.setSaving(true);
	};

	const handleOpenSummary = () => {
		summaryRef.current?.open();
		summaryRef.current?.setTitle("Strategy Summary");
		summaryRef.current?.setActions([]);
		summaryRef.current?.setMessage("");
		summaryRef.current?.setSaving(false);
	};

	const handleOpenLegend = () => {
		legendRef.current?.open();
	};

	const handleLatestStrategy = async () => {
		let latestStrategy = await getLatestStrategyTreeByTruckId(defaultValues?.truck, defaultValues?.carrier, {
			compute_plan_data: true,
		});
		if (!latestStrategy?.tree) {
			return setErrors({ warnings: [<NoLatestStrategy />] });
		}
		setResult(latestStrategy);
	};

	useEffect(() => {
		if (tablePaths && !Object.values(tablePaths).some((e) => e.selected)) {
			summaryRef.current?.setError("At least one path should be selected before saving the strategy");
		} else {
			summaryRef.current?.setError("");
		}
	}, [tablePaths]);

	useEffect(() => {
		contextDispatch(unselectPath());
	}, [result, contextDispatch]);

	if (contentListDisabled) return null;
	return (
		<>
			<div className={contentHidden ? "hidden" : ""}>
				{!strategyId && controlPanel !== "load" && (
					<TreeVisualizerPanel
						onSubmit={handleSubmit}
						defaultValues={defaultValues}
						options={{ cycle, topLevel }}
						role={role}
						user={user}
					/>
				)}
				{!model && dataMap && !loading && (
					<div className={classes.map + " w-full"}>
						<MarketConditionsMap data={dataMap} />
					</div>
				)}
				{!errors && (model || strategyId || result || resultData) && (
					<SmarthopControlPanel
						callbacks={{
							previous: handlePrev,
							next: handleNext,
							load: handleLoad,
							save: handleSave,
							strategySummary: handleOpenSummary,
							latest: handleLatestStrategy,
							legend: handleOpenLegend,
						}}
						disabled={{
							previous: !result?.prevStrategyId,
							next: !result?.nextStrategyId,
							latest: !result?.nextStrategyId,
							save: disableSaving(),
							strategySummary: !(result || resultData),
						}}
						loading={{ save: saveLoading }}
					/>
				)}
				{loading
					? loadingViewComponent()
					: (strategyId || result || resultData) && (
							<div>
								{strategyId && (
									<>
										<p className=" MuiTab-textColorInherit-577 text-14 p-14 ">
											<b>Carrier</b> {dataIds?.carrierName || "No Carrier Name"} &nbsp;|&nbsp;
											<b>Truck</b> {dataIds?.truckFriendlyId || "No Truck Friendly Id"} &nbsp;|&nbsp;
											<b>Equipment</b> {dataIds?.equipment} &nbsp;|&nbsp;
											<b>Computed</b> {moment(result?.resData?.strategy.createdAt).format("MMM DD yy HH:mm")}{" "}
											&nbsp;|&nbsp;
											<b>Available Date</b> {moment(result?.resData?.strategy?.available_date).format("MMM DD yy")}{" "}
											&nbsp;|&nbsp;
											<b>Empty Location</b> {result?.resData?.strategy?.current_location}
											<br />
										</p>
									</>
								)}
								{(result || resultData) && (
									<TreeVisualizerWithDrawer
										useControlPanelState={useControlPanelState}
										setControlPanelValue={setControlPanelValue}
										result={result || resultData}
										targetProfit={targetProfit}
										setErrors={setErrors}
										topLevel={topLevel}
										disableSaving={disableSaving}
									/>
								)}
							</div>
					  )}
				{errors && <TreeVisualizerErrorsOrWarnings errors={errors} setErrors={setErrors} />}
				<SmarthopConfirmDialog
					open={!!openDialog}
					message={openDialog}
					handleClose={handleDialogClose}
					handleAccept={handleAccept}
					closeMsg={"No"}
					acceptMsg={"Yes"}
				/>
				{(result || resultData) && (
					<StrategySummaryWrapper
						ref={summaryRef}
						strategy={result?.resData?.strategy || resultData?.resData?.strategy}
						strategyReqData={
							result?.resData?.strategy?.response?.queryData || resultData?.resData?.strategy?.response?.queryData
						}
						truckInfo={truckInfo}
						tablePaths={tablePaths}
						setTablePaths={setTablePaths}
						mountStackLength={mountStackLength}
					/>
				)}
				<StrategyLegend ref={legendRef} />
			</div>
		</>
	);
};

const TreeVisualizerWithProvider = (props) => (
	<StrategyContextProvider>
		<TreeVisualizerView {...props} />
	</StrategyContextProvider>
);

export default TreeVisualizerWithProvider;
