import i18next from "i18next";
import sp from "./navigation-i18n/es";

// Utils
import { authRoles } from "app/main/consts";
import { SMARTHOP_WALLET_SECTION_LABEL } from "app/main/utils/financeUtils";
import { hasRequiredGateKeepers, roleRestrictions } from "app/main/utils/rolesUtils";

// Services
import { isEnabled } from "app/services/featureStorageService";

i18next.addResourceBundle("es", "navigation", sp);

const navigationConfig = [
	{
		id: "home",
		title: "Home",
		type: "item",
		icon: "home",
		auth: [...authRoles.all, ...authRoles.deactivated],
		url: "/home",
	},
	{
		id: "search-web",
		title: "Search",
		type: "item",
		icon: "search",
		auth: [...authRoles.all, { permission_search_on: true }],
		url: "/search",
		visible: () => !(window.innerWidth < 960),
	},
	{
		id: "search-mobile",
		title: "Search",
		type: "item",
		icon: "search",
		auth: [...authRoles.all, { permission_search_on: true }],
		url: "/load",
		visible: () => window.innerWidth < 960,
	},
	{
		id: "strategy",
		title: "Virtual Contracts",
		type: "group",
		icon: "account_tree",
		auth: [...authRoles.all],
		visible: () => isEnabled("ENABLE_VIRTUAL_CONTRACTS"),
		children: [
			{
				id: "saved_virtual_contracts",
				title: "Available Contracts",
				type: "item",
				icon: "list_alt",
				auth: authRoles.admin,
				url: "/admin/virtual_contracts",
			},
			{
				id: "strategy-main",
				title: "On-demand",
				type: "item",
				icon: "account_tree",
				auth: authRoles.admin,
				url: "/strategy",
				visible: () => !(window.innerWidth < 960),
			},
			{
				id: "saved_strategies",
				title: "Assigned Contracts",
				type: "item",
				icon: "local_shipping",
				auth: authRoles.admin,
				url: "/admin/saved_strategies",
			},
		],
	},
	{
		id: "profile-goals",
		title: "Performance",
		type: "item",
		icon: "track_changes",
		auth: [...authRoles.carrierPowerUsers, ...authRoles.carrierGeneric, ...authRoles.carrierDispatcher],
		url: "/profile/goals",
		visible: (user) => {
			const isInvestor = user.gatekeepers?.permission_investor_on;
			const userRole = user.rolesLabels;
			const role = Array.isArray(userRole) ? userRole[0] : userRole;
			return (
				(isInvestor && role === "carrier_generic" && user?.onboardingStatusPefrormance === "DONE") ||
				(authRoles.carrierDispatcher.includes(role) && user?.onboardingStatusPefrormance === "DONE") ||
				authRoles.carrierPowerUsers.includes(role)
			);
		},
	},
	{
		id: "dispatching-carrier",
		title: "Dispatching",
		type: "item",
		icon: "support_agent",
		auth: [...authRoles.externalDispatchingUsers, ...authRoles.carrierGeneric, ...authRoles.driver],
		url: "/dispatching",
		visible: (user) => {
			const role = user.role;
			const gk = user.gatekeepers;
			if (role === "CARRIER_GENERIC" && !gk.permission_investor_on) {
				return false;
			}
			if (roleRestrictions.permission_trips_access.includes(user.role)) {
				const hasTripsPermission = hasRequiredGateKeepers(user, { permission_trips_access: "viewer" });
				return hasTripsPermission;
			}
			return true;
		},
	},
	{
		id: "dispatching-internal",
		title: "Dispatching",
		type: "item",
		icon: "support_agent",
		auth: authRoles.internalDispatchingUsers,
		url: "/profile/load/dispatching",
	},
	{
		id: "invoicing-carrier",
		title: `Invoices`,
		type: "item",
		icon: "receipt",
		auth: [...authRoles.carrierPowerUsers, ...authRoles.carrierDispatcher],
		url: "/billing/invoicing",
		gk: { permission_invoice_access: "viewer" },
	},
	{
		id: "smartfuel",
		title: `SmartFuel`,
		type: "item",
		icon: "local_gas_station",
		auth: authRoles.carrierPowerUsers,
		url: "/smartfuel",
		visible: (user) => user.onboardingFuelModule === "ENABLED",
	},
	{
		id: "smartfuel-driver",
		title: `SmartFuel`,
		type: "item",
		icon: "local_gas_station",
		auth: authRoles.driver,
		url: "/driver-smartfuel",
		visible: (user) => user.onboardingFuelModule === "ENABLED",
	},
	{
		id: "payroll-carrier",
		title: `Payroll`,
		type: "item",
		icon: "local_atm",
		auth: authRoles.carrierPowerUsers,
		url: "/billing/v3/payroll",
		gk: { permission_payroll_access: "viewer" },
	},
	{
		id: "payroll-driver",
		title: "Payroll",
		type: "item",
		icon: "local_atm",
		auth: authRoles.driver,
		url: "/v3/driver-payroll",
		visible: (user) => user.enterpriseFeatures?.payroll_module_flag,
	},
	{
		id: "payroll-investor",
		title: "Payroll",
		type: "item",
		icon: "local_atm",
		auth: [...authRoles.carrierGeneric, ...authRoles.carrierDispatcher],
		url: "/v3/payroll",
		visible: (user) => {
			const isInvestor = user.gatekeepers?.permission_investor_on;
			const payrollAccessible = user.payrollSettings?.payrollAccessible;
			return user.enterpriseFeatures?.payroll_module_flag && (isInvestor || payrollAccessible);
		},
	},
	{
		id: "settings",
		title: "Settings",
		type: "group",
		icon: "settings",
		auth: [
			...authRoles.internalDispatchingUsers,
			...authRoles.carrierPowerUsers,
			...authRoles.carrierDispatcher,
			...authRoles.driver,
			...authRoles.deactivated,
		],
		children: [
			{
				type: "divider",
				id: "divider-1",
				auth: [
					...authRoles.internalDispatchingUsers,
					...authRoles.carrierPowerUsers,
					...authRoles.carrierDispatcher,
					...authRoles.driver,
					...authRoles.deactivated,
				],
			},
			{
				id: "profile-user-details",
				title: "Profile",
				type: "item",
				icon: "person",
				auth: [...authRoles.all, ...authRoles.deactivated],
				url: "/profile/details",
			},
			{
				id: "profile-account",
				title: "Account",
				type: "group",
				auth: [...authRoles.carrierPowerUsers],
				children: [
					{
						id: "profile-account-users",
						title: "Users",
						type: "item",
						icon: "people_alt",
						auth: [...authRoles.carrierPowerUsers],
						url: "/profile/management",
					},
					{
						id: "profile-account-billing",
						title: "Billing",
						type: "item",
						icon: "credit_card",
						auth: [...authRoles.carrierPowerUsers],
						url: "/billing/details",
						visible: (user) => {
							const role = Array.isArray(user.rolesLabels) ? user.rolesLabels[0] : user.rolesLabels;
							if (role === "carrier_manager") {
								return user.gatekeepers.permission_billing_access === "editor";
							}
							return role === "carrier";
						},
					},
					{
						id: "finance-smartpay",
						title: SMARTHOP_WALLET_SECTION_LABEL,
						type: "item",
						icon: "attach_money",
						visible: () => false, // TODO: WALLET_FEATURE gk
						auth: authRoles.carrier,
						url: "/profile/smarthop-wallet",
					},
					{
						id: "profile-history",
						title: "History",
						type: "item",
						icon: "history",
						auth: authRoles.carrier,
						url: "/profile/history",
					},
					{
						id: "profile-reports",
						title: "Reports",
						type: "item",
						icon: "text_snippet",
						auth: [...authRoles.carrierPowerUsers],
						url: "/profile/reports",
					},
				],
			},
			{
				id: "profile-dispatching",
				title: "Dispatcher",
				type: "group",
				auth: [...authRoles.carrierPowerUsers, ...authRoles.carrierDispatcher],
				visible: (user) => {
					if (user.role === "CARRIER_DISPATCHER") {
						return user.gatekeepers.trucks_full_access_all_context;
					}
					return true;
				},
				children: [
					{
						id: "profile-dispatching-details",
						title: "Plan & Access",
						type: "item",
						icon: "settings_ethernet",
						auth: [...authRoles.carrierPowerUsers, ...authRoles.carrierDispatcher],
						url: "/profile/dispatcher-details",
						visible: (user) => {
							return user.mainAccount;
						},
					},
					{
						id: "profile-dispatching-accounts",
						title: "Sub-Accounts",
						type: "item",
						icon: "account_tree",
						auth: [...authRoles.carrierPowerUsers, ...authRoles.carrierDispatcher],
						url: "/profile/dispatcher-subaccounts",
						visible: (user) => {
							return user.mainAccount;
						},
					},
					{
						id: "profile-dispatching-billing",
						title: "Billing",
						type: "item",
						icon: "request_quote",
						auth: [...authRoles.carrierPowerUsers, ...authRoles.carrierDispatcher],
						url: "/profile/dispatcher-billing",
						visible: (user) => {
							return user.mainAccount && isEnabled("TRIP_BILLING");
						},
					},
				],
			},
			{
				id: "profile-fleer",
				title: "Fleet",
				type: "group",
				auth: [...authRoles.carrierPowerUsers, ...authRoles.carrierDispatcher],
				visible: (user) => {
					if (user.role === "CARRIER_DISPATCHER") {
						return user.gatekeepers.trucks_full_access_all_context;
					}
					return true;
				},
				children: [
					{
						id: "profile-carrier-company",
						title: "Details",
						type: "item",
						icon: "business",
						auth: [...authRoles.carrierPowerUsers, ...authRoles.carrierDispatcher],
						url: "/profile/company",
					},
					{
						id: "profile-carrier-assets",
						title: "Assets",
						type: "item",
						icon: "local_shipping",
						auth: [...authRoles.carrierPowerUsers, ...authRoles.carrierDispatcher],
						url: "/profile/assets",
					},
					{
						id: "profile-costs",
						title: "Costs",
						type: "item",
						icon: "calculate",
						auth: [...authRoles.carrierPowerUsers, ...authRoles.carrierDispatcher],
						url: "/profile/costs",
					},
					{
						id: "profile-intergations-brokers",
						title: "Integrations",
						type: "item",
						icon: "settings_input_composite",
						auth: [...authRoles.carrierPowerUsers, ...authRoles.carrierDispatcher],
						url: "/profile/integrations/brokers",
					},
					{
						id: "profile-customers",
						title: "Customers",
						type: "item",
						icon: "people",
						auth: [...authRoles.carrierPowerUsers],
						url: "/profile/customers",
						visible: (user) => {
							if (user.userTier === "TIER_STARTER") return false;
							return true;
						},
					},
				],
			},
		],
	},
	{
		type: "divider",
		id: "divider-1",
		auth: authRoles.internalDispatchingUsers,
	},
	{
		id: "internal-dashboards",
		title: "Data",
		type: "group",
		icon: "dashboard",
		auth: authRoles.internalPowerUsers,
		children: [
			{
				type: "divider",
				id: "divider-1",
				auth: authRoles.internalPowerUsers,
			},
			{
				id: "internal-users",
				title: "General",
				type: "group",
				auth: authRoles.internalPowerUsers,
				children: [
					{
						id: "profile-users-dashboard",
						title: "Users",
						type: "item",
						icon: "group",
						auth: authRoles.internalDispatchingUsers,
						url: "/profile/users/dashboard?tab=1",
					},
					{
						id: "profile-carreirs-dashboard",
						title: "Carriers",
						type: "item",
						icon: "folder_open",
						auth: authRoles.internalDispatchingUsers,
						url: "/profile/carrier/dashboard?tab=1",
					},
					{
						id: "archives",
						title: "Archive",
						type: "item",
						icon: "delete",
						auth: authRoles.internalPowerUsers,
						url: "/admin/archive",
						visible: () => {
							return isEnabled("ENABLE_ARCHIVE_CARRIER");
						},
					},
				],
			},
		],
	},
	{
		id: "billing",
		title: "Fin-Tech",
		type: "group",
		icon: "local_atm",
		auth: authRoles.internalPowerUsers,
		children: [
			{
				type: "divider",
				id: "billing-divider",
				auth: authRoles.internalPowerUsers,
			},
			{
				id: "billing-general",
				title: "General",
				type: "group",
				auth: authRoles.internalPowerUsers,
				children: [
					{
						id: "billing-plans",
						title: "Plans",
						translate: "PLANS",
						type: "item",
						icon: "card_membership",
						auth: authRoles.internalPowerUsers,
						url: "/admin/billing/plans",
					},
					{
						id: "billing-overview",
						title: "Billing",
						translate: "UNPAID_INVOICES",
						type: "item",
						icon: "payment",
						auth: authRoles.admin,
						url: "/admin/billing/overview",
					},
					{
						id: "billing-trip-invoicing",
						title: "Invoicing",
						translate: "",
						type: "item",
						icon: "description",
						auth: authRoles.admin,
						url: "/admin/billing/trips/invoicing",
					},
					{
						id: "fuel-dashboard",
						title: "Fuel",
						translate: "",
						type: "item",
						icon: "local_gas_station",
						auth: authRoles.admin,
						url: "/admin/fuel",
					},
				],
			},
		],
	},
	{
		id: "internal-system-jobs",
		title: "Background Jobs",
		type: "group",
		icon: "settings_backup_restore",
		auth: authRoles.internalPowerUsers,
		children: [
			{
				type: "divider",
				id: "divider-1",
				auth: authRoles.internalPowerUsers,
			},
			{
				id: "internal-search",
				title: "Search Jobs",
				type: "group",
				auth: authRoles.internalPowerUsers,
				children: [
					{
						id: "search-requests",
						title: "Search Dashboard",
						type: "item",
						icon: "pageview",
						auth: authRoles.internalPowerUsers,
						url: "/admin/search/requests",
					},
				],
			},
			{
				id: "internal-rr",
				title: "Assignments Jobs",
				type: "group",
				auth: authRoles.internalPowerUsers,
				children: [
					{
						id: "assignment-requests",
						title: "Assignments Dashboard",
						type: "item",
						icon: "assignment",
						auth: authRoles.internalPowerUsers,
						url: "/admin/assignments/requests",
					},
				],
			},
			{
				id: "internal-cron-jobs",
				title: "Cron Jobs",
				type: "group",
				auth: authRoles.admin,
				children: [
					{
						id: "all-cron-jobs-configs",
						title: "Cron Dashboard",
						type: "item",
						icon: "access_time",
						auth: authRoles.admin,
						url: "/admin/cronjobs",
					},
				],
			},
			{
				id: "internal-analytics-jobs",
				title: "Analytics Jobs",
				type: "group",
				auth: authRoles.admin,
				children: [
					{
						id: "all-cron-jobs-configs",
						title: "Analytics Dashboard",
						type: "item",
						icon: "bar_chart",
						auth: authRoles.admin,
						url: "/admin/analyticsjobs",
					},
				],
			},
			{
				id: "internal-transaction",
				title: "Transactions",
				type: "group",
				auth: authRoles.internalPowerUsers,
				children: [
					{
						id: "transactions-all",
						title: "State Machine",
						type: "item",
						icon: "published_with_changes",
						auth: authRoles.admin,
						url: "/admin/transactions/all",
					},
					{
						id: "transactions-booking",
						title: "Digital Booking",
						type: "item",
						icon: "book",
						auth: authRoles.internalPowerUsers,
						url: "/admin/transactions/booking",
					},
					{
						id: "transactions-post-trucks",
						title: "Post trucks",
						type: "item",
						icon: "settings_input_antenna",
						auth: authRoles.internalPowerUsers,
						url: "/admin/transactions/post-truck",
					},
					{
						id: "transactions-creation-trip",
						title: "Trip Creation",
						type: "item",
						icon: "local_shipping",
						auth: authRoles.internalPowerUsers,
						url: "/admin/transactions/creation-trip",
					},
					{
						id: "transactions-multi-upload-file",
						title: "Multi Upload File",
						type: "item",
						icon: "upload",
						auth: authRoles.internalPowerUsers,
						url: "/admin/transactions/multi-upload-file",
					},
					{
						id: "transactions-finance-transfer-funds",
						title: "Funds Transfer",
						type: "item",
						icon: "attach_money",
						visible: () => false, // TODO: WALLET_FEATURE gk
						auth: authRoles.internalPowerUsers,
						url: "/admin/transactions/finance-transfer-funds",
					},
					{
						id: "transactions-smarthop-wallet-close",
						title: "SH Wallet Close",
						type: "item",
						icon: "account_balance_wallet",
						visible: () => false, // TODO: WALLET_FEATURE gk
						auth: authRoles.internalPowerUsers,
						url: "/admin/transactions/smarthop-wallet-close",
					},
					{
						id: "transactions-create-business-debit-card",
						title: "Debit Cards Requested",
						type: "item",
						icon: "attach_money",
						visible: () => false, // TODO: WALLET_FEATURE gk
						auth: authRoles.internalPowerUsers,
						url: "/admin/transactions/create-business-debit-card",
					},
				],
			},
		],
	},
	{
		id: "internal-system",
		title: "System",
		type: "group",
		icon: "construction",
		auth: authRoles.internalPowerUsers,
		children: [
			{
				type: "divider",
				id: "divider-1",
				auth: authRoles.internalPowerUsers,
			},
			{
				id: "internal-users",
				title: "General",
				type: "group",
				auth: authRoles.internalPowerUsers,
				children: [
					{
						id: "profile-brokers",
						title: "Brokers",
						type: "item",
						icon: "device_hub",
						auth: authRoles.internalPowerUsers,
						url: "/admin/brokers",
					},
					{
						id: "internal-loads",
						title: "Internal Loads",
						type: "item",
						icon: "device_hub",
						auth: authRoles.internalPowerUsers,
						url: "/admin/internal-loads",
					},
					{
						id: "cost-structures",
						title: "Cost Structures",
						type: "item",
						icon: "analytics",
						auth: authRoles.internalPowerUsers,
						url: "/costs-structures",
					},
					{
						id: "releases",
						title: "Releases",
						type: "item",
						icon: "new_releases",
						auth: authRoles.internalPowerUsers,
						url: "/admin/releases",
					},
					{
						id: "issues",
						title: "System Issues",
						type: "item",
						icon: "bug_report",
						auth: authRoles.internalPowerUsers,
						url: "/admin/issues",
					},
				],
			},
			{
				id: "user_activity",
				title: "Activity",
				type: "group",
				auth: authRoles.internalPowerUsers,
				children: [
					{
						id: "users-actions",
						title: "User Actions",
						type: "item",
						icon: "person_search",
						auth: authRoles.internalPowerUsers,
						url: "/admin/users-actions",
					},
					{
						id: "profile-history-1",
						title: "History",
						type: "item",
						icon: "history",
						auth: authRoles.admin,
						url: "/profile/history",
					},
					{
						id: "communications",
						title: "Communications",
						type: "item",
						icon: "mark_email_read",
						auth: authRoles.admin,
						url: "/admin/communications",
					},
				],
			},
			{
				id: "internal-messenger",
				title: "Messenger",
				type: "group",
				auth: authRoles.admin,
				children: [
					{
						id: "channels-dashboard",
						title: "Inbox Dashboard",
						type: "item",
						icon: "chat",
						auth: authRoles.admin,
						url: "/admin/channels",
					},
				],
			},
			{
				id: "internal-analytics",
				title: "Analytics",
				type: "group",
				auth: authRoles.admin,
				children: [
					{
						id: "analytics-metrcis-dashboard",
						title: "Metrics Dashboard",
						type: "item",
						icon: "bar_chart",
						auth: authRoles.admin,
						url: "/admin/analytics/metrics",
					},
					{
						id: "analytics-dashboard-builder",
						title: "Dashboards Builder",
						type: "item",
						icon: "tune",
						auth: authRoles.admin,
						url: "/admin/analytics/builder",
					},
				],
			},
			{
				id: "internal-integrations",
				title: "Integrations",
				type: "group",
				auth: authRoles.admin,
				children: [
					{
						id: "load-tracking",
						title: "Tracking",
						type: "item",
						icon: "list_alt",
						auth: authRoles.admin,
						url: "/admin/tracking",
					},
					{
						id: "load-cache-logs",
						title: "Load Cache Logs",
						type: "item",
						icon: "list_alt",
						auth: authRoles.admin,
						url: "/admin/cache-logs",
					},
					{
						id: "credentials",
						title: "Credentials",
						type: "item",
						icon: "list_alt",
						auth: authRoles.admin,
						url: "/admin/credentials",
					},
				],
			},
			{
				id: "strategies",
				title: "Strategies",
				type: "group",
				auth: authRoles.admin,
				children: [
					{
						id: "saved_strategies",
						title: "Generated Strategies",
						type: "item",
						icon: "list_alt",
						auth: authRoles.admin,
						url: "/admin/saved_strategies",
					},
				],
			},
			{
				id: "gatekeeping",
				title: "Gatekeepers",
				type: "group",
				auth: authRoles.admin,
				children: [
					{
						id: "features",
						title: "Features",
						type: "item",
						icon: "vpn_key",
						auth: authRoles.admin,
						url: "/admin/features",
					},
				],
			},
			{
				id: "parsing",
				title: "Parsing",
				type: "group",
				auth: authRoles.admin,
				children: [
					{
						id: "parsing-tools",
						title: "Tools",
						type: "item",
						icon: "search",
						auth: authRoles.admin,
						url: "/admin/parsing-tools",
					},
				],
			},
		],
	},
];

export default navigationConfig;
