import { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import SmarthopChatMessageViewContainer from "@smarthop/views/SmarthopChatMessageViewContainer";

const HEADER = {
	DRIVER_ITINERARY: { title: "Itinerary Sent" },
	DRIVER_BOL_NOTIFICATION: { title: "Upload Files" },
	DRIVER_REUPLOAD_BOL_NOTIFICATION: { title: "Re-upload File" },
};

function MessageBasicSms(props) {
	const variant = props.variant;
	const message = props.message;

	const checkLink = (text) => {
		var urlRegex = /(https?:\/\/[^\s]+)/g;
		return text?.replace?.(urlRegex, function (url) {
			return `<${url}`;
		});
	};
	let text = checkLink(message?.content?.text);
	let words = text?.split(" ");

	const handleClick = (link) => {
		const reactNative = window?.ReactNativeWebView ?? false;
		if (reactNative) {
			window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "EXTERNAL_LINK", url: link }));
		} else {
			window.open(link);
		}
	};

	return (
		<SmarthopChatMessageViewContainer
			enableLeftBorder={true}
			headerConfig={{
				title: HEADER[message?.type].title,
				subtitle: <div className="-mt-5">Sent via SMS</div>,
				showDivider: true,
			}}
			contentFields={[
				{
					type: "CUSTOM",
					component: (
						<Typography
							color={"inherit"}
							className={`font-normal break-all text-12 max-w-sm md:text-14`}
							component="div"
							style={{ wordBreak: "break-word" }}
						>
							<Fragment>
								{words?.map((word) => {
									if (word.includes("<")) {
										let link = word.split("<")[1];
										if (word.includes(`\n<`)) link = `\n${link}`;
										return (
											<Link className="cursor-pointer" onClick={() => handleClick(link)}>
												{link + " "}
											</Link>
										);
									}
									if (word === "") return "  ";
									return word + " ";
								})}
							</Fragment>
						</Typography>
					),
				},
			]}
			variant={variant}
		/>
	);
}
export default MessageBasicSms;
