import { toStateViews } from "app/main/utils/stateUtils";
const moment = require("moment");

export const filtersChanged = (localFilters, savedFilters) => {
	let filtersChanged = false;
	if (localFilters) {
		filtersChanged = Object.keys(localFilters).some((filter) => {
			if (!filter.includes("__view")) {
				if (localFilters[filter] === undefined && savedFilters?.[filter] === undefined) {
					return false;
				} else if (typeof localFilters[filter] === "boolean") {
					if (!!localFilters[filter] !== !!savedFilters?.[filter])
						return !!localFilters[filter] !== !!savedFilters?.[filter];
				} else if (typeof localFilters[filter] === "string" || typeof localFilters[filter] === "number") {
					if (localFilters[filter] !== savedFilters?.[filter]) return localFilters[filter] !== savedFilters?.[filter];
				} else if (Array.isArray(localFilters[filter])) {
					return JSON.stringify(localFilters[filter]) !== JSON.stringify(savedFilters?.[filter]);
				}
			} else {
				return localFilters[filter]?.value !== savedFilters?.[filter]?.value;
			}
			return false;
		});
	}
	return filtersChanged;
};

export const applyFilter = (filter, load) => {
	let show = true;

	if (filter.rate_per_mile_preferred && load.rpm < filter.rate_per_mile_preferred) show = false;
	if (filter.rate_total_preferred && load.price < filter.rate_total_preferred) show = false;
	if (
		filter.miles_preferred?.[0] &&
		filter.miles_preferred?.[0] !== DEFAULT_SOFT_FILTERS_VALUES.miles_preferred[0] &&
		load.miles + load.distance_to_origin + load.distance_to_dest < filter.miles_preferred[0]
	)
		show = false;
	if (
		filter.miles_preferred?.[1] &&
		filter.miles_preferred?.[1] !== DEFAULT_SOFT_FILTERS_VALUES.miles_preferred[1] &&
		load.miles + load.distance_to_origin + load.distance_to_dest > filter.miles_preferred[1]
	)
		show = false;
	if (
		filter.profit_preferred &&
		filter.profit_preferred !== DEFAULT_SOFT_FILTERS_VALUES.profit_preferred &&
		(load.profit_trip_carrier < filter.profit_preferred || isNaN(load.profit_trip_carrier))
	)
		show = false;
	if (filter.min_rank_mci && load.rank_mci * 100 < filter.min_rank_mci) show = false;
	if (
		filter.min_credit_score &&
		filter.min_credit_score !== DEFAULT_SOFT_FILTERS_VALUES.min_credit_score &&
		load.creditScore < filter.min_credit_score
	)
		show = false;
	if (
		filter.extra_stops_preferred !== DEFAULT_SOFT_FILTERS_VALUES.extra_stops_preferred &&
		load.stopsNumber > filter.extra_stops_preferred
	)
		show = false;
	if (filter.onlyBook && !load.booknow) show = false;
	if (filter.onlyNew && !load.new) show = false;
	if (filter.onlySmartpay && !load.smartpayElegible) show = false;
	if (filter.onlyBook && !load.bidnow && !load.booknow) show = false;
	if (filter.onlyTruckPlan && !load.match_plan) show = false;
	if (filter.onlyNoFlex && load.flexible) show = false;
	if (filter.onlyNoInter && load.outside) show = false;
	if (filter.onlyTeam && !load.team) show = false;
	if (filter.onlyWithRate && !load.price) show = false;
	if (filter.onlyHazmat && !load.hazmat) show = false;
	if (filter.onlyPreffered && !load.isOnBoarded) show = false;
	if (filter.hideCovered && load.deleted && moment().diff(moment(load.deletedAt), "minutes") >= 2) show = false;

	//Admin filters
	if (filter.onlyOwnerLoads?.length > 0 && !filter.onlyOwnerLoads.includes(load.owner)) show = false;
	if (filter.onlyCovered && load.deleted !== true) show = false;
	if (filter.onlyTest && load.test !== true) show = false;

	const age = moment().utc().diff(moment(load.updated).utc(), "minutes");
	if (
		filter.age_mins_max_preferred &&
		filter.age_mins_max_preferred < DEFAULT_SOFT_FILTERS_VALUES.age_mins_max_preferred &&
		age > filter.age_mins_max_preferred
	)
		show = false;

	if (filter.searchBroker) {
		const clientid = load.clientid.toLowerCase();
		const mcnumber = load.mcnumber.toLowerCase();
		const brokerToSearch = filter.searchBroker.toLowerCase();
		if (!clientid.includes(brokerToSearch) && !mcnumber.includes(brokerToSearch)) show = false;
	}

	return show;
};

export const truckToFilters = (truck) => {
	const truckEquipment = truck?.equipment ?? truck?.truck?.equipment ?? "";
	const truckSubtype = truck?.subtype ?? truck?.truck?.subtype ?? undefined;

	let hardFilters = {
		equipment: truckEquipment === "SPECIAL" ? "SPECIAL_" + truckSubtype : truckEquipment ?? "",
		subtype: truckSubtype,
		load_type: truck?.truck_trailer__view?.metadata?.load_type ?? truck?.truck?.load_type ?? "FULL",
		trailer: truck?.trailer ?? truck?.truck?.trailer ?? "",
		max_weight: truck?.max_weight ?? truck?.truck?.max_weight ?? "",
		driver: truck?.driver ?? undefined,
		driver__view: truck?.driver__view ?? undefined,
	};
	let softFilters = { onlyHazmat: truck?.truck_trailer__view?.metadata?.hazmat };

	return {
		hardFilters,
		softFilters,
	};
};

export const truckPlanFieldsToFilters = (plan, includeSoftFilters = false) => {
	let date = plan?.current_available_date
		? moment.utc(plan?.current_available_date).format("YYYY-MM-DD")
		: moment.utc().format("YYYY-MM-DD");
	let currentAvailableDate = moment(date).format("YYYY-MM-DD");
	currentAvailableDate = moment().diff(currentAvailableDate) > 0 ? moment() : currentAvailableDate;
	let hardFilters = {
		pickup_ds__after: currentAvailableDate.valueOf(), //truck.current_available_date,
		pickup_ds__before: moment.utc(currentAvailableDate).endOf("day").valueOf(),
	};

	if (plan.current_location) {
		hardFilters["location_origin"] = plan.current_location;
		hardFilters["location_origin__view"] = {
			value: plan.current_location,
			label: plan.current_location,
		};
	}

	if (plan.current_destination) {
		hardFilters["location_destination"] = plan.current_destination;
		hardFilters["location_destination__view"] = {
			value: plan.current_destination,
			label: plan.current_destination,
		};
		hardFilters["dh_destination"] = plan?.dh_destination_preferred;
	} else if (plan.destinationStates?.length > 0) {
		hardFilters["destinationStates"] = plan.destinationStates;
		hardFilters["destinationStates__view"] = toStateViews(plan.destinationStates);
	} else {
		hardFilters["destinationStates"] = null;
		hardFilters["destinationStates__view"] = null;
		hardFilters["location_destination"] = null;
		hardFilters["location_destination__view"] = null;
	}
	hardFilters["dh_origin"] = plan?.dh_origin_preferred;

	hardFilters["load_type"] = plan?.load_type ?? DEFAULT_HARD_FILTERS_VALUES.load_type;
	hardFilters["trailer"] = plan?.trailer;
	hardFilters["max_weight"] = plan?.max_weight;

	let softFilters = { ...DEFAULT_SOFT_FILTERS_VALUES };
	if (includeSoftFilters) {
		if (plan?.rate_per_mile_preferred) softFilters["rate_per_mile_preferred"] = plan?.rate_per_mile_preferred;
		if (plan?.miles_preferred__min && plan?.miles_preferred__max)
			softFilters["miles_preferred"] = [plan?.miles_preferred__min, plan?.miles_preferred__max];
		if (plan?.rate_total_preferred) softFilters["rate_total_preferred"] = plan?.rate_total_preferred;
		if (plan?.extra_stops_preferred) softFilters["extra_stops_preferred"] = plan?.extra_stops_preferred;
		if (plan?.profit_preferred) softFilters["profit_preferred"] = plan?.profit_preferred;
		if (plan?.min_rank_mci) softFilters["min_rank_mci"] = plan?.min_rank_mci;
		if (plan?.age_mins_max_preferred) softFilters["age_mins_max_preferred"] = plan?.age_mins_max_preferred;
		if (plan?.min_credit_score) softFilters["min_credit_score"] = plan?.min_credit_score;
	}
	return { hardFilters, softFilters };
};

export const driverPreferencesToFilters = (driver) => {
	return {
		blacklisted_states_ids: driver?.blacklisted_states_ids ?? [],
		blacklisted_states_ids__view: toStateViews(driver?.blacklisted_states_ids ?? []),
	};
};

export const DEFAULT_SOFT_FILTERS_VALUES = {
	rate_per_mile_preferred: 0,
	rate_total_preferred: 0,
	miles_preferred: [0, 4000],
	age_mins_max_preferred: 2 * 60, //2 hours
	profit_preferred: -1000,
	min_rank_mci: 0,
	min_credit_score: 0,
	extra_stops_preferred: 5,

	searchBroker: undefined,
	onlyPreffered: false,
	onlyNew: false,
	onlySmartpay: false,
	onlyBook: false,
	onlyTruckPlan: false,
	onlyNoFlex: false,
	onlyNoInter: true,
	onlyTeam: false,
	onlyWithRate: false,
	onlyHazmat: false,
	onlyOwnerLoads: [],
	onlyOwnerLoads__view: undefined,
	hideCovered: true,
};

export const DEFAULT_HARD_FILTERS_VALUES = {
	load_type: "FULL",
	dh_origin: 250,
	dh_destination: 250,
};
