const _BASE_ROW = { getVisibleCells: () => [], getToggleSelectedHandler: () => {}, getIsSelected: () => false };

export const RECOMMENDED_SEPARATOR = (vcEnabled) => {
	return [
		{
			..._BASE_ROW,
			id: "RECOMMENDED_SEPARATOR",
			original: {
				type: "separator",
				label: vcEnabled ? "Contract Matches" : "Recommended Loads",
				description: vcEnabled
					? "Loads that match your carrier's plan and destination market"
					: "SmartHop ranks these loads based on profitability and destination market optimization. We call these HopLoads!",
				icon: "BEST",
			},
		},
	];
};

export const RECOMMENDED_MESSAGE = (inProgress, loadsFound) => {
	if (!inProgress && loadsFound > 0) return [];
	return [
		{
			..._BASE_ROW,
			id: "RECOMMENDED_MESSAGE",
			original: {
				type: "separator",
				classes: { text: "italic text-11 ml:text-12" },
				showInProgress: inProgress,
				label: inProgress ? "Finding the best loads for you…" : loadsFound === 0 ? "No match found" : "",
			},
		},
	];
};

export const NEW_LOADS_SEPARATOR = () => {
	return [
		{
			..._BASE_ROW,
			id: "NEW_SEPARATOR",
			original: { type: "separator", label: "New Loads" },
		},
	];
};
