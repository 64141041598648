import moment from "moment";
import { Typography, Link } from "@material-ui/core";
import {
	DH_ORIGIN,
	DH_DESTINATION,
	MILES_PREFERRED,
	RATE_PER_MILE_PREFERRED,
	RATE_TOTAL_PREFERRED,
} from "app/main/search/config/searchFields";
import { openFormDialog, openLoadedFormDialog } from "app/store/tools/formDialogSlice";
import { formatDate } from "app/main/utils/dateUtils";
import { isEnabled } from "app/services/featureStorageService";
import { getUserTier, getCarrierId, getUserId } from "app/services/LoginService";
import {
	SPECIAL_EQ_SELECTOR,
	MAX_WEIGHT_FIELD,
	TRAILER_LENGTH_FIELD,
	LOAD_TYPE_SELECTOR,
} from "@smarthop/form/configs/trailerForm";

const CURRENT_LOCATION = {
	key: "current_location",
	type: "autocomplete",
	label: "Available Location",
	required: true,
	autocomplete: {
		provider: "trimblemaps",
		params: {
			componentRestrictions: { country: "us", includeOnly: "city" },
			types: ["(cities)"],
		},
		icon: "location",
	},
};

const CURRENT_DESTINATION = (data) => ({
	key: "current_destination",
	type: "autocomplete",
	label: "Desired Destination",
	autocomplete: {
		provider: "trimblemaps",
		params: {
			componentRestrictions: { country: "us", includeOnly: "city" },
			types: ["(cities)"],
		},
		icon: "location",
	},
	disabled: data?.destinationStates?.length > 0,
});

const DESTINATION_STATES = (data) => ({
	key: "destinationStates",
	type: "autocomplete",
	label: "Desired Destinations States",
	translate: "",
	field: {
		noErrorMessage: false,
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/consts/states",
		types: [],
		multiple: true,
		anyValue: false,
		preloadDefault: true,
		icon: "location",
		maxValues: 7,
	},
	disabled: !!data?.current_destination,
});

const AVAILABLE_DATE = {
	key: "current_available_date",
	type: "picker",
	label: "Available Date",
	required: true,
	field: {
		classes: {},
		variant: undefined,
	},
	input: {
		classes: {},
	},
	picker: {
		disablePast: true,
		notClearable: true,
		type: "datetime",
	},
};

const PROFIT_PREFERRED = (disabled = false) => {
	return {
		key: "profit_preferred",
		type: "slider",
		label: "Profit (Minimum)",
		defaultValue: -1000,
		disabled,
		slider: {
			builder: (value) => {
				if (value === -1000) {
					return { label: "Any" };
				} else if (value < 0) {
					return { label: "-$" + value * -1, classes: "text-red-600" };
				} else {
					return { label: "$" + value + "+", classes: "text-green-600" };
				}
			},
			inverted: true,
			type: "input",
			prefix: "$",
			step: 25,
			min: -1000,
			max: 1000,
			noErrorMessage: true,
			classes: {},
			variant: undefined,
		},
	};
};

const MIN_RANK_MCI = (disabled = false) => {
	return {
		disabled,
		key: "min_rank_mci",
		type: "slider",
		label: "MPI (Minimum)",
		defaultValue: 0,
		slider: {
			inverted: true,
			type: "input",
			prefix: "",
			postfix: "+",
			step: 5,
			min: 0,
			max: 100,
			noErrorMessage: true,
			classes: {},
			variant: undefined,
		},
	};
};

const SUBSECTION_LOADSCOUT_CRITERIA = {
	type: "section",
	label: "Advanced Filters",
	section: { classes: { label: "-mt-2 font-normal" } },
};

const RATE_PER_MILE_PREFERRED_MOD = (disabled = false) => {
	return {
		...RATE_PER_MILE_PREFERRED,
		disabled,
	};
};

const RATE_TOTAL_PREFERRED_MOD = (disabled = false) => {
	return {
		...RATE_TOTAL_PREFERRED,
		disabled,
	};
};

const MAX_MIN_MILES = (disabled = false, mergedData) => {
	return {
		...MILES_PREFERRED,
		disabled: disabled || mergedData?.current_destination,
	};
};

const ONLY_HOP_LOADS = (disabled = false) => {
	return {
		key: "system_show_best_loads_only",
		type: "checkbox",
		label: "Only HOP loads",
		disabled,
	};
};

const SUBSECTION_LOADSCOUT_NOTIFICATION = {
	type: "section",
	label: "Notification Preferences",
	section: { classes: { label: "-mt-2 font-normal" } },
};

const MIN_HOP_LOADS_COUNT = (disabled = false) => {
	return {
		key: "minHopLoadsCount",
		type: "slider",
		label: "Min HOP loads to notify me",
		disabled,
		slider: {
			type: "input",
			min: 0,
			max: 15,
			step: 1,
			noErrorMessage: true,
			classes: {},
			variant: undefined,
		},
	};
};

const MIN_MATCHING_PREF_COUNT = (disabled = false) => {
	return {
		key: "minMatchingPrefCount",
		type: "slider",
		label: "Min matching loads to notify me",
		disabled,
		defaultValue: 1,
		slider: {
			type: "input",
			min: 1,
			max: 15,
			step: 1,
			noErrorMessage: true,
			classes: {},
			variant: undefined,
		},
	};
};

const PAUSE_OUTSIDE_BUSINESS_HOURS = (disabled = false) => {
	return {
		key: "pauseOutsideBusinessHours",
		type: "checkbox",
		label: "Pause Notifications Outside Business Hours",
		disabled,
	};
};

const SUBSECTION_LOADSCOUT_AUTO_BOOK = {
	type: "section",
	label: "Dispatch Automation",
	section: { classes: { label: "-mt-2 font-normal" } },
};

const AUTO_BOOK_BANNER = {
	key: "auto_book_banner",
	type: "banner",
	label: "Authorize Load Scout to execute a real booking for your truck.",
	banner: {
		icon: "info",
		classes: { icon: "text-blue-600" },
	},
};

const maxAgeValue = 8 * 60;
const AGE_PREFERRED = (disabled) => {
	return {
		key: "age_mins_max_preferred",
		type: "slider",
		label: "Age (Maximum)",
		defaultValue: maxAgeValue,
		disabled,
		slider: {
			type: "input",
			postfix: " min",
			builder: (value) => {
				if (value === maxAgeValue) return { label: "8h+" };
				if (value < 10) return { label: value + "m" };
				let mins = parseInt(value % 60);
				let hourse = parseInt((value / 60) % 24);
				let days = parseInt(value / 60 / 24);
				let label =
					(days > 0 ? days + "d:" : "") +
					(hourse > 0 || days > 0 ? hourse + "h:" : "") +
					(mins < 10 ? "0" : "") +
					mins +
					"m";
				return { label };
			},
			min: 1,
			max: maxAgeValue,
			step: 1,
			scale: (x) => Math.exp(x),
			noErrorMessage: true,
			classes: {},
			variant: undefined,
		},
	};
};

const MIN_CREDIT_SCORE = (disabled) => {
	return {
		key: "min_credit_score",
		type: "slider",
		label: "Broker Credit Score (Minimum)",
		defaultValue: 0,
		slider: {
			inverted: true,
			type: "input",
			prefix: "",
			postfix: "+",
			step: 1,
			min: 0,
			max: 100,
			noErrorMessage: true,
			classes: {},
			variant: undefined,
			builder: (value) => {
				if (value === 0) {
					return { label: "Any" };
				} else if (value < 67) {
					return { label: value, value: value, classes: "text-red-600" };
				} else if (value < 87) {
					return { label: value, value: value, classes: "text-orange-600" };
				} else {
					return { label: value, value: value, classes: "text-green-600" };
				}
			},
		},
	};
};

const EXTRA_STOPS_PREFERRED = (disabled) => {
	return {
		key: "extra_stops_preferred",
		type: "slider",
		label: "Extra Stops (Maximum)",
		defaultValue: 5,
		slider: {
			inverted: false,
			type: "input",
			step: 1,
			min: 0,
			max: 5,
			noErrorMessage: true,
			classes: {},
			variant: undefined,
			builder: (value) => {
				if (value === 5) {
					return { label: "5+" };
				} else {
					return { label: value, value: value };
				}
			},
		},
	};
};

const DURATION_PREFERRED = (disabled) => {
	return {
		key: "duration_preferred",
		type: "slider",
		label: "Duration (Days)",
		defaultValue: 1,
		slider: {
			inverted: false,
			type: "input",
			step: 1,
			min: 1,
			max: 6,
			noErrorMessage: true,
			classes: {},
			variant: undefined,
			builder: (value) => {
				if (value === 6) {
					return { label: "5+" };
				} else {
					return { label: value, value: value };
				}
			},
		},
	};
};

const AUTO_BOOK_TRIGGER = (disabled = false) => {
	return {
		key: "triggerAutobook",
		type: "checkbox",
		label: "Book Now Automatically",
		disabled,
	};
};

const AUTO_CALL_TRIGGER = (disabled = false) => {
	return {
		key: "triggerCallNow",
		type: "checkbox",
		label: "Call Now Automatically",
		disabled,
	};
};

const AUTO_TRIP_CREATE = (disabled = false) => {
	return {
		key: "triggerTripCreate",
		type: "checkbox",
		label: "Create Trip Automatically",
		disabled,
	};
};

const DH_ORIGIN_PREFERRED = (disabled = false) => {
	return {
		...DH_ORIGIN,
		key: "dh_origin_preferred",
		label: "DH-Available Location",
		disabled,
	};
};

const DH_DESTINATION_PREFERRED = (disabled = false, mergedData) => {
	const config = DH_DESTINATION;
	config.rules = {};
	return {
		...config,
		key: "dh_destination_preferred",
		label: "DH-Destination",
		disabled: disabled || !mergedData?.current_destination,
	};
};

const PLAN_NOTES = { key: "notes", type: "text", label: "Add Notes", multiline: { rows: 4 } };

const POST_DATE = (disabled = false) => {
	return {
		key: "post_date",
		type: "picker",
		label: "Date to post",
		defaultValue: "NOW",
		picker: {
			disablePast: true,
			notClearable: true,
			type: "datetime",
			minutesStep: 30,
		},
		disabled,
	};
};

const POST_BROKERS = (mergedData, carrierId, equipment) => {
	return {
		key: "post_brokers",
		type: "autocomplete",
		label: "Select brokers",
		required: !!mergedData?.auto_post_externally,
		disabled: !mergedData?.auto_post_externally,
		autocomplete: {
			provider: "smarthop",
			types: [],
			anyValue: false,
			multiple: true,
			url: "api/post-truck/brokers",
			preloadDefault: true,
			listenEvent: "profileRevision",
			params: { carrierId: carrierId, equipment },
		},
	};
};

const BANNER_EQUIPMENT_NOT_SUPPORTED = {
	key: "disclamer",
	type: "banner",
	label: "Only DAT allows to post this equipment",
	banner: {
		icon: "info",
		classes: { icon: "text-blue-600" },
	},
};

const BANNER_GENERIC_CREDENTIALS = (brokers) => {
	return {
		key: "disclamer",
		type: "banner",
		label: `The credentials for ${brokers} are not available yet, in the next 48-72 hours you will be able to Post your truck there`,
		banner: {
			icon: "warning",
			classes: { icon: "text-red-600" },
		},
	};
};

const POST_COMMENTS = (mergedData) => {
	return {
		key: "post_comments",
		type: "text",
		label: "Public comments",
		multiline: { rows: 2 },
		field: { maxLength: 70 },
		disabled: !mergedData?.auto_post_externally,
		required: !!mergedData?.auto_post_externally,
	};
};

const BOOK_BY = {
	key: "bookBy",
	type: "picker",
	label: "Book By",
	required: true,
	picker: {
		type: "date",
		disablePast: true,
	},
};

const SMARTHOP_BOOK_FOR_ME = {
	key: "bookForMe",
	type: "checkbox",
	label: "I want Smarthop to help me cover this truck.",
};

const goToBillingPlan = (dispatch, ids) => {
	const callback = {
		restriction: (tier) => tier !== "TIER_STARTER",
		function: (dispatch) => dispatch(openLoadedFormDialog({ viewId: "TRUCK_PLAN", dataIds: ids })),
	};
	dispatch(
		openFormDialog({
			viewId: "BILLING_PLAN_CHANGE",
			dataIds: { carrierId: getCarrierId(), userId: getUserId(), callback: callback },
		})
	);
};
const ADVANCED_FILTERS_BANNER = (userTier, ids) => {
	return {
		key: "advance_search_banner",
		type: "banner",
		builder: (_i, _m, dispatch) => {
			return (
				<>
					Take advantage of SmartHop search, even when you’re busy with other work. Just click to enable Load Scout and
					select your search preferences. We’ll search for you in the background and notify you when we find
					matches.&nbsp;
					{userTier === "TIER_STARTER" && (
						<>
							<Link onClick={() => goToBillingPlan(dispatch, ids)}>Click Here</Link> to upgrade your plan and try load
							scout today!.
						</>
					)}
				</>
			);
		},
		banner: {
			icon: "info",
			classes: { icon: "text-blue-600" },
		},
	};
};

const SEARCH_PREF_BANNER = {
	key: "SEARCH_PREF_BANNER",
	type: "banner",
	label:
		"Search Preferences will be applied by default when searching for loads for this truck and will also be used in Load Scouting",
	banner: {
		icon: "info",
		classes: { icon: "text-blue-600" },
	},
};

const ENABLE_ADVANCED_FILTERS = (userTier) => {
	return {
		key: "advancedFilters",
		type: "checkbox",
		label: "Enable Load Scout",
		disabled: userTier === "TIER_STARTER",
	};
};

const CARRY_OVER = (disabled = false) => {
	return {
		key: "carryOverLoadscout",
		type: "checkbox",
		label: "Carry Over",
		description: "Copy search preferences to the next generated truck plan.",
		disabled,
	};
};

const ENABLE_AUTO_POST_EXTERNALLY = (disabled = false) => {
	return {
		key: "auto_post_externally",
		type: "checkbox",
		label: "Post Externally",
		disabled,
	};
};

const group = (items) => {
	return {
		type: "group",
		content: {
			items,
		},
	};
};

const toggleGroup = (name, items, defaultVisible, alwaysVisible = false, icon = false, summary) => {
	let toggle = { defaultVisible };
	if (icon) toggle.icon = icon;
	let config = {
		type: "toggle",
		key: name,
		toggle,
		label: name,
		alwaysVisible,
		content: {
			items,
		},
	};

	if (summary) {
		config.summary = summary;
	}

	return config;
};

const CARRIER_ALL = (isAdminRol) => ({
	key: "carrier",
	type: "autocomplete",
	label: "Carrier",
	required: true,
	field: {
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/carriers",
		preloadDefault: true,
		listenEvent: "profileRevision",
		params: { options_active_only: true, options_my_assigned: !isAdminRol },
	},
});

const TRUCK_ASSIGNED = ({ isAdminRol, isDispatcher, isCarrierRol = false, carrierId, onlyShowDrafts }) => ({
	key: "truck",
	type: "autocomplete",
	label: "Truck",
	required: true,
	field: {
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "smarthop",
		preloadDefault: true,
		listenEvent: "profileRevision",
		url: "api/profile/trucks",
		params: {
			options_metadata_truck: true,
			options_metadata_driver: true,
			options_active_only: true,
			label_format_show_driver: true,
			...(isDispatcher ? { options_my_assigned: true } : {}), // Admin can see all trucks
			...(isCarrierRol ? { options_carrier_id_search: carrierId } : {}),
			...(onlyShowDrafts ? { filters: { status_truck_plan: "NO_PUBLISHED" } } : {}),
		},
	},
	...(isAdminRol || isDispatcher
		? {
				rules: {
					params: {
						valueRef: "carrier",
						paramKey: "options_carrier_id_search",
					},
				},
		  }
		: {}),
});

const selectCarrierAndTruck = ({ isAdmin, isDispatcher, isCarrier, carrierId, onlyShowDrafts }) => {
	const isUserView = isCarrier; // Add here driver or other roles to see as user
	return {
		items: [
			...(!isUserView ? [CARRIER_ALL(isAdmin)] : []),
			TRUCK_ASSIGNED({
				isAdminRol: isAdmin,
				isDispatcher,
				isCarrierRol: isUserView,
				carrierId,
				onlyShowDrafts,
			}),
			{
				type: "action",
				action: "SUBMIT",
				label: "Continue",
			},
		],
	};
};

const truckPlanForm = ({ data, formData, historyMode, ids, mergedData }) => {
	const openSection = ids?.openSection ?? "availability";
	const createMode = !data?.plan?._id;
	const bookForMeEnabled = !!data?.carrier?.planTransferEnabled || !!data?.carrier?.manual_queue;
	const bookForMeValueEdit = bookForMeEnabled && mergedData?.bookForMe;
	const bookForMeValueAdd =
		bookForMeEnabled && (formData?.bookForMe ?? data?.truck?.verified ?? data?.carrier?.manual_queue);
	const bookForMe = bookForMeEnabled && createMode ? bookForMeValueAdd : bookForMeValueEdit;
	const brokersGenericCredentials = data?.brokersForPost?.reduce((brokers, broker, index) => {
		if (broker.metadata.isGeneric) {
			brokers += broker.label;
			if (index < data.brokersForPost.length) brokers += ", ";
		}
		return brokers;
	}, "");

	const submit = () => {
		return {
			type: "action",
			action: "SUBMIT",
			label: "Save",
			button: {
				alwaysEnabled: true,
			},
		};
	};

	const dispatchingSection = () => {
		if (!bookForMeEnabled) return [];

		return [
			toggleGroup(
				"Dispatching",
				[
					group([
						{
							...SMARTHOP_BOOK_FOR_ME,
							defaultValue: bookForMe,
						},
						{
							...BOOK_BY,
							defaultValue: mergedData?.current_available_date,
							disabled: !bookForMe,
							required: bookForMe,
						},
					]),
				],
				openSection === "dispatching",
				false,
				"support_agent",
				(data) => {
					return data?.bookForMe ? (
						<Typography className="inline-block p-2 text-green text-12" component="div">
							Enabled{" "}
							{data?.bookBy || data?.current_available_date
								? moment(data?.bookBy ?? data?.current_available_date).format("MMM D")
								: ""}
						</Typography>
					) : (
						<Typography className="inline-block p-2 text-red text-12" component="div">
							Disabled
						</Typography>
					);
				}
			),
		];
	};

	const getModifiedPreferences = () => {
		const modified = [];
		if (!mergedData) return [];
		if (mergedData.dh_origin_preferred && mergedData.dh_origin_preferred !== 250) modified.push("dh_origin_preferred");
		if (mergedData.dh_destination_preferred && mergedData.dh_destination_preferred !== 250)
			modified.push("dh_destination_preferred");
		if (
			mergedData.miles_preferred &&
			(mergedData.miles_preferred?.[0] !== 0 || mergedData.miles_preferred?.[1] !== 4000)
		)
			modified.push("miles_preferred");
		if (mergedData.rate_total_preferred && mergedData.rate_total_preferred !== 0) modified.push("rate_total_preferred");
		if (mergedData.rate_per_mile_preferred && mergedData.rate_per_mile_preferred !== 0)
			modified.push("rate_per_mile_preferred");
		if (mergedData.min_rank_mci) modified.push("min_rank_mci");
		if (mergedData.profit_preferred && mergedData.profit_preferred !== -1000) modified.push("profit_preferred");
		if (mergedData.age_mins_max_preferred && mergedData.age_mins_max_preferred !== 120)
			modified.push("age_mins_max_preferred");
		if (mergedData.min_credit_score && mergedData.min_credit_score !== 0) modified.push("min_credit_score");
		if (mergedData.extra_stops_preferred && mergedData.extra_stops_preferred !== 5)
			modified.push("extra_stops_preferred");

		return modified;
	};

	const searchPreferencesSection = () => {
		const modifiedFields = getModifiedPreferences();
		const enabledByDefault = modifiedFields.length > 0;

		return toggleGroup(
			"Search Preferences",
			[
				SEARCH_PREF_BANNER,
				CARRY_OVER(),
				group([
					DH_ORIGIN_PREFERRED(false),
					DH_DESTINATION_PREFERRED(false, mergedData),
					MAX_MIN_MILES(false, mergedData),
				]),
				group([RATE_TOTAL_PREFERRED_MOD(false), RATE_PER_MILE_PREFERRED_MOD(false)]),
				group([MIN_RANK_MCI(false), PROFIT_PREFERRED(false)]),
				group([AGE_PREFERRED(false), MIN_CREDIT_SCORE(false)]),
				group([DURATION_PREFERRED(false), EXTRA_STOPS_PREFERRED(false)]),
			],
			enabledByDefault || historyMode || openSection.includes("search"),
			false,
			"search",
			(data) => (
				<Typography
					className={"inline-block p-2 text-12 " + (modifiedFields.length ? " text-green " : " text-grey ")}
					component="div"
				>
					{modifiedFields.length ? `${modifiedFields.length} Preferences` : "None"}
				</Typography>
			)
		);
	};

	const loadScoutSection = () => {
		const userTier = getUserTier();
		const enabled = !!mergedData?.advancedFilters;
		const automationEnabled = isEnabled("MARKET_LOADS_ANALYSIS_ADMIN_TEST");
		const visible =
			(historyMode || openSection === "loadscout" || openSection.includes("search")) &&
			openSection !== "availability-search";

		return toggleGroup(
			"Load Scout",
			[
				ADVANCED_FILTERS_BANNER(userTier, ids),
				group([
					ENABLE_ADVANCED_FILTERS(userTier),
					// enabled && automationEnabled && userTier !== "TIER_STARTER"
					// 	? CARRY_OVER(!enabled || !automationEnabled)
					// 	: { type: "stub" },
				]),
				enabled && SUBSECTION_LOADSCOUT_CRITERIA,
				enabled && ONLY_HOP_LOADS(!enabled),
				enabled && SUBSECTION_LOADSCOUT_NOTIFICATION,
				enabled && PAUSE_OUTSIDE_BUSINESS_HOURS(!enabled),
				enabled && group([MIN_MATCHING_PREF_COUNT(!enabled), MIN_HOP_LOADS_COUNT(!enabled)]),
				enabled && automationEnabled && SUBSECTION_LOADSCOUT_AUTO_BOOK,
				enabled && automationEnabled && AUTO_BOOK_BANNER,
				enabled && automationEnabled && AUTO_BOOK_TRIGGER(!enabled || !automationEnabled),
				//enabled && automationEnabled && AUTO_TRIP_CREATE(!enabled || !automationEnabled), // TODO: Enable this when we have a way to trigger it
				//enabled && automationEnabled && AUTO_CALL_TRIGGER(!enabled || !automationEnabled), // TODO: Enable this when we have a way to trigger it
			],
			visible,
			false,
			"alarm_on",
			(data) => {
				return data?.advancedFilters ? (
					<Typography className="inline-block p-2 text-green text-12" component="div">
						Enabled {data?.triggerAutobook ? " - Auto Book" : ""}
					</Typography>
				) : (
					<Typography className="inline-block p-2 text-red text-12" component="div">
						Disabled
					</Typography>
				);
			}
		);
	};

	const postTruckSection = () => {
		if (historyMode) {
			return toggleGroup(
				"Post externally",
				[
					ENABLE_AUTO_POST_EXTERNALLY(),
					group([
						{
							key: "post_date",
							type: "picker",
							label: "Post Date",
						},
					]),
					...(data?.truck?.equipment === "SPECIAL"
						? [
								group([
									SPECIAL_EQ_SELECTOR(
										data.trailer?.subtype,
										!mergedData?.auto_post_externally,
										mergedData?.auto_post_externally
									),
								]),
						  ]
						: []),
					...(data?.truck?.equipment !== "PO"
						? [
								group([
									MAX_WEIGHT_FIELD(mergedData?.auto_post_externally, !mergedData?.auto_post_externally),
									TRAILER_LENGTH_FIELD(mergedData?.auto_post_externally, !mergedData?.auto_post_externally),
								]),
								group([LOAD_TYPE_SELECTOR(mergedData?.auto_post_externally, !mergedData?.auto_post_externally, true)]),
						  ]
						: []),
					group([
						{
							label: "Post Brokers",
							key: "post_brokers",
							type: "text",
							builder: (item) => item?.post_brokers?.map((b) => b.charAt(0).toUpperCase() + b.slice(1)).join(", "),
						},
					]),
					group([POST_COMMENTS(mergedData)]),
				],
				true,
				false,
				"wifi_tethering"
			);
		}

		return toggleGroup(
			"Post externally",
			[
				ENABLE_AUTO_POST_EXTERNALLY(mergedData?.disablePostTruck),
				group([POST_DATE(mergedData?.disablePostTruck)]),
				...(data?.truck?.equipment === "SPECIAL"
					? [
							group([
								SPECIAL_EQ_SELECTOR(
									data.trailer?.subtype,
									!mergedData?.auto_post_externally,
									mergedData?.auto_post_externally
								),
							]),
					  ]
					: []),
				...(data?.truck?.equipment !== "PO"
					? [
							group([
								MAX_WEIGHT_FIELD(mergedData?.auto_post_externally, !mergedData?.auto_post_externally),
								TRAILER_LENGTH_FIELD(mergedData?.auto_post_externally, !mergedData?.auto_post_externally),
							]),
							group([LOAD_TYPE_SELECTOR(mergedData?.auto_post_externally, !mergedData?.auto_post_externally, true)]),
					  ]
					: []),
				...(data?.truck?.equipment === "PO" || data?.truck?.equipment === "SPECIAL"
					? [BANNER_EQUIPMENT_NOT_SUPPORTED]
					: []),
				...(brokersGenericCredentials?.length > 0 ? [BANNER_GENERIC_CREDENTIALS(brokersGenericCredentials)] : []),
				group([{ ...POST_BROKERS(mergedData, data?.carrier?._id, data?.truck?.equipment) }]),
				group([POST_COMMENTS(mergedData)]),
			],
			openSection === "posttruck",
			false,
			"wifi_tethering",
			(data) => {
				return data?.auto_post_externally ? (
					<Typography className="inline-block p-2 text-green text-12 flex flex-row items-center" component="div">
						{mergedData?.post_date ? formatDate(mergedData?.post_date) : ""}
						<div className="flex flex-row space-x-4 ml-4">
							{data?.post_brokers?.map((b) => (
								<div className="p-2 text-black">{b.charAt(0).toUpperCase() + b.slice(1)}</div>
							))}
						</div>
					</Typography>
				) : (
					<Typography className="inline-block p-2 text-red text-12" component="div">
						{mergedData?.disablePostTruck ? `Not available for ${mergedData?.subtype}` : "Disabled"}
					</Typography>
				);
			}
		);
	};

	const availabilitySection = () => {
		return [
			toggleGroup(
				"Availability",
				[
					//AVAILABILITY,
					AVAILABLE_DATE,
					CURRENT_LOCATION,
					CURRENT_DESTINATION(mergedData),
					DESTINATION_STATES(mergedData),
					PLAN_NOTES,
				],
				openSection.includes("availability") || openSection === "search",
				false,
				"event",
				(data) => {
					if (data?.current_available_date && data?.current_location) {
						return (
							<Typography className="inline-block p-2 text-green text-12" component="div">
								{moment(data?.current_available_date).format("MMM D")} @ {data?.current_location}
							</Typography>
						);
					} else {
						return null;
					}
				}
			),
		];
	};

	return {
		form: {
			preventClose: {
				enable: true,
			},
		},
		items: [
			...availabilitySection(),
			loadScoutSection(),
			searchPreferencesSection(),
			postTruckSection(),
			...dispatchingSection(),
			submit(),
		],
	};
};

export { truckPlanForm, selectCarrierAndTruck };
