// Utils
import { billingConf } from "app/main/utils/billingUtils";
import { formatCurrency } from "app/main/utils/tableUtils";
import { formatDate } from "app/main/utils/dateUtils";
import moment from "moment";

const getRoutes = ({ isUser, enableQuickFilters }) => {
	let route = "billing/v3/payroll/carrier/:carrierId/:payrollStatus";
	if (enableQuickFilters) {
		route = "billing/v3/payroll/carrier/:carrierId";
	} else if (isUser) {
		route = "billing/v3/payroll/carrier/:carrierId/user/:userId/approved-reports";
	}
	return route;
};

const CARRIER = () => ({
	key: "carrier",
	type: "autocomplete",
	label: "Carrier",
	field: {
		noErrorMessage: true,
		classes: {},
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/carriers",
		preloadDefault: true,
		listenEvent: "profileRevision",
		params: {
			options_payroll_active: true,
			options_active_only: true,
			carrier_id: ":carrierId",
			filters: { payrollStatus: "ENABLED" },
		},
	},
});

const PAYEE = (showCarrier, selectedCarrierId) => {
	const rules = showCarrier
		? {
				params: {
					valueRef: "carrier",
					paramKey: "options_carrier_id_search",
				},
		  }
		: null;

	return {
		key: "payee",
		type: "autocomplete",
		label: "Payee",
		translate: "",
		field: {
			classes: {},
			variant: undefined,
		},
		autocomplete: {
			provider: "smarthop",
			preloadDefault: true,
			preloadImmedately: true,
			showDescription: true,
			listenEvent: "profileRevision",
			// TODO fix me, insecure endpoint, carrierId should be automatically
			// used from dataIds to fetch any relevant entities
			url: "api/profile/payees",
			params: {
				options_metadata_driver: true,
				label_format_show_driver: true,
				// TODO: incorrect approach to filter carriers
				options_carrier_id_search: selectedCarrierId,
			},
		},
		rules,
	};
};

const enterprisePayeesPayrollConf = ({ isDriver, isUser, payrollStatus, selectedCarrierId, showCarrier }) => ({
	urlGET: getRoutes({ isUser, enableQuickFilters: !payrollStatus }),
	idKey: "_id",
	listenEvent: ["tripsRevision", "profileRevision", "payrollRevision"],
	segmentKey: "ENTERPRISE_DRIVERS_PAYROLL",
	content: {
		table: {
			variant: "skinny",
			orderBy: ["payee__view.label", "priority", "closed_date", "approved_date", "payroll__view"],
		},
		pagination: {
			enabled: true,
			resetScroll: true,
		},
		order: {
			defaut: {
				key: "approved_date",
				dir: "desc",
			},
		},
		...(!payrollStatus
			? {
					defaults: {
						initial: {
							filters: {
								status: "open",
							},
						},
						items: [
							{
								label: "In Progress",
								filters: { status: "open" },
							},
							{
								label: "In Review",
								filters: { status: "closed" },
							},
							{
								label: "Approved",
								filters: { status: "approved" },
							},
						],
					},
			  }
			: {}),
		export: {
			fileName: `Payroll Table`,
		},
		filters: {
			search: true,
			searchDescription: "Search payrolls by ref #, load ID, broker name or payee",
			countVisible: 3,
			items: [
				...(showCarrier ? [CARRIER()] : []),
				...(!isUser ? [PAYEE(showCarrier, selectedCarrierId)] : []),
				...(!isUser
					? [
							{
								key: "payee_category",
								type: "select",
								label: "Payee Type",
								defaultValue: "__NOT_SELECTED__",
								translate: "",
								options: [
									{
										value: "COMPANY_DRIVER",
										label: "Company Driver",
									},
									{
										value: "OWNER_OPERATOR",
										label: "Owner Operator",
									},
									{
										value: "DISPATCHER",
										label: "Dispatcher",
									},
									{
										value: "INVESTOR",
										label: "Investor",
									},
									{
										value: "__NOT_SELECTED__",
										label: "All",
									},
								],
							},
							{
								key: "status",
								type: "select",
								label: "Status",
								defaultValue: "open",
								options: [
									{
										value: "open",
										label: "In Progress",
									},
									{
										value: "closed",
										label: "In Review",
									},
									{
										value: "approved",
										label: "Approved",
									},
									// {
									// 	value: "all",
									// 	label: "All",
									// },
								],
							},
					  ]
					: []),
				{
					key: "createdAt",
					type: "select",
					label: "Visible Window",
					defaultValue: "__NOT_SELECTED__",
					options: [
						{
							value: "__NOT_SELECTED__",
							label: "Last 30 Days",
						},
						{
							value: "__DATE_DAYS_GT__90",
							label: "Last 90 Days",
						},
						{
							value: "__DATE_DAYS_GT__365",
							label: "Last 1 Year",
						},
						{
							value: "__DATE_DAYS_GT__3650",
							label: "Last 10 Years",
						},
					],
				},
			],
		},
		click: {
			label: "Edit",
			grid: { variant: "menu" },
			dialogBuilder: (item) => ({
				viewId: "PAYROLL_VIEW",
				dataIds: {
					carrierId: item.carrier_data?._id,
					payeeType: item.payee_type ?? "DRIVER",
					// TODO: 3870: Refactor base only in user entity
					// payeeType: "USER",
					payrollId: item._id,
					...(item.payee_type === "DRIVER" ? { driverId: item.payee__view?.value } : {}),
					...(item.payee_type === "USER" ? { userId: item.payee__view?.value } : {}),
					// TODO: 3870: Refactor base only in user entity
					// userId: item.payee__view?.value,
					payrollStatus: item.status,
					isInvestor: item.payee__view?.metadata?.gatekeepers?.permission_investor_on,
					label: item.payee__view?.label,
				},
			}),
		},
		items: [
			...(!payrollStatus
				? [
						{
							key: "status",
							type: "text",
							label: "Status",
							builder: (item) => {
								return item.status === "open"
									? "In Progress"
									: item.status === "closed"
									? "In Review"
									: item.status === "approved"
									? "Approved"
									: item.status;
							},
							table: { noWrap: true, width: 100 },
						},
						{
							key: "payroll__view",
							type: "payroll__view",
							label: "Ref #",
							table: { width: 100 },
						},
						{
							type: "separator",
						},
				  ]
				: []),
			...(showCarrier
				? [
						{
							key: "carrier__view.label",
							builder: (item) => item.carrier__view,
							type: "carrier__view",
							label: "Carrier",
							viewConfig: {
								showMcNumber: true,
								showTestCarrier: true,
							},
							table: { width: 220 },
						},
				  ]
				: []),
			{
				key: "payee__view.label",
				type: "payee__view",
				label: "Payee",
				hidden: isUser,
				viewConfig: {
					showPayeeType: true,
					showStatus__top: true,
				},
				builder: (item) => ({
					...item.payee__view,
					metadata: {
						...item.payee__view?.metadata,
						payee_type: item.payee_type,
					},
				}),
				table: { width: 200 },
			},
			...(!isDriver
				? [
						{
							key: "trips__view",
							builder: (item) => item?.trips__view,
							type: "trips__view",
							label: "Trips",
							viewConfig: { showBroker: true },
						},
				  ]
				: []),
			{
				type: "separator",
			},
			// Conditional Closed Date Column
			...(["closed", "approved"].includes(payrollStatus) || !payrollStatus
				? [
						{
							key: "closed_date",
							label: "Closed",
							type: "string",
							export: {
								builder: (item) => (item?.closed_date ? moment.utc(item?.closed_date).format("MM/DD/YYYY") : null),
							},
							table: { width: 150 },
						},
				  ]
				: []),
			// Conditional Approved Date Column
			...("approved" === payrollStatus || !payrollStatus
				? [
						{
							key: "approved_date",
							label: "Approved",
							type: "string",
							export: {
								builder: (item) => (item?.approved_date ? moment.utc(item?.approved_date).format("MM/DD/YYYY") : null),
							},
							table: { width: 150 },
						},
				  ]
				: []),
			{
				type: "separator",
			},
			{
				key: "sub_total",
				type: "component",
				table: {
					align: "right",
					width: 120,
				},
				label: ["Total", "Trip Pay"],
				builder: (item) => {
					if (item.isMinPaymentNotReached && item.status === "closed") {
						const msg = "Minimum payment not reached for driver pay.";
						return billingConf(true, "no_minimum_payment", msg, item?.tripsSubtotal);
					}
					return formatCurrency(item?.tripsSubtotal);
				},
				export: {
					builder: (item) => formatCurrency(item?.tripsSubtotal),
				},
			},
			{
				key: "payroll_additions",
				type: "number",
				table: {
					align: "right",
					width: 120,
				},
				label: ["Total", "Additions"],
				builder: (item) => formatCurrency(item?.totalAdditions),
				export: {
					builder: (item) => formatCurrency(item?.totalAdditions),
				},
			},
			{
				key: "payroll_deductions",
				type: "number",
				table: {
					align: "right",
					width: 120,
				},
				label: ["Total", "Deductions"],
				builder: (item) => formatCurrency(item?.totalDeductions),
				export: {
					builder: (item) => formatCurrency(item?.totalDeductions),
				},
			},
			{
				type: "separator",
				table: {
					width: 8,
					sticky: true,
				},
			},
			{
				key: "grand_total",
				type: "currency",
				table: {
					align: "right",
					width: 100,
					sticky: true,
				},
				label: ["Total", "Payroll"],
				viewConfig: {
					colorPositive: "font-medium text-black",
					colorNegative: "text-orange-800",
					colorZero: "text-grey",
				},
				builder: (item) => item?.grandTotal,
				export: {
					builder: (item) => formatCurrency(item?.grandTotal),
				},
			},
		],
	},
});

export { enterprisePayeesPayrollConf };
