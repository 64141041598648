import { authRoles } from "app/main/consts";
import moment from "moment";

import {
	LOCATION_ORIGIN,
	LOCATION_DESTINATION,
	CARRIER_ALL,
	TRUCK_ALL,
	CARRIER_ASSIGNED,
	TRUCK_ASSIGNED,
	CARRIER_TRUCKS,
	DRIVER_TRUCKS,
} from "../../search/config/searchFields";

const { actionOptions, ...autocomplete } = CARRIER_TRUCKS().autocomplete;
const CARRIER_TRUCKS_FILTERED = { ...CARRIER_TRUCKS(), autocomplete };

const createPanel = (classes, options) => {
	const entryPoint = options?.entryPoint;
	const role = options?.role;
	const topLevel = entryPoint === "topLevel";

	const isAdmin = authRoles.internalPowerUsers.includes(role);
	const isDispatch = authRoles.dispatcher.includes(role);
	const isCarrier = authRoles.externalUsers.includes(role);
	const isManager = authRoles.carrierManager.includes(role);
	const isInvestor = authRoles.carrierGeneric.includes(role);
	const isExtDispatcher = authRoles.carrierDispatcher.includes(role);
	const isDriver = authRoles.driver.includes(role);
	const isInternal = authRoles.internalUsers.includes(role);
	const isMainHandler = options.hasSubaccounts && (isCarrier || isManager || isExtDispatcher);

	let carrierSelector = isAdmin
		? CARRIER_ALL(true)
		: isDispatch || isMainHandler
		? CARRIER_ASSIGNED(isDispatch, true)
		: null;
	let truckSelector = isAdmin
		? TRUCK_ALL(true)
		: isDispatch || isMainHandler
		? TRUCK_ASSIGNED(isDispatch, true, isMainHandler)
		: isCarrier || isManager || isInvestor || isExtDispatcher
		? CARRIER_TRUCKS(isExtDispatcher, isInvestor, true)
		: isDriver
		? DRIVER_TRUCKS
		: null;

	return {
		form: {
			noErrorMessage: true,
			classes: {},
		},
		items: [
			{
				type: "group",
				group: {
					classes: {
						root: "flex-col md:flex-row",
						direction: undefined,
					},
				},
				content: {
					items: [
						...(topLevel ? [carrierSelector, truckSelector] : []),
						LOCATION_ORIGIN,
						{
							key: "start_date",
							type: "picker",
							label: "Start date",
							translate: "",
							field: {
								noErrorMessage: true,
								classes: {},
								variant: undefined,
							},
							picker: {
								disablePast: true,
								type: "date",
								classes: {},
								maxDate: moment().add(7, "d"),
							},
						},
						{ ...LOCATION_DESTINATION, disabled: !!options?.cycle },
						{
							key: "max_end_date",
							type: "picker",
							label: "Max. Deliver date",
							translate: "",
							field: {
								noErrorMessage: true,
								classes: {},
								variant: undefined,
							},
							picker: {
								disablePast: true,
								type: "date",
								classes: {},
								maxDate: moment().add(13, "d"),
							},
						},
						{
							type: "menu",
							icon: "tune",
							button: { color: "primary", classes: {} },
							content: {
								items: [
									{
										key: "max_duration",
										type: "slider",
										label: "Max Duration",
										translate: "",
										defaultValue: 8,
										slider: {
											type: "input",
											prefix: "",
											postfix: " days",
											step: 1,
											min: 1,
											max: 11,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},
									{
										key: "blacklisted_states",
										type: "autocomplete",
										label: "Blacklisted States",
										translate: "",
										field: {
											noErrorMessage: false,
											classes: {},
											variant: undefined,
										},
										autocomplete: {
											provider: "smarthop",
											url: "api/consts/states",
											types: [],
											multiple: true,
											anyValue: false,
											preloadDefault: true,
											icon: "location",
											maxValues: 10,
										},
									},
									{
										key: "path_length",
										type: "slider",
										label: "Max Legs",
										translate: "",
										defaultValue: 4,
										slider: {
											type: "input",
											prefix: "",
											postfix: " legs",
											step: 1,
											min: 1,
											max: 5,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},
									{
										key: "miles_range",
										type: "slider",
										label: "Miles Range",
										translate: "",
										defaultValueMin: 2400,
										defaultValueMax: 3200,
										slider: {
											type: "range",
											postfix: " mi",
											min: 600,
											max: 4000,
											step: 300,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},
									{
										key: "min_volume",
										type: "slider",
										label: "Min Volume",
										translate: "",
										defaultValue: 20,
										slider: {
											type: "input",
											prefix: "",
											postfix: " loads/day",
											step: 1,
											min: 3,
											max: 50,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},
									{
										type: "group",
										group: {
											classes: {
												root: "flex-col md:flex-row",
												direction: undefined,
											},
										},
										content: {
											items: [
												{
													key: "n_markets",
													type: "slider",
													label: "Top Destination Markets",
													translate: "",
													defaultValue: 15,
													slider: {
														type: "input",
														step: 1,
														prefix: "",
														postfix: "",
														min: 1,
														max: 70,
														noErrorMessage: true,
														classes: {},
														variant: undefined,
													},
												},
												/*{
													key: "end_market_profit",
													type: "slider",
													label: "Ending Market Profit",
													translate: "",
													defaultValue: null,
													rules: {
														disabled: {
															ifNotEmptyRef: "n_markets",
														},
													},
													slider: {
														type: "input",
														step: 100,
														prefix: "$",
														postfix: "",
														min: -2000,
														max: 2000,
														noErrorMessage: true,
														classes: {},
														variant: undefined,
													},
												},*/
											],
										},
									},
									{
										key: "max_paths",
										type: "slider",
										label: "Max Paths",
										translate: "",
										defaultValue: 25,
										slider: {
											type: "input",
											prefix: "",
											postfix: "",
											step: 5,
											min: 5,
											max: 100,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},

									{
										key: "allowed_states",
										type: "autocomplete",
										label: "Allowed States",
										translate: "",
										field: {
											noErrorMessage: false,
											classes: {},
											variant: undefined,
										},
										autocomplete: {
											provider: "smarthop",
											url: "api/consts/states",
											types: [],
											multiple: true,
											anyValue: false,
											preloadDefault: true,
											icon: "location",
											maxValues: 10,
										},
									},
									/*{
										key: "min_profit_per_leg",
										type: "slider",
										label: "Min Profit per Leg",
										translate: "",
										defaultValue: -500,
										slider: {
											type: "input",
											step: 50,
											prefix: "$ ",
											postfix: "",
											min: -2000,
											max: 200,
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
									},
									{
										key: "min_profit",
										type: "number",
										label: "Target Profit",
										translate: "",
										defaultValue: -10000,
									},*/
									{
										key: "sort_by",
										type: "select",
										label: "Sort by",
										defaultValue: "total_rpm",
										field: {
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
										options: [
											{
												value: "balanced_profit",
												label: "Balanced Profit",
												selected: true,
												translate: "balanced_profit",
											},
											{
												value: "total_profit",
												label: "Total profit",
												translate: "total_profit",
											},
											{
												value: "total_risk",
												label: "Total Risk",
												translate: "total_risk",
											},
											{
												value: "total_payment",
												label: "Total Payment",
												translate: "total_payment",
											},
											{
												value: "total_rpm",
												label: "Total RPM",
												translate: "total_rpm",
											},
											{
												value: "total_miles",
												label: "Total Miles",
												translate: "total_miles",
											},
										],
									},
									{
										key: "analysis_method",
										type: "select",
										label: "Analysis",
										defaultValue: "standard",
										field: {
											noErrorMessage: true,
											classes: {},
											variant: undefined,
										},
										options: [
											{
												value: "standard",
												label: "Standard",
												selected: true,
												translate: "standard",
											},
											{
												value: "volume",
												label: "Volume",
												translate: "volume",
											},
											{
												value: "probability",
												label: "Probability",
												translate: "probability",
											},
										],
									},
								],
							},
						},
						{
							type: "action",
							action: "SUBMIT",
							label: "Generate",
							icon: undefined,
							button: {
								alwaysEnabled: true,
								classes: {
									root: classes.buttonNormal,
									disabled: classes.buttonDisabled,
								},
							},
						},
					],
				},
			},
			{
				type: "action",
				button: {
					classes: {
						root: "hidden",
					},
				},
				confirmation: {
					enable: !!options?.cycle,
					message:
						"You have selected the cycle option, which means your destination is the same as your origin. Do you want to continue?",
				},
			},
		],
	};
};

export default createPanel;
