import moment from "moment-timezone";
import { sort } from "fast-sort";

import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import TooltipClickAwayView from "../tools/TooltipClickAwayView";

// Utils
import { formatDate } from "app/main/utils/dateUtils";
import { getAddressCity } from "app/main/utils/tripsUtils";
import { Icon, Tooltip, CircularProgress } from "@material-ui/core";
import { createMPIViewManual } from "app/main/utils/brokerUtils";

/**
 * Formats a numeric value as currency
 * (for direct use in SmarthopList! Use cellType as "currency" instead)
 *
 * @param {*} value The numeric value to format as currency
 * @param {*} locale The locale to get the format from. Defaults to "en-US"
 * @param {*} decimalPlaces How many decimals to fix. Defaults to 2
 * @returns formatted string
 */
const formatCurrency = (value, decimalPlaces = 2, locale = "en-US", showPlus = false, noShowDollar = false) => {
	return !isNaN(value) && value !== null
		? `${value < 0 ? "-" : showPlus ? "+" : ""}${noShowDollar ? "" : "$"}${new Intl.NumberFormat(locale, {
				minimumFractionDigits: decimalPlaces,
		  }).format(Number.parseFloat(Math.abs(value)).toFixed(decimalPlaces))}`
		: value ?? "-";
};

/**
 * Formats a numeric value
 * @returns formatted string
 */
const formatNumber = (value, decimalPlaces = 2, prefix = "", postfix = "", locale = "en-US") => {
	return !isNaN(value) && value !== null
		? `${prefix}${value < 0 ? "-" : ""}${new Intl.NumberFormat(locale, {
				minimumFractionDigits: decimalPlaces,
		  }).format(Number.parseFloat(Math.abs(value)).toFixed(decimalPlaces))}${postfix}`
		: "-";
};

const formatTruck = (truckId, equipment) => {
	return equipment ? `${capitalizeFirstLetter(equipment)} #${truckId}` : `#${truckId}`;
};

/**
 * capitalize only the first letter of the string.
 * @param {*} string
 * @returns
 */
const capitalizeFirstLetter = (string) => {
	return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
};

/**
 * Transform camel case text to sentence case
 * @param {*} string
 * @returns string
 */
const camelCaseToSentenceCase = (text) => {
	const result = text.replace(/([A-Z])/g, " $1");
	return result?.charAt(0)?.toUpperCase() + result?.slice(1);
};

/**
 * Transform snake case text to sentence case
 * @param {*} string
 * @returns string
 */
const snakeCaseToSentenceCase = (text) => {
	const result = text.replace(/_/g, " ");
	return result?.charAt(0)?.toUpperCase() + result?.slice(1);
};

/**
 * Crop string handler
 * @param {String} name company, truck, driver name
 * @returns {String} crop name
 */
const formatName = (name) => {
	let parts = name?.split(" ");
	if (!parts || parts?.length < 2) {
		return name;
	}
	parts[0] = parts[0].toUpperCase().substring(0, 1) + ".";
	return parts.join(" ");
};

/**
 * Join string handler
 * @param {String} firstName dispatcher first name
 * @param {String} lastName dispatcher last name
 * @returns {String} join name
 */
const formatDispatcherName = (firstName, lastName) => {
	firstName = firstName ? firstName + " " : "";
	lastName = lastName ?? "";
	return firstName + lastName;
};

const createTooltip = (content, title, color, marginWrapper = false) => {
	if (!title) return content;

	const toolTip = (
		<TooltipClickAwayView key={title} classes={{ tooltip: "drop-shadow-md " + (color ?? "") }} title={title}>
			{content}
		</TooltipClickAwayView>
	);
	return marginWrapper ? <div className={marginWrapper}>{toolTip}</div> : toolTip;
};

const TooltipContainer = ({ description, children }) => {
	if (description) {
		return (
			<Tooltip classes={{ tooltip: "text-12 font-normal" }} title={description}>
				{children}
			</Tooltip>
		);
	} else {
		return children;
	}
};

const BadgeTooltip = ({ title, description, colorClass, spinner = false }) => {
	return (
		<TooltipContainer description={description}>
			<div className={`flex rounded-full my-3 mx-4 items-center justify-center h-28 ${colorClass}`}>
				<div className="flex flex-row items-center justify-center px-12">
					{description && <Icon className="text-white mr-4 -ml-2 text-16">{"info"}</Icon>}
					<Typography className="text-12 text-white whitespace-nowrap">{title}</Typography>
					{spinner && <CircularProgress size={12} style={{ color: "#FFF" }} className="ml-4" />}
				</div>
			</div>
		</TooltipContainer>
	);
};

const labelComponent = ({
	bgColorLabel = "bg-orange-400",
	texColorLabel = "text-white",
	labelText,
	messageTooltip,
	marginWrapper = false,
}) => {
	return createTooltip(
		<Typography className={`px-8 py-2 rounded-md ${texColorLabel} text-12 ${bgColorLabel}`}>{labelText}</Typography>,
		<Typography color="inherit" variant="body2" className="p-4 font-light">
			{messageTooltip}
		</Typography>,
		null,
		marginWrapper
	);
};

/**
 *
 * @param {*} item A table item object with address related fields
 * @param {*} type "location", "pickup" or "delivery"
 * @returns
 */
const formatAddress = (item, type) => {
	let address = [];
	if (item[type + "_address"]) address.push(item[type + "_address"]);
	if (item[type + "_name"]) address.push(item[type + "_name"]);
	if (item[type + "_city"]) address.push(item[type + "_city"]);
	if (item[type + "_state"]) address.push(item[type + "_state"]);
	if (item[type + "_zip"]) address.push(item[type + "_zip"]);
	let title = address.join(", ");
	return title;
};

/**
 * URL carrier handler
 * @param {*} carrierId carrier ID
 * @returns {String} URL
 */
const carrierRedirectURL = (carrierId) => {
	if (carrierId) {
		let url = `/profile/carriers/${carrierId}/company/`;
		return url;
	}
	return "#";
};

/**
 * @description return the timezone of a UTC date
 * @param {Date} date - UTC Date
 * @param {String} region - Timezone Region
 * @returns {String} - Timezone
 */
const getTimeZone = (date, region) => {
	if (!date || !region) return "-";
	return `(UTC${moment(date).tz(region).format("Z")})`;
};

/**
 * @description reason column formatter (reason in error)
 * @param {String} reason
 * @returns {String} format value
 */
const formatPaymentMethodErrorReason = (reason) => {
	return reason === "ITEM_LOGIN_REQUIRED" ? "This bank account requires the user to re-authenticate." : reason;
};

/**
 * @description
 * @param {String} value
 * @param {String} href
 * @returns {Component}
 */
const redirectUrl = (value, href) => {
	if (!value) return;
	if (!href) return value;
	return (
		<div className="w-full">
			<Link href={href} rel="noopener noreferrer" target="_blank">
				{value}
			</Link>
		</div>
	);
};

const formatPriceRange = (min_price, max_price, decimalPlaces = 2) => {
	if (!min_price || !max_price) return "N/A";
	return formatCurrency(min_price, decimalPlaces) + " - " + formatCurrency(max_price, decimalPlaces);
};

const formatPercentage = (total, amount) => {
	if (total < amount) return "-";
	return ((amount / total) * 100).toFixed(0) + "%";
};

const renderAddressWithDate = (address, date, marketIndexData = null) => {
	let marketView =
		marketIndexData?.mci && marketIndexData?.mvi
			? createMPIViewManual(marketIndexData.mci, marketIndexData.mvi, true, true)
			: null;
	return (
		<div className="flex flex-col">
			<Typography className="break-words whitespace-normal tracking-wide min-w-100 text-12 ml:text-13">
				{date ? formatDate(date) : "-"}
			</Typography>
			<div className="flex flex-row items-center">
				{marketView}
				<Typography className="break-words whitespace-normal tracking-wide text-grey-600 text-11 ml:text-12 -mt-2">
					{getAddressCity(address)}
				</Typography>
			</div>
		</div>
	);
};

/**
 * @description returns an array sorted depending of the key and direction
 * @param {Array} array
 * @param {String} key
 * @param {String} dir
 * @returns {Array}
 */
const sortArray = (array, key, dir) => {
	if (dir === "asc") return sort(array).asc((u) => u?.[key]?.originalPrimaryValue);
	return sort(array).desc((u) => u?.[key]?.originalPrimaryValue);
};

const setSwitchCache = (key, value) => {
	const smarthopList = localStorage.getItem("smarthopList");

	let smarthopListJson = {};
	if (smarthopList && smarthopList !== "undefined") {
		smarthopListJson = JSON.parse(smarthopList);
	}

	smarthopListJson[key] = value;
	localStorage.setItem("smarthopList", JSON.stringify(smarthopListJson));
};

const getSwitchCache = (key, defaultValue) => {
	const smarthopList = localStorage.getItem("smarthopList");
	if (!smarthopList || smarthopList === "undefined") return defaultValue;

	const smarthopListJson = JSON.parse(smarthopList);
	if (!smarthopListJson[key]) return defaultValue;

	return smarthopListJson[key] ?? defaultValue;
};

export {
	labelComponent,
	createTooltip,
	formatCurrency,
	formatNumber,
	formatName,
	formatDispatcherName,
	formatAddress,
	formatTruck,
	carrierRedirectURL,
	formatPaymentMethodErrorReason,
	redirectUrl,
	getTimeZone,
	formatPriceRange,
	capitalizeFirstLetter,
	formatPercentage,
	camelCaseToSentenceCase,
	snakeCaseToSentenceCase,
	renderAddressWithDate,
	BadgeTooltip,
	sortArray,
	setSwitchCache,
	getSwitchCache,
	TooltipContainer,
};
