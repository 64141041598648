import { Typography, Icon, Tooltip } from "@material-ui/core";

function TypeComponent(props) {
	const item = props.item;
	const smallScreen = props.smallScreen;

	// Badge List
	const bookBadge = item.booknow || item.bidnow;
	const testBadge = item.test;
	const hasContact = item.contacted;
	const isCallEnded = item.contacted_end && item.contacted_end > item.contacted;
	const isVerified = item.verified;
	return (
		<div className="flex items-center">
			{bookBadge ? (
				<div className={"flex flex-row h-18 rounded-4 border-1 border-purple"}>
					<div className={`min-w-16 h-16 justify-center` + (smallScreen ? " bg-transparent " : " bg-purple-800 ")}>
						<Icon className={`font-500 text-12 p-2 ` + (smallScreen ? " text-purple-800 " : " text-white ")}>
							flash_on
						</Icon>
					</div>
					{!smallScreen && (
						<Typography className={"font-500 px-2 text-purple-800 text-12 ml:text-11"}>{"Book"}</Typography>
					)}
				</div>
			) : null}
			{testBadge ? (
				<div className={"flex flex-row h-18 rounded-4 border-1 border-blue ml-2"}>
					<div
						className={`min-w-16 h-16 justify-center` + (smallScreen || bookBadge ? " bg-transparent " : " bg-blue ")}
					>
						<Icon className={`font-500 text-13 p-1.5 ` + (smallScreen || bookBadge ? " text-blue " : " text-white ")}>
							star
						</Icon>
					</div>
					{!(smallScreen || bookBadge) && (
						<Typography className={"font-500 px-2 pt-1 text-blue text-12 ml:text-10"}>{"Test"}</Typography>
					)}
				</div>
			) : null}

			{isVerified && (
				<div className={`flex items-center ml-2`}>
					<Tooltip title={`Verified by: ${item.verifiedBy || "Unknown"}`}>
						<Icon className={`text-16 text-green-400`}>verified</Icon>
					</Tooltip>
				</div>
			)}
			{hasContact && (
				<div className={`flex items-center ml-2`}>
					<Tooltip
						title={
							item.contacted_end
								? `Ended: ${new Date(item.contacted_end).toLocaleString()}`
								: `Started: ${new Date(item.contacted).toLocaleString()}`
						}
						placement="top"
					>
						<Icon className={`text-16 ${isCallEnded ? "text-orange-800" : "text-red-400"}`}>
							{isCallEnded ? "phone_disabled" : "phone_in_talk"}
						</Icon>
					</Tooltip>
				</div>
			)}
		</div>
	);
}

export default TypeComponent;
