import moment from "moment";
import { Icon, Typography, Tooltip } from "@material-ui/core";
import Image from "material-ui-image";

import { BadgeTooltip, createTooltip } from "app/main/utils/tableUtils";

import { isBookingEnabled } from "app/services/LoginService";

import { isEnabled } from "app/services/featureStorageService";

const getFlexibleReasonsText = (reasons, quotes) => {
	if (reasons?.forEach) {
		let text = "";
		reasons?.forEach((reason, index) => {
			if (text.length > 0) text += index === reasons.length - 1 ? " and " : ", ";
			text += (quotes ?? "") + getFlexibleReasonName(reason) + (quotes ?? "");
		});
		return text;
	} else {
		return getFlexibleReasonName(reasons);
	}
};

const getFlexibleReasonName = (reason) => {
	if (reason === "ONDEMAND") {
		return "On-Demand Status";
	} else if (reason === "EQUIPMENT") {
		return "Equipment Type";
	} else if (reason === "DH_ORIGIN") {
		return "DH-Origin";
	} else if (reason === "DH_DEST") {
		return "DH-Destination";
	} else if (reason === "PICKUP_DS") {
		return "Pickup Time";
	} else if (reason === "DELIVERY_DS") {
		return "Delivery Time";
	} else if (reason === "WEIGHT") {
		return "Max Weight";
	} else if (reason === "LOW_RPM") {
		return "RPM";
	} else if (reason === "LOW_RATE") {
		return "Rate";
	} else if (reason === "MILES") {
		return "Trip Milage";
	} else if (reason === "LOAD_AGE") {
		return "Load Age";
	} else if (reason === "ANOTHER_DEST") {
		return "Destination";
	} else if (reason === "STATES_BLACKLISTED") {
		return "Blacklisted State";
	} else if (reason === "BROKER_BLACKLISTED") {
		return "Blacklisted Broker";
	} else if (reason === "BROKER_NOT_ONBOARDED") {
		return "Not a Preferred Broker";
	} else if (reason === "BROKER_POSSIBLE_SCAMMER") {
		return "Scammer Broker";
	} else if (reason === "RANK_MCI") {
		return "MPI";
	} else if (reason === "TRAILER_SIZE") {
		return "Trailer Size";
	} else if (reason === "CREDIT_SCORE") {
		return "Credit Score";
	} else if (reason === "EXTRA_STOP") {
		return "Extra Stops";
	} else if (reason === "FACTORABLE") {
		return "Factorable";
	} else {
		return reason;
	}
};

const getFormatedDate = (from, to) => {
	if (!from) {
		return "";
	}

	let fromDate = moment(from).format("MMM DD");
	let fromTime = moment(from).format("h:mm a");
	if (!to || to === "undefined") {
		return fromDate + ", " + fromTime;
	} else {
		let toDate = moment(to).format("MMM DD");
		let toTime = moment(to).format("h:mm a");
		if (fromDate !== toDate) {
			return fromDate + ", " + fromTime + " - " + toDate + ", " + toTime;
		} else if (fromTime !== toTime) {
			return fromDate + ", " + fromTime + " - " + toTime;
		} else {
			return fromDate + ", " + fromTime;
		}
	}
};

const createGenericErrorsFrom = (data, internal) => {
	let errors = data?.errors;
	errors = errors?.map
		? errors
		: [
				{
					type: "generic",
					message: `Something went wrong, please try again... (${internal ?? "Internal error"}: ${
						data?.message ?? "<empty>"
					})`,
				},
		  ];
	return errors;
};

const LOAD_EQUIPMENTS = {
	CN: "Conestoga",
	IR: "Insulated Van or Reefer",
	FLATBED: "Flatbed",
	FR: "Flatbed, Van or Reefer",
	FD: "Flatbed or Step Deck",
	FA: "Flatbed, Air-Ride",
	BT: "Flatbed, B-Train",
	FN: "Flatbed, Conestoga",
	F2: "Flatbed, Double",
	FZ: "Flatbed, HazMat",
	FH: "Flatbed, Hotshot",
	MX: "Flatbed, Maxi",
	FO: "Flatbed, Overdimension",
	RGN: "Flatbed, Removable Gooseneck",
	FC: "Flatbed w/Chains",
	FS: "Flatbed w/Sides",
	FT: "Flatbed w/Tarps",
	FM: "Flatbed w/Team",
	REEFER: "Reefer",
	RV: "Reefer or Vented Van",
	RA: "Reefer, Air-Ride",
	R2: "Reefer, Double",
	RZ: "Reefer, HazMat",
	RN: "Reefer, Intermodal",
	RL: "Reefer, Logistics",
	RP: "Reefer w/Pallet Exchange",
	RM: "Reefer w/Team",
	VAN: "Van",
	VF: "Van or Flatbed",
	VT: "Van or Flatbed w/Tarps",
	VR: "Van or Reefer",
	VA: "Van, Air-Ride",
	VC: "Van, Curtain",
	VS: "Van, Conestoga",
	V2: "Van, Double",
	VZ: "Van, HazMat",
	VH: "Van, Hotshot",
	VI: "Van, Insulated",
	VN: "Van, Intermodal",
	VG: "Van, Lift-Gate",
	VL: "Van, Logistics",
	MV: "Van, Moving",
	OT: "Van, Open-Top",
	PV: "Van, Plated",
	VB: "Van, Roller Bed",
	V3: "Van, Triple",
	VV: "Van, Vented",
	VW: "Van, w/Blanket Wrap",
	VP: "Van, w/Pallet Exchange",
	VM: "Van w/Team",
	PO: "Power Only",
	TT: "Truck and Trailer",
	OTH: "Other Equipment",
	CV: "Conveyor",
	AC: "Auto Carrier",
	SB: "Straight Box Truck",
	TA: "Aluminum Tanker",
	TN: "Intermodal Tanker",
	TS: "Steel Tanker",
	DD: "Double Drop",
	LA: "Landoll Drop Deck",
	DT: "Dump Trailer",
	LB: "Lowboy",
	LR: "Lowboy or RGN",
	LO: "Over Dimension Lowboy",
	RG: "Removable Gooseneck",
	SR: "Step Deck or RGN",
	ST: "Stretch Trailer",
	SD: "Step Deck",
};

const getLoadEquipments = () => {
	let equipmentList = [];

	for (let key in LOAD_EQUIPMENTS) {
		equipmentList.push({ label: LOAD_EQUIPMENTS[key], value: key });
	}
	return equipmentList;
};

const createTypeView = (label, skinny, options = {}) => {
	let iconStyle = skinny ? " text-16 " : " text-18 ";
	let color =
		label === "Team" || label === "T"
			? "bg-cyan text-white"
			: label === "Factorable"
			? "bg-green text-white"
			: label?.includes("'")
			? "bg-blue-400 text-white"
			: label === "Hazmat"
			? "text-red"
			: label === "Partial"
			? "text-black"
			: "bg-orange text-white";
	let tooltipInfo =
		label === "Team" || label === "T"
			? "Team load"
			: LOAD_EQUIPMENTS[label]
			? LOAD_EQUIPMENTS[label]
			: label?.includes("'")
			? `The load length is ${label}`
			: label === "Factorable"
			? `This load is eligible for factoring. Providers: ${options.extraText}. Please check if your company provider is included`
			: label === "Hazmat"
			? `This load is hazmat, please be sure you meet all the requirements before taking this load`
			: label === "soap"
			? `DAT api V2`
			: label === "api"
			? `DAT api V3`
			: label === "Partial"
			? `This load is partial`
			: label;
	let icon = label === "Hazmat" ? "warning" : label === "Partial" ? "tonality" : undefined;

	//Reduce badge label for long labels
	label =
		label === "Factorable"
			? "F"
			: label === "VAN"
			? "V"
			: label === "REEFER"
			? "R"
			: label === "FLATBED"
			? "F"
			: label === "soap"
			? "V2"
			: label === "api"
			? "V3"
			: label;

	if (icon) {
		return createTooltip(
			<div
				className={
					" flex inline font-semibold " +
					(options?.smallScreen ? " py-0 text-10 " : skinny ? " py-0 text-11  " : " text-11 py-0 ") +
					color
				}
			>
				<Icon className={color + " py-0 -ml-1 -mr-1 font-500 " + iconStyle}>{icon}</Icon>
			</div>,
			tooltipInfo,
			"bg-emerald-400"
		);
	} else if (label === "V2" || label === "V3") {
		let datLabel = label.split("");
		let styleLabel =
			"inline font-semibold truncate bg-blue-800 text-white " +
			(options?.smallScreen ? " py-0 text-10 px-3 " : skinny ? " py-0 text-10 px-3  " : " text-11 py-0 px-3 ");
		return createTooltip(
			<>
				<div className={styleLabel + " mr-1"}>{datLabel[0]}</div>
				<div className={styleLabel}>{datLabel[1]}</div>
			</>,
			tooltipInfo,
			"bg-emerald-400"
		);
	} else {
		return createTooltip(
			<div
				className={
					"inline font-semibold rounded-full truncate " +
					(options?.smallScreen ? " py-0 text-10 px-4 " : skinny ? " py-0 text-10 px-4  " : " text-11 py-0 px-10 ") +
					color
				}
			>
				{label}
			</div>,
			tooltipInfo,
			"bg-emerald-400"
		);
	}
};

const createExtraStopView = (trip, skinny, options = {}) => {
	let color = "bg-blue-900 text-white";
	let extraStops;
	if (trip.stopsNumber > 2) {
		extraStops = trip.stopsNumber - 2;
	}
	let label = `S${extraStops}`;
	let tooltipInfo = `This load has ${extraStops} extra stop${extraStops > 1 ? "s" : ""}`;
	return (
		extraStops &&
		createTooltip(
			<div
				className={
					"inline font-semibold rounded-full truncate " +
					(options?.smallScreen
						? " py-0 ml:py-1 text-10 px-3 ml:px-5 "
						: skinny
						? " py-0 ml:py-1 text-11 px-3 ml:px-5  "
						: " text-11 ml:text-12 py-0 ml:py-1 ml:py-2 px-10 ml:px-16 ") +
					color
				}
			>
				{label}
			</div>,
			tooltipInfo,
			"bg-emerald-400"
		)
	);
};

const _multistopInfo = (trip) => {
	let extraStops;
	if (trip.stopsNumber > 2) {
		extraStops = trip.stopsNumber - 2;
	} else {
		return undefined;
	}
	let label = `S${extraStops}`;
	let tooltipInfo = `This load has ${extraStops} extra stop${extraStops > 1 ? "s" : ""}`;
	return { label, tooltipInfo };
};

const getFlexibleDescription = (item) => {
	const getFlexibleReasonText = (reason) => {
		if (reason === "DH_ORIGIN") {
			return "DH-O outside of preferred range";
		} else if (reason === "DH_DEST") {
			return "DH-D outside of preferred range";
		} else if (reason === "PICKUP_DS") {
			return "Load pickup time outside of preferred pickup time";
		} else if (reason === "DELIVERY_DS") {
			return "Load delivery time outside of preferred delivery time";
		} else if (reason === "STATES_BLACKLISTED") {
			return "Driver blacklisted destination state";
		} else if (reason === "BROKER_BLACKLISTED") {
			return "Broker blacklisted";
		} else if (reason === "BROKER_NOT_ONBOARDED") {
			return "Broker require onboarding";
		} else if (reason === "WEIGHT") {
			return "Load weight outside of trailer max weight";
		} else if (reason === "TRAILER_SIZE") {
			return "Load requires a bigger trailer";
		} else if (reason === "LOW_RPM") {
			return "Load RPM outside of preferred RPM";
		} else if (reason === "LOW_RATE") {
			return "Load rate outside of preferred rate";
		} else if (reason === "MILES") {
			return "Trip miles outside of preferred miles";
		} else if (reason === "LOAD_AGE") {
			return "Load age outside of preferred age";
		} else if (reason === "ANOTHER_DEST") {
			return "Destination does not match preferred destination";
		} else if (reason === "BROKER_POSSIBLE_SCAMMER") {
			return "Broker is possibly a scammer";
		} else if (reason === "RANK_MCI") {
			return "Load outside of preferred MPI";
		} else if (reason === "CREDIT_SCORE") {
			return "Broker credit score lower than preferred";
		} else if (reason === "EXTRA_STOP") {
			return "Load has more extra stops than preferred";
		} else {
			return reason;
		}
	};

	let tTitle = (
		<>
			<Typography color="inherit" variant="body2" className="p-6">
				Why marked as flexible?
			</Typography>
			{item.flexibleReasons?.map((reason, index) => {
				return (
					<Typography key={index} color="inherit" variant="body2" className="p-6 font-light">
						{getFlexibleReasonText(reason)}
					</Typography>
				);
			})}
		</>
	);

	return tTitle;
};

const BADGES_INFORMATION = (item) => {
	return {
		MATCH_STATUS: {
			condition: (item) => {
				return item.best;
			},
			title: "Best Load",
			color: "bg-primary",
			description: () => {
				return (
					<>
						<Typography color="inherit" variant="body2" className="p-6">
							Best Load ("HOP")
						</Typography>
						<Typography color="inherit" variant="body2" className="p-6 font-light">
							This load is expected to be profitable and the destination market will yield good profits too!
						</Typography>
					</>
				);
			},
			icon: (item) => {
				return {
					type: "IMAGE",
					value: "assets/images/badges/best_badge.png",
					style: { aspectRatio: "1/1" },
				};
			},
		},
		NEW: {
			condition: (item) => {
				return item.new;
			},
			title: "New Load",
			color: "bg-cyan",
			description: () => {
				return "This is a new load";
			},
			icon: (item) => {
				return {
					type: "LABEL",
					value: "N",
					style: "bg-cyan",
				};
			},
		},
		HAZMAT: {
			condition: (item) => {
				return item.hazmat;
			},
			title: "Hazmat",
			color: "bg-red",
			description: () => {
				return "This load is hazmat, please be sure you meet all the requirements before taking this load";
			},
			icon: (item) => {
				return {
					type: "LABEL",
					value: "H",
					color: "red",
					style: "border-1 border-red text-red",
				};
			},
		},
		FLEXIBLE: {
			condition: (item) => {
				return item.flexible;
			},
			title: "Flexible",
			color: "bg-orange",
			description: (item) => {
				return getFlexibleDescription(item);
			},
			icon: (item) => {
				return {
					type: "LABEL",
					value: "F",
					color: "orange",
					style: "border-1 border-orange text-orange",
				};
			},
		},
		MATCH_PLAN: {
			condition: (item) => {
				return item.match_plan;
			},
			title: "Match",
			color: "bg-blue-900",
			description: () => {
				return "Plan Match: this load matches with your truck plan criteria.";
			},
			icon: (item) => {
				return {
					type: "LABEL",
					value: "M",
					style: "bg-blue-900",
				};
			},
		},
		TRUSTED: {
			condition: (item) => {
				return item.trusted;
			},
			title: "Trusted Load",
			color: "bg-purple",
			description: () => {
				return "Trusted: this load came from a trusted source.";
			},
			icon: (item) => {
				return {
					type: "LABEL",
					value: "T",
					style: "bg-purple",
				};
			},
		},
		COVERED: {
			condition: (item) => {
				return item.deleted;
			},
			title: "Covered",
			color: "bg-red-600",
			description: (item) => {
				return (
					"Covered: " +
					(item?.coveredBy ? `mark as covered by ${item?.coveredBy}` : "This load is not longer available")
				);
			},
			icon: (item) => {
				return {
					type: "LABEL",
					value: "X",
					style: "bg-red-600",
				};
			},
		},
		TRANSIT: {
			condition: () => {
				return true;
			},
			title: item?.badTransit ? "Bad Transit" : "Good Transit",
			color: item?.badTransit ? "bg-red" : "bg-green",
			description: (item) => {
				if (item?.badTransit) {
					return "Bad Transit: the pickup and the delivery of the load is not timely and efficient.";
				} else {
					return "Good Transit: the pickup and the delivery of the load is timely and efficient.";
				}
			},
			icon: (item) => {
				return {
					type: "ICON",
					value: "av_timer",
					style: item?.badTransit ? "text-red" : "text-green",
				};
			},
		},
		MATCH_STRATEGY: {
			condition: (item) => {
				return item.match_strategy;
			},
			title: "Strategic",
			color: "bg-red-900",
			description: () => {
				return "Strategic: this load matches current truck strategy.";
			},
			icon: (item) => {
				return {
					type: "ICON",
					value: "account_tree",
					style: "text-red-900",
				};
			},
		},
		VERIFIED: {
			condition: (item) => {
				return item.verified;
			},
			title: "Verified",
			color: "bg-green",
			description: (item) => {
				return (
					"Verified: " +
					(item?.verifiedBy ? `verified by ${item?.verifiedBy}` : "this load details has been verified with the broker")
				);
			},
			icon: (item) => {
				return {
					type: "ICON",
					value: "check_circle",
					style: "text-green",
				};
			},
		},
		BOOKED_BY: {
			condition: (item) => {
				return item.bookedBy;
			},
			title: "Booked",
			color: "bg-blue",
			description: (item) => {
				return `Booked: this load has been booked by ${item?.bookedBy?.split("/")[1]}`;
			},
			icon: (item) => {
				return {
					type: "ICON",
					value: "book",
					style: "text-blue",
				};
			},
		},
		REPORT: {
			condition: (item) => {
				return item.report;
			},
			title: "Reported",
			color: "bg-red",
			description: (item) => {
				return `Reported by ${item?.reportedByEmail}`;
			},
			icon: (item) => {
				return {
					type: "ICON",
					value: "report",
					style: "text-red",
				};
			},
		},
		BROKER_ONBOARDED: {
			condition: (item) => {
				return item.brokerOnboarded;
			},
			title: "On-Boarded Broker",
			color: "bg-green",
			description: (item) => {
				return `Broker ${item?.clientid} is included in your onboarded brokers list`;
			},
			icon: (item) => {
				return {
					type: "ICON",
					value: "group",
					style: "text-green",
				};
			},
		},
		BROKER_BLACKLISTED: {
			condition: (item) => {
				return item.brokerBlacklisted;
			},
			title: "Blacklisted Broker",
			color: "bg-red",
			description: (item) => {
				return `Broker ${item?.clientid} is included in your blacklisted brokers`;
			},
			icon: (item) => {
				return {
					type: "ICON",
					value: "group",
					style: "text-red",
				};
			},
		},
		DEST_BLACKLISTED: {
			condition: (item) => {
				return item.destBlacklisted;
			},
			title: "Blacklisted Destination",
			color: "bg-orange",
			description: (item) => {
				return `${item?.destination} is included in your blacklisted destinations`;
			},
			icon: (item) => {
				return {
					type: "ICON",
					value: "location_off",
					style: "text-orange",
				};
			},
		},
		RESET_NEEDED: {
			condition: (item) => {
				return item.resetNeeded;
			},
			title: "Reset Needed",
			color: "bg-red",
			description: (item) => {
				return `This load requires a reset because the driver have only ${
					item?.remainingCycleDutyTime / 3600
				} hours of remaining Cycle Duty Time and the trip duration is ${item?.duration} days.`;
			},
			icon: (item) => {
				return {
					type: "ICON",
					value: "refresh",
					style: "text-red",
				};
			},
		},
		BROKER_SCAMMER: {
			condition: (item) => {
				return item.brokerScammer;
			},
			title: "Possible Scammer",
			color: "bg-red",
			description: () => {
				return "This Broker is a possible scammer";
			},
			icon: (item) => {
				return {
					type: "ICON",
					value: "report_problem",
					style: "text-red",
				};
			},
		},
		MISSING_HOS: {
			condition: (item) => {
				return item.missingHOStoPU;
			},
			title: "Missing HOS to PU",
			color: "bg-red-600",
			description: (item) => {
				return `You have ${parseFloat(item?.remainingDriveTime / 3600).toFixed(
					1
				)} HOS left but the pickup is ${parseFloat(item?.pickupDriveDuration).toFixed(
					1
				)} hours away from your location`;
			},
			icon: (item) => {
				return {
					type: "ICON",
					value: "timer",
					style: "text-red-600",
				};
			},
		},
		TEAM: {
			condition: (item) => {
				return item.team;
			},
			title: "Team",
			color: "bg-cyan",
			description: (item) => {
				return "Team load";
			},
			icon: (item) => {
				return {
					type: "LABEL",
					value: "T",
					color: "cyan",
					style: "border-1 border-cyan text-cyan",
				};
			},
		},
		MULTISTOP: {
			condition: (item) => {
				return item.stopsNumber > 2;
			},
			title: "Multi Stop",
			color: "bg-blue-900",
			description: (item) => {
				return _multistopInfo(item)?.tooltipInfo ?? "This load has 1 extra stop";
			},
			icon: (item) => {
				return {
					type: "LABEL",
					value: _multistopInfo(item)?.label ?? "S1",
					style: "bg-blue-900",
				};
			},
		},
		SPECIAL: {
			condition: (item) => {
				return item.special && !item.team && !item.hazmat;
			},
			title: "Load Equipment",
			color: "bg-teal-900",
			description: (item) => {
				return LOAD_EQUIPMENTS[item.special] ?? item.special ?? "Special equipment";
			},
			icon: (item) => {
				return {
					type: "LABEL",
					value: item.special ?? "VR",
				};
			},
		},
		TRAILER: {
			color: "bg-brown-900",
			condition: (item) => {
				return item.trailerSize && item.trailerSize !== 0 && item.trailerSize !== 1;
			},
			title: "Load length",
			description: (item) => {
				return `The load length is ${item.trailerSize}`;
			},
			icon: (item) => {
				return {
					type: "LABEL",
					value: `${item.trailerSize}'`,
				};
			},
		},
		BOOK: {
			condition: (item, enabledBook) => {
				return (item.booknow || item.bidnow) && enabledBook;
			},
			title: "Book",
			color: "bg-purple-800",
			icon: (item) => {
				return {
					type: "LABEL+ICON",
					label: "Book",
					icon: "flash_on",
					color: "purple-800",
					borderColor: "purple",
				};
			},
		},
		SMARTPAY: {
			condition: (item) => {
				return item.smartpayElegible;
			},
			title: "SmartPay",
			color: "bg-green-700",
			icon: (item) => {
				return {
					type: "LABEL+ICON",
					label: "SmartPay",
					icon: "attach_money",
					color: "green-800",
					borderColor: "green",
				};
			},
		},
		PARTIAL: {
			condition: (item) => {
				return item.partial;
			},
			title: "Capacity",
			color: "bg-teal-900",
			description: (item) => {
				return "Partial Load";
			},
			icon: (item) => {
				return {
					type: "LABEL",
					value: "P",
				};
			},
		},
	};
};

const createMatchBadge = ({ type, value, style, label, icon, color, borderColor }, variant) => {
	const styleLabel = variant === "skiny" ? " text-12 ml:text-11 " : " text-13 ml:text-12 ";

	const containerIcon =
		variant === "skiny"
			? " px-1 py-1 rounded-full truncate mt-1 ml-1 "
			: variant === "extra-skiny"
			? " px-1 py-1 rounded-full truncate mt-1 ml-1 "
			: "";
	const styleIcon =
		variant === "skiny"
			? " -ml-1 -mr-1 text-13 ml:text-15 "
			: variant === "extra-skiny"
			? " -ml-1 -mr-3 text-12 ml:text-13 "
			: " mr-3 ";

	if (type === "LABEL") {
		return (
			<div
				style={{ borderColor: borderColor }}
				className={
					(style?.length > 0 ? style : borderColor ? " border-1 " : " border-1 border-grey ") +
					" flex flex-row min-w-18 ml:min-w-16 h-18 ml:h-16 items-center justify-center rounded-4 px-2 mr-4 ml:mr-3 truncate "
				}
			>
				<Typography component={"div"} className={"font-500 px-1 ml:px-2 text-" + (color ?? "grey") + " " + styleLabel}>
					{value}
				</Typography>
			</div>
		);
	}

	if (type === "LABEL+ICON") {
		return (
			<div
				// WORKAROUND: border color need to be part of style or after compression of the UI style does not apply
				style={{ borderColor: borderColor }}
				className={"flex flex-row h-18 ml:h-16 mr-4 ml:mr-3 items-center justify-center rounded-4 p-0 border-1"}
			>
				<div className={"flex items-center min-w-16 ml:min-w-14 h-16 ml:h-14 justify-center bg-" + color}>
					<Icon className={"font-500 text-white " + styleIcon}>{icon}</Icon>
				</div>
				<Typography className={"font-500 px-2 text-" + color + " " + styleLabel}>{label}</Typography>
			</div>
		);
	}

	if (type === "ICON") {
		return (
			<span className={"flex inline " + containerIcon}>
				<Icon className={style + " font-500 " + styleIcon}>{value}</Icon>
			</span>
		);
	}

	let styleImage =
		variant === "skiny"
			? { width: undefined, height: 18, marginRight: 2 }
			: variant === "extra-skiny"
			? { width: undefined, height: 14, marginRight: 2 }
			: { width: undefined, height: 26, marginRight: 5 };

	styleImage = { ...styleImage, ...style };

	if (type === "IMAGE") {
		return (
			<Image
				disableSpinner={true}
				disableTransition={true}
				imageStyle={styleImage}
				style={{ ...styleImage, paddingTop: null, backgroundColor: "transparent" }}
				src={value}
			/>
		);
	}
};

const createBadgeView = (item, type, badgeKey = null, variant = "extra-skiny", viewType = "", options = {}) => {
	let BADGES = BADGES_INFORMATION(item);

	if (type === "STATUS") {
		const badge = BADGES[badgeKey];
		return createMatchBadge(badge.icon(item), "extra-skiny");
	}

	if (!item) return;

	if (type === "LIST") {
		let LIST = [];
		LIST = options?.hideBookNow ? [] : ["BOOK"];

		BADGES = Object.keys(BADGES)
			.filter((key) => LIST.includes(key))
			.reduce((obj, key) => {
				return Object.assign(obj, { [key]: BADGES[key] });
			}, {});
	}

	if (type === "MOBILE_CARD") {
		let LIST = [
			...(options?.hideBookNow ? [] : ["BOOK"]),
			...["SMARTPAY", "FLEXIBLE", "HAZMAT", "TEAM", "TRAILER", "SPECIAL"],
		];

		BADGES = Object.keys(BADGES)
			.filter((key) => LIST.includes(key))
			.reduce((obj, key) => {
				return Object.assign(obj, { [key]: BADGES[key] });
			}, {});
	}

	if (type === "FEATURE") {
		const LIST = ["FLEXIBLE", "HAZMAT", "TEAM", "TRAILER", "SPECIAL"];

		BADGES = Object.keys(BADGES)
			.filter((key) => LIST.includes(key))
			.reduce((obj, key) => {
				return Object.assign(obj, { [key]: BADGES[key] });
			}, {});
	}

	if (type === "WEIGHT") {
		const LIST = ["PARTIAL"];

		BADGES = Object.keys(BADGES)
			.filter((key) => LIST.includes(key))
			.reduce((obj, key) => {
				return Object.assign(obj, { [key]: BADGES[key] });
			}, {});
	}

	if (type === "MAJOR") {
		const LIST = ["FLEXIBLE", "HAZMAT", "TEAM"];
		BADGES = Object.keys(BADGES)
			.filter((key) => LIST.includes(key))
			.reduce((obj, key) => {
				return Object.assign(obj, { [key]: BADGES[key] });
			}, {});
	}

	if (type === "SUMMARY") {
		const LIST = ["FLEXIBLE", "HAZMAT", "TEAM", "TRAILER", "SPECIAL", "MATCH_STATUS", "VERIFIED", "COVERED"];

		BADGES = Object.keys(BADGES)
			.filter((key) => LIST.includes(key))
			.reduce((obj, key) => {
				return Object.assign(obj, { [key]: BADGES[key] });
			}, {});
	}

	const enabledBook = isBookingEnabled();
	return (
		<>
			{Object.keys(BADGES).map((key) => {
				const badge = BADGES[key];
				if (!badge.condition(item, enabledBook) && !isEnabled("QA_SEARCH_TYPE_BADGES")) return null;

				if (type === "SUMMARY") {
					return (
						<div key={key}>
							{createTooltip(
								createMatchBadge(badge.icon(item), variant),
								badge.descriptionLarge?.(item) ?? badge.description?.(item),
								"bg-emerald-400"
							)}
						</div>
					);
				} else if (type === "DETAIL") {
					return (
						<BadgeTooltip
							key={key}
							title={badge.title}
							colorClass={badge.color}
							description={badge.description?.(item)}
							spinner={badge.spinner && badge.spinner(item)}
						/>
					);
				} else if (
					type === "LIST" ||
					type === "FEATURE" ||
					type === "MAJOR" ||
					type === "MOBILE_CARD" ||
					type === "WEIGHT"
				) {
					return (
						<div key={key}>
							{createTooltip(createMatchBadge(badge.icon(item), "skiny"), badge.description?.(item), "bg-emerald-400")}
						</div>
					);
				} else {
					return null;
				}
			})}
		</>
	);
};

const showCategoryView = (category, index, wait, daysArray, dayToBook, weekDays, smallScreen, variant) => {
	let build;
	dayToBook = moment(dayToBook).format("dddd, MMM D");

	if (index === -1 || !category) {
		build = {
			color: "text-gray-500",
			text: "N/A",
			textTooltip: "Due to a lack of sufficient data, we are unable to draw any conclusions at this time",
		};
	} else if (category !== -1 && daysArray?.length === 1) {
		build = {
			color: "text-red-600",
			text: "Best",
			textTooltip: `Today ${dayToBook} is the best time to book`,
		};
	} else if (category !== -1) {
		build = {
			color: "text-orange-600",
			text: "Good",
			textTooltip: `Since ${dayToBook} is good to book, we don’t expect rates to change as much`,
		};
	} else if (weekDays < Math.min(...daysArray)) {
		build = {
			color: "text-gray-500",
			text: "Past",
			textTooltip: `This is not the best time to book, it was on ${dayToBook}`,
		};
	} else if (weekDays > Math.max(...daysArray)) {
		build = {
			color: "text-yellow-600",
			text: `+${wait} day(s)`,
			textTooltip: `We recommend waiting ${wait} days to book, ${dayToBook}`,
		};
	}
	return (
		<Tooltip
			classes={{ tooltip: "drop-shadow-md " }}
			title={
				<Typography component={"div"} color="inherit" className={"text-11 md:text-12 p-6 font-semibold "}>
					{build?.textTooltip}
				</Typography>
			}
		>
			<div className="flex">
				<Icon className={(variant === "side" ? "text-25 " : "text-18 ") + `mr-2 ${build?.color}`}>schedule</Icon>
				<Typography
					component={"div"}
					color="inherit"
					className={
						(variant === "side" ? "text-14 " : smallScreen ? "text-11 " : "text-12 ") + "self-center font-normal"
					}
				>
					{build?.text}
				</Typography>
			</div>
		</Tooltip>
	);
};

const errorViewMarket = (variant) => {
	return (
		<Tooltip
			classes={{ tooltip: "drop-shadow-md " }}
			title={<Typography color="inherit">Couldn't find market conditions</Typography>}
		>
			<div className="flex">
				<Icon className={(variant === "side" ? "text-25 " : "text-18 ") + "mr-2 text-grey-500"}>report</Icon>
			</div>
		</Tooltip>
	);
};

const createSmartPayView = () => {
	return <Icon className={"text-18 text-grey-500"}>check</Icon>;
};

const descriptionLoadId = (load) => {
	if (load?.hasBrokerId) return "Load ID " + load?.load_id;
	return "No Load ID";
};

const checkValidURL = (url) => {
	if (!url) return false;
	const urlRegex = /(https?:\/\/[^\s]+)/g;
	return urlRegex.test(url);
};

export {
	getFlexibleDescription,
	getFlexibleReasonsText,
	getFlexibleReasonName,
	getFormatedDate,
	createGenericErrorsFrom,
	createTypeView,
	createExtraStopView,
	getLoadEquipments,
	createBadgeView,
	showCategoryView,
	errorViewMarket,
	createSmartPayView,
	descriptionLoadId,
	checkValidURL,
	LOAD_EQUIPMENTS,
};
