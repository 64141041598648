import { useEffect, useState, useMemo, useRef } from "react";
import { SmarthopErrorView } from "@smarthop/list";
import { global } from "app/services/requestUtil";
import axios from "axios";

import { readURLParameters, convertURLParamsToModel } from "../utils/urlUtils";
import { setAuthToken, setUserId } from "app/services/LoginService";
import { useDispatch, useSelector } from "react-redux";

import SmarthopForm from "@smarthop/form/SmarthopForm";

import LoadStrategyTabsView from "../search/load/LoadStrategyTabsView";
import LoadSearchView from "app/main/search/result/LoadSearchView";
import TermsView from "app/main/terms/TermsView";
import FuelTermsView from "app/main/terms/FuelTermsView";
import ResetPasswordView from "app/main/auth/password/ResetPasswordView";
import RegisterView from "app/main/auth/register/RegisterView";
import TripView from "app/main/profile/trips/TripView";
import CreditCardView from "app/main/billing/payments/CreditCardView";
import TruckPlanEdit from "app/main/truck-plans/TruckPlanEdit";
import TruckPlanView from "app/main/truck-plans/TruckPlanView";
import OnboardingPage from "app/main/onboarding/OnboardingPage";
import HomePage from "app/main/home/HomePage";
import PhoneConfirmView from "app/main/profile/validation/PhoneConfirmView";
import EmailConfirmationView from "app/main/profile/validation/EmailConfirmationView";
import CompanyInfoConfirmationView from "app/main/profile/validation/CompanyInfoConfirmationView";
import TripFilesForm from "../profile/trips/TripFilesForm";
import TripsNeedDeliveredView from "app/main/widgets/static/TripsNeedDeliveredView";
import TruckView from "app/main/profile/trucks/TruckView";
import TruckDetailsDialog from "app/main/profile/trucks/TruckDetailsDialog";
import TrailerView from "app/main/profile/trucks/TrailerView";
import TripsNeedPod from "app/main/widgets/static/TripsNeedPod";
import CarrierUsersEditView from "../profile/carriers/CarrierUsersEditView";
import CalculatorLoadView from "app/main/calculator/CalculatorLoadView";
import LoadSearchResultView from "app/main/search/result/LoadSearchResultView";
import TripInvoicingView from "app/main/profile/trips/invoicing/TripInvoicingView";
import SmartPaySubmissionView from "app/main/profile/trips/invoicing/SmartPaySubmissionView";
import FileSharingDialog from "app/main/tools/FileSharingDialog";
import BrokerInfoView from "app/main/profile/brokers/BrokerInfoView";
import TripTracking from "../profile/trips/TripTracking";
import PlatformAvailablePlansView from "app/main/billing/plans/PlatformAvailablePlansView";
import UserDetailsView from "../profile/users/UserDetailsView";

import ChatApp from "app/main/chat/ChatApp";
import LoadSearchResult from "app/main/search/result/LoadSearchResultTab";
import TripEditView from "app/main/profile/trips/TripEditView";

import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { setActiveChat } from "app/store/messenger/messageSlice";
import { set } from "app/services/featureService";
import { setUser } from "app/store/auth/userSlice";
import { setSubAccount } from "app/store/auth/accountSlice";

import MobileDetect from "mobile-detect";
import PageWarning from "../common/PageWarning";
import { buildForm } from "@smarthop/form/registy";
import SignupPage from "../signup/SignupPage";
import SetupPage from "../setup/SetupPage";
import InReviewBlocker from "app/fuse-layouts/shared-components/InReviewBlocker";

const mobileDetect = new MobileDetect(window.navigator.userAgent);

const NO_AUTH_REQUIRED = [
	"PRIVACY_POLICY_VIEW",
	"SMARTHOP_TERMS_VIEW",
	"SMS_TERMS_VIEW",
	"REGISTRATION_VIEW",
	"PASSWORD_RECOVERY_VIEW",
];

function NativeForm() {
	const dispatch = useDispatch();
	const [dataIds] = useState(convertURLParamsToModel(readURLParameters(), {}, { notJson: true }));
	const mode = dataIds.mode ?? "VIEW";
	const formId = dataIds?.formId;
	const viewId = dataIds?.viewId;

	const [dialogTitle, setDialogTitle] = useState({});
	const setTitle = (newTitle) => {
		if (viewId) {
			setDialogTitle((val) => ({ ...val, [viewId]: newTitle }));
		}
	};

	const loadingHandedByViewRef = useRef(null);
	const user = useSelector(({ auth: { user } }) => user);

	const formatString = (value) => {
		return value && value !== "null" && value !== "undefined" ? value : null;
	};

	// Saving user related data to local storage, some forms rely on that data
	if (dataIds.token) localStorage.setItem("tokenSmarthop", dataIds.token);
	if (dataIds.userId) localStorage.setItem("userId", formatString(dataIds.userId));
	if (dataIds.carrierId) localStorage.setItem("carrierId", formatString(dataIds.carrierId));
	if (dataIds.driverId) localStorage.setItem("driverId", formatString(dataIds.driverId));
	if (dataIds.isOnboarding) localStorage.setItem("isOnboarding", dataIds.isOnboarding);
	if (dataIds.accountStatus) localStorage.setItem("accountStatus", formatString(dataIds.accountStatus));
	if (dataIds.onboardingStatus) localStorage.setItem("onboardingStatus", formatString(dataIds.onboardingStatus));
	if (dataIds.profileId) localStorage.setItem("profileId", dataIds.profileId);
	if (dataIds.username)
		localStorage.setItem("username", formatString(dataIds.username) ?? formatString(dataIds.userEmail));
	if (dataIds.profilename)
		localStorage.setItem(
			"firstName",
			formatString(dataIds.profilename) ??
				(formatString(dataIds.first_name) ?? "") + " " + (formatString(dataIds.last_name) ?? "")?.trim()
		);
	if (dataIds.phone) localStorage.setItem("phone", formatString(dataIds.userPhone) ?? "");
	// WORKAROUND: for backward compatibility consider every user who is using native
	// mobile app as carrier owner to show proper UI
	if (dataIds.roleUser) localStorage.setItem("roleUser", formatString(dataIds.roleUser) ?? "2");
	if (dataIds.carrierDisabledBooking) {
		localStorage.setItem("disabledDigitalBooking", formatString(dataIds.carrierDisabledBooking));
	}
	if (dataIds.userTier) localStorage.setItem("user_tier", formatString(dataIds.userTier));

	// Set principal permission
	const gatekeepers = {
		permission_users_access: dataIds.permission_users_access === "true",
		permission_rates_on: dataIds.permission_rates_on === "true",
		permission_messaging_on: dataIds.permission_messaging_on === "true",
		permission_search_on: dataIds.permission_search_on === "true",
		permission_book_on: dataIds.permission_book_on === "true",
		permission_trips_access: dataIds.permission_trips_access,
		permission_invoice_access: dataIds.permission_invoice_access,
	};
	localStorage.setItem("gatekeepers", JSON.stringify(gatekeepers));

	const features = useMemo(() => {
		const res = {};
		Object.keys(dataIds)
			.filter((k) => k.includes("feature_"))
			.forEach((f) => {
				res[f.replace("feature_", "")] = dataIds[f] === "true";
			});
		localStorage.setItem("features", JSON.stringify(res));
		localStorage.setItem("nativeMobile", true);

		return res;
	}, [dataIds]);

	const [data, setData] = useState(null);
	const [error, setError] = useState(null);

	const handleMessageFromNative = (msg) => {
		try {
			const dataParsed = JSON.parse(msg.data);
			switch (dataParsed.type) {
				case "INCREMENT_ALL_REVISIONS":
					dispatch(incrementDataRevision({ event: "onboardingRevision" }));
					dispatch(incrementDataRevision({ event: "paymentRevision" }));
					dispatch(incrementDataRevision({ event: "profileRevision" }));
					return;
				case "INCREMENT_REVISION":
					if (dataParsed.event) {
						dispatch(incrementDataRevision({ event: dataParsed.event }));
					}
					return;
				default:
					break;
			}
		} catch (e) {}
	};

	useEffect(() => {
		const listener = mobileDetect.is("iPhone") ? window : document;
		listener.addEventListener("message", handleMessageFromNative);
		return () => {
			listener.removeEventListener("message", handleMessageFromNative);
		};
	});

	useEffect(() => {
		dispatch(
			setUser({
				_id: formatString(dataIds.userId),
				userId: formatString(dataIds.userId),
				carrierId: formatString(dataIds.carrierId),
				driverId: formatString(dataIds.driverId),

				email: formatString(dataIds.username) ?? formatString(dataIds.userEmail) ?? "",
				phone: formatString(dataIds.userPhone) ?? "",

				isOnboarding: formatString(dataIds.isOnboarding),
				accountStatus: formatString(dataIds.accountStatus),
				onboardingStatus: formatString(dataIds.onboardingStatus),
				isTestCarrier: formatString(dataIds.isTestCarrier),
				// TODO improve way name is passed to native mobile
				first_name: formatString(dataIds.firstName),
				last_name: formatString(dataIds.lastName),
				// DEPRECATED use role and roleType instead
				roles: [parseInt(dataIds.roleUser) >= 0 ? parseInt(dataIds.roleUser) : 2],
				rolesLabels: [dataIds.role],

				role: formatString(dataIds.role),
				roleType: formatString(dataIds.roleType),

				disable_digital_booking: !!formatString(dataIds.carrierDisabledBooking),
				gatekeepers: gatekeepers,
				nativeMobile: window?.location?.href?.includes("/native/"),
			})
		);

		dispatch(setSubAccount({ hasSubaccounts: dataIds.hasSubaccounts === "true" }));
		// eslint-disable-next-line
	}, []);

	const handleDone = (data) => {
		console.log(`[NativeForm] handleDone: data => ${JSON.stringify(data)}`);
		window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "DONE", data: data ?? {} }));
	};

	const handleLoading = (inProgress, error) => {
		console.log(`[NativeForm] handleLoading: inProgress => ${inProgress}`, new Error());
		window?.ReactNativeWebView?.postMessage(
			JSON.stringify({ type: "LOADING", status: inProgress ? "STARTED" : "FINISHED", error })
		);
	};

	const handleProcessing = (inProgress, error) => {
		console.log(`[NativeForm] handleProcessing: inProgress => ${inProgress}`);
		window?.ReactNativeWebView?.postMessage(
			JSON.stringify({ type: "PROCESSING", status: inProgress ? "STARTED" : "FINISHED", error })
		);
	};

	const handleEvent = (event, data) => {
		console.log(`[NativeForm] handleEvent: event => ${event}`);
		window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "EVENT", event, data }));
	};

	const handleDialogOpen = (data) => {
		const form = buildForm(formId, { user });
		if (formId) {
			if (mode === "CREATE") {
				data.title = form?.header?.create?.label ?? formId;
			} else if (mode === "EDIT") {
				data.title = form?.header?.edit?.label ?? formId;
			} else if (mode === "DELETE") {
				data.title = form?.header?.delete?.label ?? formId;
			} else if (mode === "VIEW") {
				data.title = form?.header?.view?.label ?? formId;
			}
		} else if (viewId) {
			data.title = dialogTitle[viewId];
		}

		console.log(`[NativeForm] handleDialogOpen: data => ${JSON.stringify(data)}`);
		window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "OPEN_DIALOG", data }));
	};

	const renderView = (dispatch, viewId, dataIds, onLoadEvent) => {
		let params = {
			nativeMobile: true,
			dataIds,
			onDone: handleDone,
			onEvent: handleEvent,
			onProgress: handleProcessing,
			onLoading: (inProgress, error) => {
				onLoadEvent?.(inProgress, error);
				handleLoading(inProgress, error);
			},
			onDialogOpen: handleDialogOpen,
			setTitle,
		};
		if (viewId === "CHAT_APP") {
			if (dataIds.chatId) {
				dispatch(
					setActiveChat({
						_id: dataIds.chatId,
						nativeOverried: true,
					})
				);
			}
			return <ChatApp {...params} />;
		} else if (viewId === "SEARCH_RESULT_VIEW") {
			return <LoadSearchResult {...params} />;
		} else if (viewId === "LOAD_STRATEGY_VIEW") {
			return <LoadStrategyTabsView {...params} />;
		} else if (
			// backwards compatiable for react native use "LOAD_SUGGEST_VIEW"
			["LOAD_SUGGEST_VIEW", "LOAD_REQUEST_VIEW", "LOAD_ACCEPT_VIEW", "LOAD_REQUEST_DETAILS_VIEW"].includes(viewId)
		) {
			return <LoadSearchView {...params} />;
		} else if (viewId === "SMARTHOP_TERMS_VIEW") {
			return <TermsView {...params} type={"SERVICE_TERMS"} />;
		} else if (viewId === "SMS_TERMS_VIEW") {
			return <TermsView {...params} type={"SMS_TEMRS"} />;
		} else if (viewId === "PRIVACY_POLICY_VIEW") {
			return <TermsView {...params} type={"PRIVACY_POLICY"} />;
		} else if (viewId === "FUEL_TERMS_VIEW") {
			return <FuelTermsView {...params} />;
		} else if (viewId === "PASSWORD_RECOVERY_VIEW") {
			return <ResetPasswordView {...params} />;
		} else if (viewId === "IN_REVIEW") {
			return <InReviewBlocker {...params} nativeView={true} />;
		} else if (viewId === "SETUP_VIEW_NEW_LOGGED_IN") {
			return <SetupPage {...params} />;
		} else if (viewId === "REGISTRATION_VIEW_NEW_LOGGED_IN") {
			// hack to avoid login flashing on native becuase user does not load right away
			return null; // <SignupPage minStep={2} {...params} />;
		} else if (viewId === "REGISTRATION_VIEW_NEW") {
			return null; //<SignupPage {...params} />;
		} else if (viewId === "REGISTRATION_VIEW") {
			return <RegisterView {...params} />;
		} else if (viewId === "TRIP_VIEW") {
			return <TripView {...params} />;
		} else if (viewId === "CREDIT_CARD") {
			return <CreditCardView {...params} />;
		} else if (viewId === "TRUCK_PLAN_EDIT") {
			return <TruckPlanEdit {...params} />;
		} else if (viewId === "TRUCK_PLAN_VIEW") {
			return <TruckPlanView {...params} />;
		} else if (viewId === "DASHBOARD_CARRIER_VIEW") {
			return <HomePage {...params} />;
		} else if (viewId === "UPLOAD_FILES_FORM") {
			return <TripFilesForm {...params} />;
		} else if (viewId === "ONBOARDING_VIEW") {
			return <OnboardingPage {...params} />;
		} else if (viewId === "PHONE_CONFIRM") {
			return <PhoneConfirmView {...params} />;
		} else if (viewId === "EMAIL_CONFIRM") {
			return <EmailConfirmationView {...params} />;
		} else if (viewId === "COMPANY_INFO_CONFIRM") {
			return <CompanyInfoConfirmationView {...params} />;
		} else if (viewId === "TRIP_EDIT_VIEW") {
			return <TripEditView {...params} />;
		} else if (viewId === "RESTRICTION_VIEW") {
			return <PageWarning {...params?.dataIds} />;
		} else if (viewId === "TRIPS_NEED_DELIVERED") {
			return <TripsNeedDeliveredView {...params} />;
		} else if (viewId === "TRUCK_VIEW") {
			return <TruckView {...params} />;
		} else if (viewId === "TRUCK_DETAILS_VIEW") {
			return <TruckDetailsDialog {...params} />;
		} else if (viewId === "TRAILER_VIEW") {
			return <TrailerView {...params} />;
		} else if (viewId === "TRIPS_NEED_POD") {
			return <TripsNeedPod {...params} />;
		} else if (viewId === "CARRIER_USER_EDIT_VIEW") {
			return <CarrierUsersEditView {...params} />;
		} else if (viewId === "CALCULATOR_LOAD_VIEW") {
			return <CalculatorLoadView {...params} />;
		} else if (viewId === "LOADS_SEARCH_RESULTS_VIEW") {
			return <LoadSearchResultView {...params} />;
		} else if (viewId === "TRIP_INVOICE_VIEW") {
			return <TripInvoicingView {...params} />;
		} else if (viewId === "SMARTPAY_SUBMISSION_VIEW") {
			return <SmartPaySubmissionView {...params} />;
		} else if (viewId === "FILE_SHARING_DIALOG") {
			return <FileSharingDialog {...params} />;
		} else if (viewId === "BROKER_INFO_VIEW") {
			return <BrokerInfoView {...params} />;
		} else if (viewId === "TRIP_TRACKING") {
			return <TripTracking {...params} />;
		} else if (viewId === "BILLING_PLAN_CHANGE") {
			return <PlatformAvailablePlansView {...params} />;
		} else if (viewId === "USER_DETAILS_VIEW") {
			return <UserDetailsView {...params} />;
		}
	};

	useEffect(() => {
		set(features);
	}, [features]);

	useEffect(() => {
		if (NO_AUTH_REQUIRED.includes(viewId) || NO_AUTH_REQUIRED.includes(formId)) {
			// No auth required
			console.log(`[NativeForm] auth is not required for ${viewId ?? formId}`);
		} else {
			if (!dataIds?.token || !dataIds?.userId) {
				setError("Token is misisng...");
				handleLoading(false);
				return;
			}
			if (!dataIds?.userId) {
				setError("User Id is misisng...");
				handleLoading(false);
				return;
			}

			setAuthToken(dataIds.token);
			setUserId(dataIds.userId);
		}

		if (viewId) {
			const view = renderView(dispatch, viewId, dataIds);
			if (!view) {
				setError(`View not found for ${viewId}...`);
				handleLoading(false, "Invalid view Id...");
				return;
			}
			if (loadingHandedByViewRef.current) {
				// Resetting status to null for the next view call
				loadingHandedByViewRef.current = null;
			} else {
				setTimeout(() => {
					if (!loadingHandedByViewRef.current) {
						// Saving false value instead of null to
						// indicate that loading was handled automatically
						loadingHandedByViewRef.current = false;
						handleLoading(false);
					}
				}, 200);
			}
			setData({});
			return;
		} else if (formId) {
			const form = formId ? buildForm(formId, { user }) : undefined;
			if (!form) {
				setError(`Form not found for ${formId}...`);
				handleLoading(false, "Invalid form Id...");
				return;
			}

			if (!mode) {
				setError("Form mode is missing...");
				handleLoading(false, "Form mode is missing...");
				return;
			}

			if (mode === "CREATE") {
				setData({});
				handleLoading(false);
				return;
			}

			let queryMissingParams = "";
			form?.urlGET?.split("/").forEach((section) => {
				if (section.startsWith(":") && (!dataIds || !dataIds[section.substring(1)])) {
					if (queryMissingParams) queryMissingParams += ",";
					queryMissingParams += section.substring(1);
				}
			});

			if (queryMissingParams) {
				let message = `Params ${queryMissingParams} for GET url ${form?.urlGET} not provided...`;
				setError(message);
				handleLoading(false, message);
				return;
			}

			let urlGET = form?.urlGET;
			Object.keys(dataIds).forEach((key) => {
				urlGET = urlGET?.replace(":" + key, dataIds[key]);
			});

			let headers = {
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("tokenSmarthop"),
			};

			axios
				.create({ baseURL: global.SERVER_NAME, headers: headers })
				.get(urlGET, { headers: headers })
				.then((res) => {
					setData(res.data);
					handleLoading(false);
				})
				.catch((error) => {
					let response = error?.response;
					let message =
						response?.data?.errors?.[0]?.message ??
						(error?.message ? "Unexpected Error: " + error?.message : "Something went wrong, please try again later");
					setError(message);
					handleLoading(false, message);
				});
		} else {
			setError("Missing form Id or view Id...");
			return;
		}

		// eslint-disable-next-line
	}, [dataIds?.token, dataIds?.userId]);

	if (error) {
		return <SmarthopErrorView message={error} />;
	}
	if (viewId) {
		const view = renderView(dispatch, viewId, dataIds, (inProgress) => {
			if (inProgress) {
				// Saving status when view triggered loading start, that means view will be contorlling
				// that process instead of showing fake loading indication for native view
				loadingHandedByViewRef.current = true;
			}
		});
		if (!view) return <SmarthopErrorView message="Invalid view Id..." />;
		return view;
	} else if (formId) {
		const form = buildForm(formId, { user });
		return (
			<div className="flex w-full items-center justify-center mt-10 mb-28 px-4">
				<SmarthopForm
					nativeMobile={true}
					data={data}
					form={form}
					dataIds={dataIds}
					mode={mode}
					onLoading={handleLoading}
					onProcessing={handleProcessing}
					onDone={handleDone}
				/>
			</div>
		);
	} else {
		handleLoading(false);
		return <SmarthopErrorView message="Missing form or view Id..." />;
	}
}

export default NativeForm;
