import { Typography } from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import { isEnabled } from "app/services/featureStorageService";
import { formatCurrency } from "app/main/utils/tableUtils";

const FIRST_SERIE = 0;
const SECOND_SERIE = 1;
const THIRD_SERIE = 2;

const POSTED_PRICE = 0;
const BID_PRICE = 0;
const MIN_PRICE = 0;
const MAX_PRICE = 1;

const POSTED_PRICE_ICON = "../../../../../assets/images/icon_posted_rate.png";
const BID_EST_PRICE_ICON = "../../../../../assets/images/badges/best_icon.png";

const DEFAULT_OPTIONS = {
	chart: {
		toolbar: {
			show: false,
		},
		zoom: {
			enabled: false,
		},
	},
	grid: {
		show: false,
	},
	legend: {
		show: false,
	},
};

const DEFAULT_XAXIS_OPTIONS = {
	type: "numeric",
	tickAmount: 2,
	tooltip: {
		enabled: false,
	},
	labels: {
		formatter: function (value, timestamp, opts) {
			return "$" + value.toFixed(0);
		},
		offsetX: 1,
		style: {
			colors: "#FFF",
			fontSize: "10px",
		},
	},
};

const DEFAULT_YAXIS_OPTIONS = {
	show: false,
	axisBorder: {
		show: false,
	},
	axisTicks: {
		show: false,
	},
};

function EstimatePriceTooltip({ load, width = "200px", className = "" }) {
	const estimateFwBarColor =
		load?.min_fw_price >= load?.min_price && load?.max_fw_price <= load?.max_price ? "#0096FF" : "#c42121";

	const series = [
		{
			type: "scatter",
			data: [
				{
					x: load.est_price ?? 0,
					y: 1,
				},
			],
		},
		{
			type: "scatter",
			data: [
				{
					x: load.price ?? 0,
					y: 1.5,
				},
			],
		},
		{
			name: "Line",
			type: "line",
			data: [
				{
					x: load.min_price,
					y: 1,
				},
				{
					x: load.max_price,
					y: 1,
				},
			],
		},
	];

	const options = {
		...DEFAULT_OPTIONS,
		markers: {
			size: 7,
			strokeWidth: 0,
			discrete: [
				{
					seriesIndex: THIRD_SERIE,
					dataPointIndex: MIN_PRICE,
					fillColor: "#fff",
					size: 3,
				},
				{
					seriesIndex: THIRD_SERIE,
					dataPointIndex: MAX_PRICE,
					fillColor: "#fff",
					size: 3,
				},
				{
					seriesIndex: SECOND_SERIE,
					dataPointIndex: POSTED_PRICE,
					size: 0,
				},
			],
			hover: {
				size: undefined,
				sizeOffset: 1,
			},
		},
		fill: {
			type: ["image", "image", "solid"],
			colors: ["", "", "#FFF"],
			opacity: 1,
			strokeWidth: 0,
			image: {
				src: [BID_EST_PRICE_ICON, POSTED_PRICE_ICON, ""],
				width: 15,
				height: 15,
			},
		},
		tooltip: {
			shared: false,
			intersect: true,
			custom: function ({ series, seriesIndex, dataPointIndex, w }) {
				return (
					'<div class="arrow_box px-7 py-7"><span class="text-black text-12 ">' +
					(seriesIndex === SECOND_SERIE && dataPointIndex === POSTED_PRICE
						? `Current: $${load.price}`
						: seriesIndex === THIRD_SERIE && dataPointIndex === MIN_PRICE
						? `Min: $${load.min_price}`
						: seriesIndex === THIRD_SERIE && dataPointIndex === MAX_PRICE
						? `Max: $${load.max_price}`
						: seriesIndex === FIRST_SERIE && dataPointIndex === BID_PRICE
						? `Ask for: $${load.est_price}`
						: "") +
					"</span></div>"
				);
			},
		},
		stroke: {
			width: [0, 0, 15],
			colors: ["", "", "#50C878"],
		},
		xaxis: {
			...DEFAULT_XAXIS_OPTIONS,
			min: load.price > 0 ? Math.min(load.min_price, load.price) : load.min_price,
			max: Math.max(load.max_price, load.price),
		},
		yaxis: {
			tickAmount: 2,
			...DEFAULT_YAXIS_OPTIONS,
		},
	};

	//Data and options for FW estimates graph (under QA GK)

	const isFwEstimateEnabled = isEnabled("QA_FW_ESTIMATE_PRICE_GRAPH");

	const seriesFw = [
		{
			name: "Line",
			type: "line",
			data: [
				{
					x: load.min_fw_price,
					y: 1,
				},
				{
					x: load.max_fw_price,
					y: 1,
				},
			],
		},
	];

	const optionsFw = {
		...DEFAULT_OPTIONS,
		markers: {
			size: 7,
			strokeWidth: 0,
			discrete: [
				{
					seriesIndex: FIRST_SERIE,
					dataPointIndex: MIN_PRICE,
					shape: "square",
					fillColor: "#fff",
					size: 3,
				},
				{
					seriesIndex: FIRST_SERIE,
					dataPointIndex: MAX_PRICE,
					shape: "square",
					fillColor: "#fff",
					strokeColor: "#FFF",
					size: 3,
				},
			],
			hover: {
				size: undefined,
				sizeOffset: 2,
			},
		},
		tooltip: {
			shared: false,
			intersect: true,
			custom: function ({ series, seriesIndex, dataPointIndex, w }) {
				return (
					'<div class="arrow_box px-7 py-7"><span class="text-black text-12 ">' +
					(seriesIndex === FIRST_SERIE && dataPointIndex === MIN_PRICE
						? `Min Fw: $${load.min_fw_price}`
						: seriesIndex === FIRST_SERIE && dataPointIndex === MAX_PRICE
						? `Max Fw: $${load.max_fw_price}`
						: "") +
					"</span></div>"
				);
			},
		},
		stroke: {
			width: [15],
			colors: [estimateFwBarColor],
		},
		xaxis: {
			...DEFAULT_XAXIS_OPTIONS,
			min: load.min_fw_price,
			max: load.max_fw_price,
		},
		yaxis: {
			tickAmount: 1,
			...DEFAULT_YAXIS_OPTIONS,
		},
	};

	const estimateAvailable = !!load?.min_price && !!load?.max_price;

	const textEstimate =
		load?.est_price && load.price && load.price > load?.est_price
			? `${formatCurrency(load.price)} (${formatCurrency(load.price - load?.est_price)} above estimate)`
			: `${formatCurrency(load?.est_price)}`;

	return (
		<div className={`flex flex-col p-3 ${className}`} style={{ width }}>
			{estimateAvailable && (
				<>
					<div className="flex flex-row justify-center items-center my-1">
						<Typography className="text-12 ">Estimated Price Range</Typography>
					</div>
					<ReactApexChart options={options} series={series} type={"line"} height={"73"} />
					{isFwEstimateEnabled && <ReactApexChart options={optionsFw} series={seriesFw} type={"line"} height={"60"} />}
					{load?.est_price && (
						<>
							<hr className="bg-white my-8" />
							<div className="flex flex-row justify-center text-center items-center my-1">
								<Typography className="text-13 ">
									{load.price > load?.est_price ? "Ask for " : "We estimate you can ask for "}
									<span className={"text-green-300 font-semibold"}>{textEstimate}</span>
								</Typography>
							</div>
						</>
					)}
				</>
			)}
			{!estimateAvailable && (
				<>
					<div className="flex flex-row justify-center text-center items-center my-1">
						<Typography className="text-12 ">Why the load don't have an estimate?</Typography>
					</div>
					<hr className="bg-white my-8" />
					<div className="flex flex-row justify-center  items-center my-1">
						<Typography className="text-11 ">We don't have confident information to estimate the rate</Typography>
					</div>
				</>
			)}
		</div>
	);
}

export default EstimatePriceTooltip;
