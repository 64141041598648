import moment from "moment";
import { useEffect, useMemo, useCallback, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Icon, IconButton, Link, Typography } from "@material-ui/core";
import { openLoadedFormDialog, closeAllDialogs } from "app/store/tools/formDialogSlice";
import ReactApexChart from "react-apexcharts";

import { useSnackbar } from "notistack";
import { showErrorSnackbar, showSuccessSnackbar } from "app/main/utils/snackbarUtil";

import { getCarrierId } from "app/services/LoginService";
import { updateTruck } from "app/services/truckServices";
import { getTruckGoals } from "app/services/goalsServices";
import { formatCurrency } from "app/main/utils/tableUtils";
import { DisplaySection, renderDataItem } from "app/main/utils/uiUtils";
import { hasRequiredGateKeepers } from "app/main/utils/rolesUtils";
import { convertPlanAndTruckToSearch, saveSearchesSessionStorage } from "app/main/searchV3/utils/searchUtils";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import {
	PRIMARY_DRIVER_AUTOCOMPLETE,
	CARRIER_TRAILER_AUTOCOMPLETE,
	SECONDARY_DRIVER_AUTOCOMPLETE,
	COST_STRUCTURE_AUTOCOMPLETE,
	GOAL_AUTOCOMPLETE_TRUCK,
} from "../cost-structure/calculator/CostStructureCalculatorSettings";

import CostStructureView from "../cost-structure/CostStructureView";
import WidgetsBoard from "app/main/widgets/WidgetsBoard";
import CircularProgressWithIndicator from "app/main/utils/circular-progress/CircularProgressWithIndicator";
import LoadSearchSummaryCarrierView from "app/main/search/result/LoadSearchSummaryCarrierView";
import TruckTimeline from "./TruckTimeline";

import { carrierTruckRemindersList } from "@smarthop/list";
import SmarthopDialogViewContainer from "@smarthop/views/SmarthopDialogViewContainer";
import SmarthopDialogViewLoadingStub from "@smarthop/views/SmarthopDialogViewLoadingStub";
import SmarthopFormView from "@smarthop/form/SmarthopFormView";
import { getTruckInfo } from "app/services/truckServices";
import { truckFormConfig } from "@smarthop/form/configs/truckForm";
import ProfitWarnings from "../cost-structure/calculator/ProfitWarnings";
import { isEnabled } from "app/services/featureStorageService";

const formatCellGoalsLabel = (progress, firstRow) => {
	let description = (
		<>
			<Typography variant="body1" component="div" className="whitespace-nowrap ml-2 text-12">
				{firstRow}
			</Typography>
		</>
	);

	if (isNaN(progress)) return description;

	return (
		<CircularProgressWithIndicator
			reverse
			thickness={2}
			textClass="text-11"
			progress={progress}
			description={description}
			size={40}
			mr={0}
			ml={0}
		/>
	);
};

const getTruckData = (truck) => {
	const data = { ...truck };
	delete data.createdAt;
	delete data.updatedAt;
	delete data.cost_structure;
	delete data.goals;
	delete data.costStructure__view;
	delete data.goals__view;
	return data;
};

const TIMELINE_COLOR_EMPTY = "bg-primary";
const TIMELINE_COLORS = ["bg-green", "bg-blue", "bg-purple-600", "bg-brown", "bg-pink-800"];
const TIMELINE_COLOR_PLAN = "bg-secondary";
const COLORS_MAPPING = {
	"bg-primary": "#062246",
	"bg-green": "#4CAF50",
	"bg-blue": "#2196F3",
	"bg-purple-600": "#9C27B0",
	"bg-brown": "#795548",
	"bg-pink-800": "#AD1457",
	"bg-secondary": "#E7683D",
};

const getTruckTimeLine = (trips, plan) => {
	const schedule = [];
	const outdated = [];
	const routes = [];

	trips?.forEach((trip, tIndex) => {
		if (moment().diff(moment(trip.data.delivery_date), "days") > 1) {
			outdated.push(trip);
			return;
		}

		const tripColor = TIMELINE_COLORS[(tIndex - outdated.length) % TIMELINE_COLORS.length];
		const { carrier, truck, _id } = trip;
		// Sanitize trip data
		const data = { ...trip?.data };
		delete data.all_locations;
		// Saving locations
		let skipEmpty = false;
		let tripLocations = [];

		const tripOverlapWarning = trip?.data?.warning;
		trip?.data?.all_locations?.forEach((location, index) => {
			// Need to add only first empty location
			if (schedule.length === 0 || location.type !== "EMPTY") {
				tripLocations.push(location);
				// Skipping index of empty if nessesary
				const pos = skipEmpty ? index - 1 : index;
				const classes = { dot: location.type === "EMPTY" ? TIMELINE_COLOR_EMPTY : tripColor };

				// Checking if current stop is overlapping with another trip (another stop in a different trip)
				const isOverlapping = tripOverlapWarning?.affectedCurrentStops?.includes(index);
				let overlappingWarning;
				if (isOverlapping) {
					overlappingWarning = tripOverlapWarning.description;
				}

				schedule.push({
					type: "TRIP",
					classes,
					carrier,
					truck,
					trip: _id,
					data,
					pos,
					location,
					isOverlapping,
					overlappingWarning,
				});
			} else {
				skipEmpty = true;
			}
		});
		routes.push({ type: "TRIP", routeId: _id, locations: tripLocations, color: COLORS_MAPPING[tripColor] });
	});

	if (plan) {
		const classes = { dot: TIMELINE_COLOR_PLAN };
		// Adding plan item
		const { carrier, truck, _id } = plan;
		const outdated = moment().diff(moment(plan.current_available_date), "days") > 1;
		const location = {
			type: "PICKUP",
			coordinates: plan.current_location_coords,
			location_date: plan.current_available_date,
			location: plan?.current_location,
		};

		// Saving destimation as prefs, may be need to add as separte timeline stop
		const metadata = {
			outdated,
			destination: plan?.destinationStates__view?.map((l) => l.description).join(", ") ?? plan?.current_destination,
		};
		routes.push({ type: "PLAN", routeId: _id, locations: [location], color: COLORS_MAPPING[TIMELINE_COLOR_PLAN] });
		schedule.push({ type: "PLAN", classes, carrier, truck, plan: _id, location, pos: 0, metadata });
	}

	return { schedule, outdated, routes };
};

const MODEL_CONFIG = {
	"Primary Driver": {
		label: "Primary Driver",
		autocomplete: () => {
			return {
				...PRIMARY_DRIVER_AUTOCOMPLETE,
				field: {
					...PRIMARY_DRIVER_AUTOCOMPLETE.field,
					noErrorMessage: true,
					variant: "row",
					classes: { container: "border-b-0 mt-0" },
				},
			};
		},
		getData: (data) => {
			return {
				...data.truck,
				driver: data?.driver?._id,
				driver__view: {
					label: data?.driver?.name ? data?.driver?.name : data?.driver?._id ? "(No Name)" : "None",
					value: data?.driver?._id ?? null,
				},
			};
		},
		sendData: (data, model, key) => {
			return { ...getTruckData(data.truck), driver: model[key], truck_trailer: data?.truck?.trailerId };
		},
		changed: (data, val) => {
			return val !== data?.driver?._id;
		},
	},
	"Secondary Driver": {
		label: "Secondary Driver",
		autocomplete: () => {
			return {
				...SECONDARY_DRIVER_AUTOCOMPLETE,
				field: { ...SECONDARY_DRIVER_AUTOCOMPLETE.field, variant: "row", classes: { container: "border-b-0  mt-0" } },
			};
		},
		getData: (data) => {
			return {
				...data.truck,
				secondaryDriver: data?.driver_secondary?._id,
				secondaryDriver__view: {
					label: data?.driver_secondary?.name ?? data?.driver_secondary?._id ?? "None",
					value: data?.driver_secondary?._id ?? null,
				},
			};
		},
		sendData: (data, model, key) => {
			return { ...getTruckData(data.truck), driver_secondary: model[key], truck_trailer: data?.truck?.trailerId };
		},
		changed: (data, val) => {
			return val !== data?.driver_secondary?._id;
		},
	},
	Truck: {
		label: "Truck",
		getViewLabel: (data) => {
			return "#" + data?.truck?.truckid;
		},
	},
	Trailer: {
		label: "Trailer",
		autocomplete: () => {
			return {
				...CARRIER_TRAILER_AUTOCOMPLETE,
				required: false,
				field: { ...CARRIER_TRAILER_AUTOCOMPLETE.field, variant: "row", classes: { container: "border-b-0 mt-0" } },
			};
		},
		getData: (data) => {
			if (!data?.truck?.trailerId) {
				return {
					...data.truck,
					truck_trailer__view: { value: "PO", label: "Power Only" },
				};
			}
			return {
				...data.truck,
			};
		},
		sendData: (data, model, key) => {
			return { ...getTruckData(data.truck), ...model?.truck_trailer__view?.metadata, truck_trailer: model[key] };
		},
		changed: (data, val) => {
			if (val === "PO" || val === null) {
				return !!data?.trailer?._id;
			}

			return val !== data?.trailer?._id;
		},
	},
	"Cost Structure": {
		label: "Cost Structure",
		autocomplete: (carrierId, truckId, user, data) => {
			const config = COST_STRUCTURE_AUTOCOMPLETE(carrierId, truckId, user, data?.truck?.equipment);
			return {
				...config,
				field: { ...config.field, variant: "row", classes: { container: "border-b-0 mt-0" } },
			};
		},
		getData: (data) => {
			return {
				...data.truck,
				costStructure: data?.costStructure?._id,
				costStructure__view: {
					label: data?.costStructure?.name_cost_structure
						? `${data?.costStructure?.name_cost_structure} - ${data?.trailer?.equipment ?? "Power Only"}`
						: "None",
					value: data?.costStructure?._id ?? null,
				},
			};
		},
		sendData: (data, model, key) => {
			return {
				...getTruckData(data.truck),
				cost_structure: model[key],
				truck_trailer: data?.truck?.trailerId,
				cost_structure__view: model.costStructure__view,
			};
		},
		changed: (data, val) => {
			return val && val !== data?.costStructure?._id;
		},
	},
	Goal: {
		label: "Goal",
		autocomplete: () => {
			return {
				...GOAL_AUTOCOMPLETE_TRUCK,
				field: { ...GOAL_AUTOCOMPLETE_TRUCK.field, variant: "row", classes: { container: "border-b-0 mt-0" } },
			};
		},
		getData: (data) => {
			return {
				...data.truck,
				goals: data?.goal?._id,
				goals__view: {
					label: data?.goal?._id ? (data?.goal?.name ?? "No Name") + " - " + (data?.goal?.equipment ?? "PO") : "None",
					value: data?.goal?._id ?? null,
				},
			};
		},
		sendData: (data, model, key) => {
			return {
				...getTruckData(data.truck),
				truck_trailer: data?.truck?.trailerId,
				goals: model[key],
				goals__view: model.goals__view,
			};
		},
		changed: (data, val) => {
			return val && val !== data?.goal?._id;
		},
	},
};

const SubSection = ({ title, children }) => {
	return (
		<div className="flex flex-row w-1/2 items-center bg-grey-50 border-1 p-6 rounded-lg place-content-between">
			<div className="text-13 text-primary-400 font-bold leading-4">{title}</div>
			<div className="">{children}</div>
		</div>
	);
};

const TruckDetailsView = ({ dataIds, setTitle, nativeMobile }) => {
	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const params = useParams();
	const history = useHistory();

	const dialogRef = useRef(null);
	const truckId = useMemo(() => params?.truckId ?? dataIds?.truckId, [dataIds, params]);
	const carrierId = useMemo(() => params?.carrierId ?? dataIds?.carrierId ?? getCarrierId(), [dataIds, params]);

	const [data, setData] = useState(null);
	const [initialized, setInitialized] = useState(false);
	const [loading, setLoading] = useState(true);
	const [loadingError, setLoadingError] = useState(null);
	const [loadingSection, setLoadingSection] = useState(null);
	const [updatedSection, setUpdatedSection] = useState(null);
	const [goalData, setGoalData] = useState(null);

	const dataRevision = useSelector(({ tools }) => tools.revision["dataRevision"] ?? 0);
	const tripsRevision = useSelector(({ tools }) => tools.revision["tripsRevision"] ?? 0);
	const profileRevision = useSelector(({ tools }) => tools.revision["profileRevision"] ?? 0);
	const hasSubaccounts = useSelector(({ auth }) => auth.account.subAccount?.hasSubaccounts);

	const user = useSelector(({ auth }) => auth.user);
	const roles = user?.rolesLabels;
	const isAdmin = roles.includes("administrator");

	const formConfiguration = useMemo(() => truckFormConfig(false, { mode: "VIEW" }), []);
	const viewerCanAccessReminders = useMemo(() => !["CARRIER_DRIVER", "CARRIER_GENERIC"].includes(user.role), [user]);
	const viewerCanEdit = useMemo(
		() =>
			!nativeMobile &&
			(user.roleType === "INTERNAL" ||
				(user.roleType === "EXTERNAL" && ["CARRIER_OWNER", "CARRIER_MANAGER"].includes(user.role))),
		[user, nativeMobile]
	);

	const hasSearchPermission = ["CARRIER_DRIVER", "CARRIER_MANAGER", "CARRIER_GENERIC"].includes(user.role)
		? hasRequiredGateKeepers(user, { permission_search_on: true })
		: true;

	const defaultProps = useMemo(() => {
		return {
			mode: "VIEW",
			dataIds: { carrierId, truckId },
			overrides: {
				group: { component: { variant: "vertical", classes: null } },
				ALL_EDITABLE: { component: { preview: { variant: "row" } } },
			},
		};
	}, [carrierId, truckId]);

	const { schedule, outdated, routes } = useMemo(() => {
		return getTruckTimeLine(data?.trips, data?.plan);
	}, [data?.trips, data?.plan]);

	const onClickPlan = (plan) => {
		dispatch(
			openLoadedFormDialog({
				viewId: "TRUCK_PLAN_VIEW",
				dataIds: { planId: plan?.plan ?? plan?._id, carrierId: data?.carrier?._id, truckId: data?.truck?._id },
			})
		);
	};

	const onEdit = useCallback(
		(modelType) => {
			let config;

			if (modelType === "Plan") {
				config = {
					viewId: "TRUCK_PLAN",
					mode: "EDIT",
					dataIds: {
						carrierId: carrierId,
						truckId: truckId,
					},
				};
			} else if (modelType === "Secondary Driver" || modelType === "Primary Driver") {
				const path = modelType === "Secondary Driver" ? "driver_secondary" : "driver";
				config = {
					viewId: "CARRIER_USER_EDIT_VIEW",
					mode: "EDIT",
					dataIds: {
						origin: "DRIVER",
						driverId: data?.[path]?._id,
						carrierId: carrierId,
					},
				};
			} else if (modelType === "Truck") {
				config = {
					viewId: "TRUCK_VIEW",
					mode: "EDIT",
					dataIds: {
						carrierId: carrierId,
						truckId: truckId,
						id: truckId,
						mode: "EDIT",
					},
				};
			} else if (modelType === "Trailer") {
				config = {
					viewId: "TRAILER_VIEW",
					mode: "EDIT",
					dataIds: {
						id: data?.trailer?._id,
						carrierId: carrierId,
						truckId: truckId,
						mode: "EDIT",
					},
				};
			} else if (modelType === "Goal") {
				config = {
					formId: "CARRIER_GOALS_FORM",
					mode: "EDIT",
					dataIds: {
						goalId: data?.goal?._id,
						mode: "EDIT",
					},
				};
			} else if (modelType === "Cost Structure") {
				config = {
					viewId: "COST_STRUCTURE",
					mode: "EDIT",
					dataIds: {
						id: data?.costStructure?._id,
						mode: "EDIT",
						openedFromView: true,
					},
				};
			}

			dispatch(openLoadedFormDialog(config));
		},
		[truckId, carrierId, dispatch, data]
	);

	useEffect(() => {
		if (!updatedSection) return;
		let timeout = setTimeout(() => {
			setUpdatedSection(null);
		}, 3000);
		return () => {
			clearTimeout(timeout);
		};
	}, [updatedSection]);

	// load data
	useEffect(() => {
		setLoading(true);
		(async () => {
			try {
				const [truckData, goalData] = await Promise.all([
					getTruckInfo(carrierId, truckId),
					getTruckGoals(carrierId, truckId),
				]);
				setGoalData(goalData);
				setData(truckData);
				setInitialized(true);
			} catch (err) {
				setLoadingError(err?.errors?.[0]?.message ?? err.message ?? "Oops, something went wrong...");
			}
			setLoading(false);
		})();
	}, [dataRevision, profileRevision, tripsRevision, carrierId, truckId]);

	// update dialog title
	useEffect(() => {
		if (data) {
			setTitle?.("Truck Details", `${data?.truck?.subtype ?? data?.truck?.equipment} - #${data?.truck?.truckid}`);
		}
		// eslint-disable-next-line
	}, [data, profileRevision, dataRevision, tripsRevision]);

	const getTitle = useCallback(
		(modelType, hasEditPermission, hasRecord, loadingType, updatedSection, sectionHeader) => {
			if (!hasEditPermission) return modelType;
			const config = MODEL_CONFIG[modelType];
			const updating = loadingType === modelType;
			const updated = updatedSection === modelType;
			return (
				<div className={`w-full flex flex-row items-center`}>
					<div className="flex w-4/12">
						<Typography
							className={
								"flex w-full whitespace-nowrap text-back " +
								(sectionHeader ? " font-semibold text-14 " : " text-12 ") +
								(updating ? " opacity-40 " : "")
							}
						>
							{config?.label}
							{updated ? (
								<Icon className={"ml-4 mt-2 text-green animate-pulse" + (sectionHeader ? " text-18  " : " text-16 ")}>
									check_circle
								</Icon>
							) : null}
						</Typography>
					</div>
					<div className={"flex flex-row w-8/12 justify-end " + (updating ? " pointer-events-none opacity-40 " : "")}>
						{config?.autocomplete ? (
							<SmarthopFormView
								key={modelType}
								mode={"EDIT"}
								content={{
									form: { noErrorMessage: true },
									items: [config.autocomplete(carrierId, truckId, user, data)],
								}}
								data={config.getData(data)}
								noInitValidation={true}
								dataIds={{ carrierId, truckId }}
								trackChangedFields={["ALL"]}
								onChangeCommitted={(model, key) => {
									if (config.changed(data, model[key])) {
										setLoadingSection(modelType);
										updateTruck(carrierId, truckId, config.sendData(data, model, key))
											.then(() => {
												getTruckInfo(carrierId, truckId).then((data) => {
													setData(data);
													setLoadingSection(null);
													showSuccessSnackbar(snackbar, `${modelType} Updated`);
													dispatch(incrementDataRevision({ event: "profileRevision" }));
													setUpdatedSection(modelType);
												});
											})
											.catch((error) => {
												console.log(error?.errors?.[0]?.message);
												showErrorSnackbar(snackbar, error);
												setLoadingSection(null);
											});
									}
								}}
							/>
						) : !sectionHeader && config?.getViewLabel ? (
							<Typography className={"flex w-full whitespace-nowrap mt-4 mr-8 text-13"}>
								{config?.getViewLabel(data)}
							</Typography>
						) : null}
						<div className="">
							<IconButton
								aria-label="edit"
								size="small"
								color="inherit"
								className="p-4"
								disabled={!hasRecord || updating}
								onClick={() => onEdit(modelType)}
								title={`Edit ${modelType}`}
							>
								<Icon className={"text-20 font-500"}>edit</Icon>
							</IconButton>
						</div>
					</div>
				</div>
			);
		},
		[onEdit, carrierId, data, truckId, snackbar, user, dispatch]
	);

	const costDataids = useMemo(
		() => ({ carrierId: carrierId, id: data?.costStructure?._id }),
		[data?.costStructure?._id, carrierId]
	);

	const chartSettings = useMemo(() => {
		const calculatePercentage = (settings, type) => {
			return settings?.payment_type === "PAY_BY_PERCENTAGE" ? settings?.gross : 0;
		};

		const tripRate = 100;
		const factoringPercentage = data?.costStructure?.percentageCost;
		const driverSettings = data?.driver?.payroll_settings?.settings;
		const driverPercentage = calculatePercentage(driverSettings);
		const secondaryDriverSettings = data?.driver_secondary?.payroll_settings?.settings;
		const secondaryDriverPercentage = calculatePercentage(secondaryDriverSettings);
		const investorSettings = data?.investor?.payroll_settings?.settings;
		const carrierPercentage =
			investorSettings?.distribution_priority === "CARRIER_FEE" ? investorSettings?.distribution_priority_value : 0;
		const investorPercentage =
			investorSettings?.distribution_priority === "INVESTOR_SHARE"
				? investorSettings?.distribution_priority_value
				: null;
		const profitPercentage =
			tripRate -
			factoringPercentage -
			driverPercentage -
			secondaryDriverPercentage -
			carrierPercentage -
			investorPercentage;

		const chartData = {
			factoring: { label: "GMV percentage cost", serie: factoringPercentage },
			driver: { label: "Driver %", serie: driverPercentage },
			driverSecondary: { label: "Driver Secondary %", serie: secondaryDriverPercentage },
			investor: { label: "Investor %", serie: investorPercentage },
			carrier: { label: "Carrier %", serie: carrierPercentage },
			profit: { label: "Profit %", serie: profitPercentage },
		};

		const totalSeriesSumExceptProfit = Object.keys(chartData)
			.filter((key) => key !== "profit")
			.reduce((acc, key) => {
				return acc + (chartData[key].serie || 0);
			}, 0);

		const error =
			profitPercentage < 0 && totalSeriesSumExceptProfit > 100
				? `The sum of the percentages must add up to 100%. Currently, it adds up to ${totalSeriesSumExceptProfit}%`
				: null;

		const labels = Object.values(chartData).map((item) => item.label);
		const series = Object.values(chartData)
			.map((item) => item.serie)
			.filter((val) => val !== null);

		return { series, labels, error };
	}, [data]);

	if ((loading && !initialized) || loadingError) {
		return <SmarthopDialogViewLoadingStub nativeMobile={nativeMobile} loading={loading} error={loadingError} />;
	}

	return (
		<SmarthopDialogViewContainer
			ref={dialogRef}
			nativeMobile={nativeMobile}
			sideComponents={[
				{
					key: "MAP",
					type: "MAP",
					hideOnMobile: true,
					tabKeys: ["OVERVIEW"],
					params: {
						routes,
						truckLocation: data?.truck?.lastLocation?.long ? data?.truck?.lastLocation : false,
						zoom: data?.truck?.lastLocation?.long ? 10 : 3,
						truck: {
							label: data?.truck?.truck__view?.label,
							lastLocation: data?.truck?.lastLocation,
							load_id: data?.trips?.[0]?.data?.load_id,
						},
					},
				},
			]}
			tabComponents={[
				{
					key: "OVERVIEW",
					title: "Overview",
					component: (
						<div className="flex flex-col md:flex-row flex-auto md:space-x-24">
							<div className="flex flex-col w-full">
								<DisplaySection classes={{ root: "mx-0 mt-0 mb-20" }} title="Analytics">
									<ProfitWarnings data={data} />
									<Typography component={"div"} className="hidden md:block text-grey text-11 mb-8">
										Current stats are based on this truck performance during the last 30 days, see more detailed
										information {<Link onClick={() => dialogRef.current?.setTab("ANLYTICS")}>here</Link>}
									</Typography>
									<Typography component={"div"} className="md:hidden flex text-grey text-11 mb-8">
										Current stats are based on this truck performance during the last 30 days
									</Typography>
									<div className="flex flex-col w-full space-y-8">
										{(!data.costStructure?._id || !data.goal?._id) && (
											<div className="flex flex-col bg-blue-50 border-1 border-blue rounded-lg p-10 mb-4">
												<Typography component={"div"} className="text-blue-700 text-12">
													<Icon className="text-18 -mb-6 text-blue-800 mr-1">info</Icon> It seems that you have not
													configured goals or/and costs for this truck, update now{" "}
													<Link onClick={() => dialogRef.current?.setTab("COSTS")}>here</Link> to properly measure truck
													profitablity
												</Typography>
											</div>
										)}
										<div className="flex flex-col md:flex-row w-full space-x-0 md:space-x-8 space-y-8 md:space-y-0">
											<SubSection title="RPM">
												{formatCellGoalsLabel(
													goalData?.rpmProgress?.goal
														? (goalData?.rpmProgress?.current / goalData?.rpmProgress?.goal) * 100
														: 0,
													formatCurrency(goalData?.rpmProgress?.current)
												)}
											</SubSection>
											<SubSection title={"Revenue"}>
												{formatCellGoalsLabel(
													goalData?.revenueProgress?.goal
														? (goalData?.revenueProgress?.current / goalData?.revenueProgress?.goal) * 100
														: 0,
													formatCurrency(goalData?.revenueProgress?.current)
												)}
											</SubSection>
										</div>
										<div className="flex flex-col md:flex-row w-full space-x-0 md:space-x-8 space-y-8 md:space-y-0">
											<SubSection title="Loaded Days">
												{formatCellGoalsLabel(
													goalData?.revenueProgress?.goal
														? (goalData?.loadedDaysProgress?.current / goalData?.loadedDaysProgress?.goal) * 100
														: 0,
													goalData?.revenueProgress?.goal
														? `${goalData?.loadedDaysProgress?.current} / ${goalData?.loadedDaysProgress?.goal}`
														: `${goalData?.loadedDaysProgress?.current}`
												)}
											</SubSection>
											<div className="flex w-full flex-row items-center p-4 rounded-lg"></div>
										</div>
									</div>
								</DisplaySection>
								<DisplaySection classes={{ root: "mb-0 mx-0 mt-0 mb-20", divider: "mb-0" }} title="Configuration">
									{viewerCanEdit ? (
										<>
											{(user.roleType === "INTERNAL" || hasSubaccounts) && (
												<LoadSearchSummaryCarrierView
													carrier={{ id: data?.carrier?._id, name: data?.carrier?.carrier_company_name }}
												/>
											)}
											<Typography component={"div"} className="text-grey text-11 mb-4">
												Quickly adjust configuration of your truck, you can always access full truck configuration{" "}
												{<Link onClick={() => dialogRef?.current?.setTab("CONFIG")}>here</Link>}
											</Typography>

											<DisplaySection
												title={getTitle("Truck", viewerCanEdit, true, loadingSection, updatedSection)}
												classes={{ root: "mt-0 mb-0 mx-0" }}
											/>
											<DisplaySection
												title={getTitle("Trailer", viewerCanEdit, !!data?.trailer?._id, loadingSection, updatedSection)}
												classes={{ root: "mt-0 mb-0 mx-0" }}
											/>
											<DisplaySection
												title={getTitle(
													"Primary Driver",
													viewerCanEdit,
													!!data?.driver?._id,
													loadingSection,
													updatedSection
												)}
												classes={{ root: "mt-0 mb-0 mx-0" }}
											/>
											<DisplaySection
												title={getTitle(
													"Secondary Driver",
													viewerCanEdit,
													!!data?.driver_secondary?._id,
													loadingSection,
													updatedSection
												)}
												classes={{ root: "mt-0 mb-0 mx-0" }}
											/>
										</>
									) : (
										<LoadSearchSummaryCarrierView
											carrier={{
												id: data?.carrier?._id,
												name: data?.carrier?.carrier_company_name,
												hidden: user.roleType !== "INTERNAL",
											}}
											truck={{
												id: data?.carrier?.carrier_trucks?._id,
												name: data?.carrier?.carrier_trucks?.truckid,
												textOnly: true,
											}}
											trailer={{
												id: data?.carrier?.carrier_trailers?._id,
												name: data?.carrier?.carrier_trailers?.trailerid,
											}}
											primaryDriver={{
												id: data?.carrier?.carrier_drivers?._id,
												name: data?.carrier?.carrier_drivers?.name,
											}}
											secondaryDriver={{
												id: data?.carrier?.carrier_drivers_secondary?._id,
												name: data?.carrier?.carrier_drivers_secondary?.name,
											}}
										/>
									)}
								</DisplaySection>
							</div>
							<div className="flex flex-col w-full mt-0 mb-20">
								<DisplaySection classes={{ root: "mb-0 mx-0 mt-0" }} title={"Schedule"}>
									<Typography component={"div"} className="text-grey text-11 mb-4">
										Make sure your truck plan is always up-to-date to take advantage of 'Load Scout', 'External Posting'
										and more, edit your current plan {<Link onClick={() => onClickPlan(data?.plan)}>here</Link>}
									</Typography>
									{!data.plan && (
										<div className="flex flex-row bg-blue-50 border-1 border-blue rounded-lg p-10 mt-2 mb-4">
											<Typography component={"div"} className="text-blue-700 text-12">
												<Icon className="text-18 -mb-5 text-blue-800 mr-1">info</Icon> It seems that you have not
												created any plans for this truck, create it now{" "}
												<Link onClick={() => onClickPlan(null)}>here</Link> to simplify dispatching process and take
												advantage of automation and market analytics
											</Typography>
										</div>
									)}
									<TruckTimeline schedule={schedule} outdated={outdated} />
								</DisplaySection>
							</div>
						</div>
					),
				},
				{
					key: "CONFIG",
					title: "Configuration",
					component: (
						<div className="flex flex-col md:flex-row w-full md:space-x-40">
							<div className="flex flex-col w-full">
								<DisplaySection
									title={getTitle("Truck", viewerCanEdit, true, loadingSection, updatedSection, true)}
									classes={{ root: "mx-0 mt-0 mb-24", divider: "mb-0" }}
								>
									<SmarthopFormView content={formConfiguration.info} data={data.truck} {...defaultProps} />
								</DisplaySection>
								<DisplaySection
									title={getTitle("Trailer", viewerCanEdit, !!data?.trailer?._id, loadingSection, updatedSection, true)}
									classes={{ root: "mx-0 mb-24", divider: "mb-0" }}
								>
									<div className={`relative w-full flex`}>
										<div className={`flex w-full ${loadingSection === "Trailer" ? "opacity-40" : ""} `}>
											{data?.trailer?._id && (
												<SmarthopFormView content={formConfiguration.trailer} data={data.truck} {...defaultProps} />
											)}
										</div>
									</div>
								</DisplaySection>
							</div>
							<div className="flex flex-col w-full">
								<DisplaySection
									title={getTitle(
										"Primary Driver",
										viewerCanEdit,
										!!data?.driver?._id,
										loadingSection,
										updatedSection,
										true
									)}
									classes={{ root: "mx-0 mt-0 mt-0 mb-24", divider: "mb-0" }}
								>
									<div className={`relative w-full flex`}>
										<div className={`flex w-full ${loadingSection === "Primary Driver" ? "opacity-40" : ""} `}>
											{data?.driver?._id && (
												<SmarthopFormView content={formConfiguration.driver} data={data.driver} {...defaultProps} />
											)}
										</div>
									</div>
								</DisplaySection>
								<DisplaySection
									title={getTitle(
										"Secondary Driver",
										viewerCanEdit,
										!!data?.driver_secondary?._id,
										loadingSection,
										updatedSection,
										true
									)}
									classes={{ root: "mx-0 mb-24 mt-0", divider: "mb-0" }}
								>
									<div className={`relative w-full flex`}>
										<div className={`flex w-full ${loadingSection === "Secondary Driver" ? "opacity-40" : ""} `}>
											{data?.driver_secondary?._id && (
												<SmarthopFormView
													content={formConfiguration.driver_secondary}
													data={data.driver_secondary}
													{...defaultProps}
												/>
											)}
										</div>
									</div>
								</DisplaySection>
							</div>
						</div>
					),
				},
				{
					key: "ANLYTICS",
					title: "Analytics",
					hideOnMobile: true,
					component: <WidgetsBoard boardKey="TRUCK_PROFIT" carrierId={carrierId} truckId={truckId} margin={[20, 20]} />,
				},
				{
					key: "COSTS",
					title: "Costs & Goals",
					component: (
						<div className={`flex flex-col w-full space-y-10 `}>
							<ProfitWarnings data={data} />
							<div className={`flex flex-col md:flex-row w-full md:space-y-0 space-x-0 md:space-x-40`}>
								<DisplaySection
									title={getTitle(
										"Cost Structure",
										viewerCanEdit,
										!!data?.costStructure?._id,
										loadingSection,
										updatedSection,
										true
									)}
									classes={{ root: "mx-0 mt-0 w-full", divider: "mb-0" }}
								>
									<div className={`relative w-full flex`}>
										<div className={`flex w-full ${loadingSection === "Cost Structure" ? "opacity-40" : ""} `}>
											{data.costStructure && (
												<SmarthopFormView
													content={formConfiguration.cost}
													data={data.costStructure}
													{...defaultProps}
												/>
											)}
										</div>
									</div>
								</DisplaySection>

								<DisplaySection
									title={getTitle("Goal", viewerCanEdit, data?.goal?._id, loadingSection, updatedSection, true)}
									classes={{ root: "mx-0 mt-0 w-full", divider: "mb-0" }}
								>
									<div className={`relative w-full flex`}>
										<div className={`flex w-full ${loadingSection === "Goal" ? "opacity-40" : ""} `}>
											{data?.goal && (
												<SmarthopFormView content={formConfiguration.goal} data={data.goal} {...defaultProps} />
											)}
										</div>
									</div>
								</DisplaySection>
							</div>
							{data.costStructure?._id && (
								<div className="flex w-full">
									<CostStructureView
										classes={{ root: "w-full bg-blue-50 p-14 rounded-lg border-1 border-blue" }}
										variant="truck_preview"
										dataIds={costDataids}
									/>
								</div>
							)}
							<div className="flex md:w-1/2">
								<DisplaySection
									title={"Truck Revenue Distribution"}
									classes={{ root: "mx-0 mt-0 w-full", divider: "mb-0" }}
								>
									{chartSettings?.error ? (
										<div className="bg-red-50 border-1 border-red rounded-lg p-10 mt-16 flex flex-row">
											<Typography component="div" className={`text-red-700 text-12 mb-8 flex flex-row items-center`}>
												<div className="flex flex-col">
													<Typography className="font-semibold">
														<Icon className="text-18 -mb-5 text-red-400 mr-3">warning</Icon> Revenue distribution error
													</Typography>
													<Typography component={"div"} className="">
														{chartSettings?.error}
													</Typography>
												</div>
											</Typography>
										</div>
									) : (
										<ReactApexChart
											className="mt-16 overflow-hidden"
											height={280}
											style={{ display: "flex", width: "100%" }}
											options={{
												chart: {
													width: 380,
													type: "pie",
												},
												labels: chartSettings?.labels,
												colors: ["#673AB7", "#00BCD4", "#4CAF50", "#FFC107", "#FF5722", "#607D8B"],
												responsive: [
													{
														breakpoint: 360,
														options: {
															chart: {
																width: 200,
															},
															legend: {
																position: "bottom",
															},
														},
													},
												],
											}}
											series={chartSettings?.series}
											type="pie"
										/>
									)}
								</DisplaySection>
							</div>
						</div>
					),
				},
				{
					key: "REMINDERS",
					title: "Reminders",
					hideOnDesktop: !viewerCanAccessReminders,
					hideOnMobile: true,
					type: "LIST",
					header: (
						<div className="flex flex-row w-full items-center justify-between border-b-1 broger-grey mb-10">
							<Typography component={"div"} className="block max-w-660 text-grey text-11 pb-2">
								Keep track of maintanense or any other recurrent events by saving reminders, our bot will send you a
								message in the dispatching channel to remind you when the time comes!
							</Typography>
						</div>
					),
					params: {
						config: carrierTruckRemindersList,
						renderEmptyListView: () => "No Reminders Yet",
						dataIds: { carrierId, truckId },
					},
				},
				{
					key: "INTERNAL",
					title: "Internal",
					hideOnDesktop: !isAdmin,
					hideOnMobile: !isAdmin,
					component: (
						<div className="flex flex-col w-full">
							<div className="w-full">
								{renderDataItem("Axle Truck ID", data?.truck?.axleId)}
								{renderDataItem("Axle Name", data?.truck?.axleName)}
								{renderDataItem("Axle Imported", data?.truck?.axleTracking?.axleImported)}
								{renderDataItem("Tracking ID", data?.truck?.axleTracking?.id)}
								{renderDataItem("webhook_code", data?.truck?.axleTracking?.webhook_code)}
								{renderDataItem("webhook_type", data?.truck?.axleTracking?.webhook_type)}

								{data?.driver?.axleId && (
									<DisplaySection
										classes={{ root: "mx-0 mt-0 mb-28 mt-28", divider: "mb-0" }}
										title="Driver Axle Details"
									>
										{renderDataItem("Off Duty Seconds", data?.driver?.hoursOfService?.off_duty_seconds)}
										{renderDataItem("On Duty Seconds", data?.driver?.hoursOfService?.on_duty_seconds)}
										{renderDataItem("Sleeper Seconds", data?.driver?.hoursOfService?.sleeper_seconds)}
										{renderDataItem("Driving Seconds", data?.driver?.hoursOfService?.driving_seconds)}
										{renderDataItem("Waiting Seconds", data?.driver?.hoursOfService?.waiting_seconds)}
										{renderDataItem("Active Seconds", data?.driver?.hoursOfService?.active_seconds)}
										{renderDataItem("Yard Move Seconds", data?.driver?.hoursOfService?.yard_move_seconds)}

										{renderDataItem("Break", data?.driver?.hoursOfService?.break)}
										{renderDataItem("Cycle", data?.driver?.hoursOfService?.cycle)}
										{renderDataItem("Jurisdiction", data?.driver?.hoursOfService?.jurisdiction)}
										{renderDataItem("Restart", data?.driver?.hoursOfService?.restart)}
										{renderDataItem("Shift", data?.driver?.hoursOfService?.shift)}
									</DisplaySection>
								)}
								{data?.driver_secondary?.axleId && (
									<DisplaySection
										classes={{ root: "mx-0 mt-0 mb-28 mt-28", divider: "mb-0" }}
										title="Secondary Driver Axle Details"
									>
										{renderDataItem("Off Duty Seconds", data?.driver_secondary?.hoursOfService?.off_duty_seconds)}
										{renderDataItem("On Duty Seconds", data?.driver_secondary?.hoursOfService?.on_duty_seconds)}
										{renderDataItem("Sleeper Seconds", data?.driver_secondary?.hoursOfService?.sleeper_seconds)}
										{renderDataItem("Driving Seconds", data?.driver_secondary?.hoursOfService?.driving_seconds)}
										{renderDataItem("Waiting Seconds", data?.driver_secondary?.hoursOfService?.waiting_seconds)}
										{renderDataItem("Active Seconds", data?.driver_secondary?.hoursOfService?.active_seconds)}
										{renderDataItem("Yard Move Seconds", data?.driver_secondary?.hoursOfService?.yard_move_seconds)}

										{renderDataItem("Break", data?.driver_secondary?.hoursOfService?.break)}
										{renderDataItem("Cycle", data?.driver_secondary?.hoursOfService?.cycle)}
										{renderDataItem("Jurisdiction", data?.driver_secondary?.hoursOfService?.jurisdiction)}
										{renderDataItem("Restart", data?.driver_secondary?.hoursOfService?.restart)}
										{renderDataItem("Shift", data?.driver_secondary?.hoursOfService?.shift)}
									</DisplaySection>
								)}
							</div>
						</div>
					),
				},
			]}
			footerActions={[
				{
					key: "SEARCH_LOAD",
					title: "Search for Load",
					hideOnDesktop: !hasSearchPermission,
					hideOnMobile: !hasSearchPermission,
					style: { align: "RIGHT", notCollapsible: true, primary: true, icon: "search" },
					onClick: () => {
						if (localStorage.getItem("SEARCH_VERSION") !== "2" && isEnabled("ENABLE_SEARCH_V3")) {
							saveSearchesSessionStorage(
								convertPlanAndTruckToSearch(data?.carrier, data?.truck, data?.plan, data?.driver, data?.trailer)
							).then(() => {
								history.push("/search?id=EMPTY");
								dispatch(closeAllDialogs());
							});
						} else {
							dispatch(
								openLoadedFormDialog({
									viewId: !data?.plan?.current_location ? "SEARCH_REQUEST" : "SEARCH_REQUEST_REDIRECT",
									dataIds: {
										carrierId: dataIds?.carrierId,
										truckId: dataIds?.truckId,
										eventType: "SEARCH_SIMILAR",
									},
								})
							);
						}
					},
				},
				{
					key: "EDIT_PLAN_TRUCK",
					hideOnDesktop: !hasSearchPermission,
					hideOnMobile: !hasSearchPermission,
					title: "Truck Plan",
					style: { align: "LEFT", notCollapsible: true, icon: "event" },
					onClick: () => {
						dispatch(
							openLoadedFormDialog({
								viewId: "TRUCK_PLAN_VIEW",
								dataIds: {
									carrierId: dataIds?.carrierId,
									truckId: dataIds?.truckId,
								},
							})
						);
					},
				},
				{
					key: "ADD_REMINDER",
					title: "Add Reminder",
					style: { align: "LEFT", notCollapsible: true, icon: "access_alarm" },
					hideOnDesktop: !viewerCanAccessReminders,
					hideOnMobile: true,
					onClick: () => {
						dispatch(
							openLoadedFormDialog({ formId: "TRUCK_REMINDER_FORM", mode: "CREATE", dataIds: { truckId, carrierId } })
						);
					},
				},
			]}
		/>
	);
};

export default TruckDetailsView;
