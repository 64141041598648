import moment from "moment";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { Alert } from "@material-ui/lab";
import { smartpayBadgeGenerator } from "app/main/profile/trips/invoicing/invoiceUtils";

// Utils
import { uploadFile } from "./updateContentFile";
import { isEnabled } from "app/services/featureStorageService";
import { createPriceView } from "app/main/utils/priceUtils";
import { getLoadEquipments } from "app/main/search/searchUtils";
import { processTierRestrictions } from "app/main/utils/rolesUtils";
import { getWarningMsg } from "app/main/utils/tripsUtils";

const LOAD_INFORMATION_SECTION = {
	type: "section",
	label: "Load Details",
};

const TRUCK_DRIVER_SECTION = {
	type: "section",
	label: "Truck & Driver",
};

const BROKER_UPDATE = {
	type: "section",
	label: "Broker Updates",
};

const BROKER_SECTION = (
	showButtonSaveBroker,
	saveBroker,
	brokerMetadata,
	isTripSmartPayEligible,
	mode,
	smartPayModuleEnabled
) => {
	let actions = [];
	//In create is enough to check if broker and carrier have smartpay enabled
	if (mode === "CREATE" && brokerMetadata?.smartpayEnabled && smartPayModuleEnabled) {
		actions.push(smartpayBadgeGenerator({ available: brokerMetadata.smartpayEnabled, amount: 0 }));
	} else if (brokerMetadata?.smartpayEnabled && mode !== "CREATE") {
		//In Edit, we have two options, the trip is not eligible and the broker selected is eligible, or the
		//trip is already eligible
		if ((!isTripSmartPayEligible && brokerMetadata?.smartpayEnabled) || isTripSmartPayEligible) {
			actions.push(smartpayBadgeGenerator({ available: brokerMetadata.smartpayEnabled, amount: 0 }));
		}
	}

	if (showButtonSaveBroker) {
		actions.push({
			onClick: saveBroker,
			label: "Update Details",
			variant: "contained",
			description:
				"Update selected broker/customer details for everyone. This would allow to prefill broker/customer details everytime it's selected.",
		});
	}
	return {
		type: "section",
		label: "Broker/Customer Details",
		actions,
	};
};

const ORIGIN_DETAILS_SECTION = {
	type: "section",
	label: "Origin Details",
};

const STOPS_DETAILS_SECTION = {
	type: "section",
	label: "Stops Details",
};

const DESTINATION_DETAILS_SECTION = {
	type: "section",
	label: "Destination Details",
};

const TRIP_DETAILS_SECTION = {
	type: "section",
	label: "Trip Details",
};

const CARGO_DETAILS_SECTION = {
	type: "section",
	label: "Cargo Details",
};

const NOTES_SECTION = {
	type: "section",
	label: "Notes",
};

const LOAD_DOCUMENTS_SECTION = {
	type: "section",
	label: "Load Document",
};

const ADDITIONA_AND_DEDUCTION_SECTION = {
	type: "section",
	label: "Additions and Deductions",
};

const getWarnings = (warnings, create, assignedTruckId) => {
	const warn = warnings?.map((type) => getWarningMsg(type))?.filter((msg) => msg);
	if (create || (assignedTruckId && !warn?.length)) return false;

	return (
		<div>
			{warn?.length > 0 && <p>Required missing fields: {warn?.join?.(", ") + "."}</p>}
			{!assignedTruckId && (
				<p>
					To change this trip status please assign a truck first. 'Missing Data' status is automatically assigned to all
					trips without an assigned truck
				</p>
			)}
		</div>
	);
};

const TRIP_DETAILS_STATUS = (warnings, create, assignedTruckId, isPublicView) => {
	return {
		key: "status",
		label: "Status",
		type: "autocomplete",
		warning: getWarnings(warnings, create, assignedTruckId),
		disabled: !assignedTruckId && !create,
		autocomplete: {
			provider: "smarthop",
			url: isPublicView
				? "api/public/url/booking/:tripId/status"
				: create
				? "api/consts/trip/status"
				: "api/booking/trips/:tripId/status",
			preloadDefault: true,
		},
	};
};

const TRIP_DETAILS_CARGO_TYPE = {
	key: "cargo_type",
	type: "select",
	label: "Cargo type",
	options: [
		{
			value: "General Freight",
			label: "General Freight",
		},
		{
			value: "Produce",
			label: "Produce",
		},
		{
			value: "Plants",
			label: "Plants",
		},
		{
			value: "Electronics",
			label: "Electronics",
		},
		{
			value: "Fragile Equipment",
			label: "Fragile Equipment",
		},
		{
			value: "Food",
			label: "Food",
		},
		{
			value: "Other",
			label: "Other",
		},
	],
};

const TRIP_DETAILS_LOAD_ID = (hasRequired) => ({
	key: "load_id",
	label: "Load ID",
	required: hasRequired,
	type: "text",
});

const BROKER_NAME_TRIP_DETAILS = (role, roleType, carrierId, isPublicView) => ({
	key: "broker",
	type: "autocomplete",
	label: "Broker/Customer",
	autocomplete: {
		provider: "smarthop",
		url: isPublicView ? "api/public/url/const/brokers" : "api/consts/brokers",
		preloadDefault: true,
		showDescription: true,
		triggerEvent: "profileRevision",
		listenEvent: "profileRevision",
		params: {
			sortOrder: "desc",
			sortBy: "created_at",
			options_carrier_id_search: carrierId,
			limit: 10,
			check_smartpay: true,
			...(isPublicView ? { options_public_user_context: ":userId" } : {}),
		},
		actionOptions: [
			{
				value: "ADD_NEW_CUSTOMER",
				label: "Add New Customer",
				disabled: ["CARRIER_OWNER", "CARRIER_MANAGER"].includes(role) && carrierId ? false : true,
				icon: "add",
				dialog: {
					viewId: "BROKER_EDIT_VIEW",
					mode: "CREATE",
					dataIds: { carrierId },
				},
			},
		],
	},
});

const BROKER_MCNUMBER_TRIP_DETAILS = {
	key: "mcnumber",
	type: "number",
	label: "MC",
};

const BROKER_CONTACT_TRIP_DETAILS = {
	key: "broker_contact",
	type: "text",
	label: "Contact Name",
};

const BROKER_EMAIL_TRIP_DETAILS = {
	key: "mail_broker",
	type: "email",
	label: "Email",
};

const BROKER_PHONE_TRIP_DETAILS = {
	key: "phone_broker",
	type: "text",
	label: "Phone",
};

const BROKER_UPDATE_DISPATCHED = () => ({
	key: "send_broker_dispatched",
	type: "checkbox",
	label: "Truck Dispatched",
});

const BROKER_UPDATE_AT_PICKED_UP = () => ({
	key: "send_broker_at_pick_up",
	type: "checkbox",
	label: "Truck At Pickup",
});

const BROKER_UPDATE_AT_DELVERY = () => ({
	key: "send_broker_at_delivery",
	type: "checkbox",
	label: "Truck At Delivery",
});

const DOCUMENT_SECTION = {
	type: "section",
	label: "Additional Documents",
};

const VERISIOCATION_SECTION = {
	type: "section",
	label: "Verification",
};

const BROKER_EIN = {
	key: "customerId",
	type: "number",
	label: "EIN",
};

const CARRIER_ALL = (role, roleType, hasSubaccounts, hasRequired, isPublicView) => {
	return {
		key: "carrier",
		type: "autocomplete",
		label: "Carrier",
		required: hasRequired,
		field: {
			classes: {},
			variant: undefined,
		},
		disabled: roleType === "EXTERNAL" && !hasSubaccounts,
		autocomplete: {
			provider: "smarthop",
			url: isPublicView ? "api/public/url/profile/carriers" : "api/profile/carriers",
			preloadDefault: true,
			showDescription: true,
			listenEvent: "profileRevision",
			params: {
				options_active_only: true,
				options_my_assigned: role === "OPS_DISPATCHER",
				...(isPublicView ? { options_public_user_context: ":userId" } : {}),
			},
		},
	};
};

const BOOKED_BY = (role, roleType, isPublicView) => {
	return {
		key: "creator",
		label: "Booked By",
		type: "autocomplete",
		disabled: !["CARRIER_OWNER", "CARRIER_MANAGER"].includes(role),
		autocomplete: {
			provider: "smarthop",
			url: isPublicView ? "api/public/url/profile/users" : "api/profile/users",
			preloadDefault: true,
			showDescription: true,
			params: {
				options_append_internal_users_search: true,
				...(isPublicView ? { options_public_user_context: ":userId" } : {}),
				...(roleType === "EXTERNAL" ? { filters: { accountStatus: "active" } } : { options_internal_only: true }),
			},
		},
		rules: {
			params: {
				valueRef: "carrier",
				paramKey: "options_carrier_id_search",
			},
		},
	};
};

const CARRIER_TRUCK = (role, roleType, hasSubaccounts, isPublicView) => {
	return {
		key: "truck",
		type: "autocomplete",
		label: "Truck",
		translate: "",
		field: {
			classes: {},
			variant: undefined,
		},
		autocomplete: {
			provider: "smarthop",
			showDescription: true,
			preloadDefault: true,
			listenEvent: "profileRevision",
			url: isPublicView
				? "api/public/url/profile/trucks"
				: roleType === "EXTERNAL" && !hasSubaccounts
				? "api/profile/carriers/:carrierId/trucks"
				: "api/profile/trucks",
			params: {
				options_metadata_truck: true,
				options_metadata_driver: true,
				options_metadata_trailer: true,
				options_active_only: true,
				label_format_show_driver: true,
				options_metadata_investor: true,
				options_my_assigned: role === "OPS_DISPATCHER",
				...(isPublicView ? { options_public_user_context: ":userId" } : {}),
			},
		},
		rules:
			roleType !== "EXTERNAL" || hasSubaccounts
				? {
						params: {
							valueRef: "carrier",
							paramKey: "options_carrier_id_search",
						},
				  }
				: undefined,
	};
};

const CARRIER_TRAILER = (role, roleType, hasSubaccounts, isPublicView) => {
	return {
		key: "truck_trailer",
		type: "autocomplete",
		label: "Trailer",
		translate: "",
		field: {
			classes: {},
			variant: undefined,
		},
		autocomplete: {
			provider: "smarthop",
			url: isPublicView
				? "api/public/url/profile/trailers"
				: roleType === "EXTERNAL" && !hasSubaccounts
				? "api/profile/carriers/:carrierId/trailers"
				: "api/profile/trailers",
			preloadDefault: true,
			listenEvent: "trailerRevision",
			params: {
				options_active_only: true,
				...(isPublicView ? { options_public_user_context: ":userId" } : {}),
			},
		},
		rules:
			roleType !== "EXTERNAL" || hasSubaccounts
				? {
						params: {
							valueRef: "carrier",
							paramKey: "options_carrier_id_search",
						},
						disabled: {
							ifEmptyRef: "truck",
						},
				  }
				: {
						disabled: {
							ifEmptyRef: "truck",
						},
				  },
	};
};

const DRIVER_ASSIGNED = (isPublicView) => ({
	key: "driver",
	type: "autocomplete",
	label: "Primary Driver",
	translate: "",
	field: {
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "smarthop",
		preloadDefault: true,
		listenEvent: "profileRevision",
		url: isPublicView ? "api/public/url/profile/drivers" : "api/profile/drivers",
		params: {
			options_metadata_driver: true,
			options_active_only: true,
			label_format_show_driver: true,
			...(isPublicView ? { options_public_user_context: ":userId" } : {}),
		},
	},
	rules: {
		params: {
			valueRef: "carrier",
			paramKey: "options_carrier_id_search",
		},
		required: { fieldKey: "status", noEqualsTo: "Pending" },
		disabled: { ifEmptyRef: "truck" },
	},
});

const DRIVER_SECONDARY_ASSIGNED = (isPublicView) => ({
	key: "driver_secondary",
	type: "autocomplete",
	label: "Secondary Driver",
	translate: "",
	field: {
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "smarthop",
		preloadDefault: true,
		listenEvent: "profileRevision",
		url: isPublicView ? "api/public/url/profile/drivers" : "api/profile/drivers",
		params: {
			options_metadata_driver: true,
			options_active_only: true,
			label_format_show_driver: true,
			...(isPublicView ? { options_public_user_context: ":userId" } : {}),
		},
	},
	rules: {
		params: {
			valueRef: "carrier",
			paramKey: "options_carrier_id_search",
		},
		disabled: { ifEmptyRef: "truck" },
	},
});

const INVESTOR_ASSIGNED = (isPublicView) => ({
	key: "investor",
	type: "autocomplete",
	label: "Investor",
	translate: "",
	field: {
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "smarthop",
		preloadDefault: true,
		listenEvent: "profileRevision",
		url: isPublicView ? "api/public/url/profile/investors" : "api/profile/investors",
		params: {
			...(isPublicView ? { options_public_user_context: ":userId" } : {}),
		},
	},
	rules: {
		params: {
			valueRef: "carrier",
			paramKey: "options_carrier_id_search",
		},
		disabled: { ifEmptyRef: "truck" },
	},
});

const EMPTY_LOCATION = (tripId, freeForm, assignedTruckId, isPublicView) => ({
	key: "current_location",
	label: "Empty Location",
	type: "autocomplete",
	disabled: !freeForm && !assignedTruckId,
	required: true,
	warning:
		!freeForm && !assignedTruckId
			? "Please assing a truck to be able to provide an empty truck location for this trip"
			: null,
	autocomplete: {
		provider: "trimblemaps",
		params: { componentRestrictions: { country: "us" } },
		icon: "location",
		public: isPublicView,
		preloadDefault: true,
		defaultOptions: {
			provider: "smarthop",
			showDescription: true,
			listenEvent: "profileRevision",
			url: isPublicView ? "api/public/url/booking/trips" : "api/booking/trips",
			params: {
				options_past_addresses: true,
				...(isPublicView ? { options_public_user_context: ":userId" } : {}),
				...(tripId ? { options_exclude_trip_id: ":exclude_trip_id" } : {}),
				options_truck_id_search: "__FROM_VALUE_REF__truck",
			},
		},
	},
	field: freeForm ? { noErrorMessage: true } : false,
	rules: freeForm
		? null
		: {
				required: { fieldKey: "status", noEqualsTo: "Pending" },
		  },
});

const EMPTY_LOCATION_ZIP_CODE = { key: "zip_code_current_location", type: "zipcode", label: "Zip Code" };

const EMPTY_MILES = { key: "emptymiles", type: "number", label: "Empty Miles", min: 0 };

const createAlertMessage = (metadata) => {
	let { status, error } = metadata ?? {};
	let message = "";
	let style = {};
	if (status === "AUTO_PARSING_FAILURE") {
		message =
			"We failed to parse uploaded documents, it's still uploaded but please transcribe document manually." +
			(error ? " (" + error + ")" : "");
		style = {
			icon: "error_outline",
			color: "text-red-600",
		};
	} else if (status === "AUTO_PARSING_SUCCESS") {
		message = "We successfully parsed uploaded documents, please verify all information before saving.";
		style = {
			icon: "check_circle",
			color: "text-green-600",
		};
	} else if (status === "UPLOAD_SUCCESS") {
		message = "Rate confirmation uploaded successfully.";
		style = {
			icon: "check_circle",
			color: "text-green-600",
		};
	}

	return (
		message && (
			<div className="flex flex-row items-center -mt-14">
				<Icon className={`text-24 mr-10 ${style.color}`}>{style.icon}</Icon>
				<Typography
					className={style.color + " text-12"}
					style={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}
				>
					{message}
				</Typography>
			</div>
		)
	);
};

const createContent = ({
	mode,
	role,
	roleType,
	userTier,
	data,
	parsedMetadata,
	showRCPreview,
	isNative,
	onFileUpload,
	openPaywallView,
	extraFormData,
	saveBroker,
	showButtonSaveBroker = false,
	brokerMetadata,
	isTripSmartPayEligible = undefined,
	smartPayModuleEnabled = false,
	invoicingModuleEnabled = false,
	payrollModuleEnabled = false,
	billingModuleEnabled = false,
	tripId,
	assignedTruckId,
	hasSubaccounts = false,
	hasRatesOnPermission = true,
	hasRequired = false,
	isPublicView = false,
	disableBrokerUpdates,
}) => {
	const tripStatus = data?.edited?.status ?? data?.original?.status;
	const rcUploaded = data?.edited?.rate_con_file__flag ?? data?.original?.rate_con_file__flag;
	const carrierId = data?.edited?.carrier ?? data?.original?.carrier;

	const mainItems = [
		...(roleType === "INTERNAL"
			? [
					{
						type: "section",
						label: "Internal",
					},
					{
						type: "group",
						content: {
							items: [
								{
									key: "dispatcher",
									label: "Dispatcher",
									type: "autocomplete",
									autocomplete: {
										provider: "smarthop",
										url: "api/profile/users",
										preloadDefault: true,
										params: {
											options_internal_only: true,
										},
									},
								},
								...(smartPayModuleEnabled
									? [
											{
												key: "smartpay_eligible",
												label: "SmartPay Eligible (Factoring)",
												type: "checkbox",
											},
									  ]
									: [{ key: "stub2", type: "stub", label: "" }]),
								{ key: "stub3", type: "stub", label: "" },
							],
						},
					},
			  ]
			: []),
		LOAD_INFORMATION_SECTION,
		{
			type: "group",
			content: {
				items: [
					TRIP_DETAILS_STATUS(data?.original?.warnings, mode === "CREATE", assignedTruckId, isPublicView),
					...(tripStatus === "Delivered"
						? [
								{
									key: "marked_delivered_date",
									type: "picker",
									label: "Marked as Delivered on",
									picker: {
										notClearable: true,
										type: "datetime",
										classes: {},
									},
								},
								TRIP_DETAILS_LOAD_ID(hasRequired),
						  ]
						: [TRIP_DETAILS_LOAD_ID(hasRequired), { key: "stub2", type: "stub", label: "" }]),
				],
			},
		},
		...(showRCPreview || !hasRatesOnPermission
			? []
			: [
					LOAD_DOCUMENTS_SECTION,
					{
						key: "rate_con_file",
						type: "upload",
						label: "Rate Confirmation",
						upload: {
							url: isPublicView
								? "api/public/url/multi-upload/upload"
								: isNative
								? "api/booking/carriers/:carrierId/files"
								: "api/multi-upload/upload",
							documentType: "trip",
							fileName: "rate_confirmation",
							type: "rate_con_file",
							onFileUpload: isNative ? null : onFileUpload,
							extraFormData: extraFormData?.["rate_con_file"],
							public: isPublicView,
							params: {
								...(isPublicView ? { options_public_user_context: ":userId" } : {}),
							},
						},
					},
					{
						label: "",
						type: "component",
						builder: (item) => createAlertMessage(parsedMetadata),
					},
			  ]),
		{
			type: "section",
			label: "Settings",
		},
		{
			type: "group",
			group: { classes: { child0: "w-full md:w-2/3", child1: "w-full md:w-1/3" } },
			content: {
				items: [
					CARRIER_ALL(role, roleType, hasSubaccounts, hasRequired, isPublicView),
					BOOKED_BY(role, roleType, isPublicView),
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					...(payrollModuleEnabled
						? [
								{
									key: "payroll_elegible",
									type: "checkbox",
									label: "Payroll Eligible",
								},
						  ]
						: [{ key: "payroll_elegible_stub", type: "stub", label: "" }]),
					...(invoicingModuleEnabled
						? [
								{
									key: "invoicing_eligible",
									type: "checkbox",
									label: "Invoicing Eligible",
									// FIXME: check if onbording completed outside of the form
									// and set invoicingModuleEnabled to false if not onboarded
									hidden: (data) => data?.enterprise_features?.invoicingModuleOnboarding !== "COMPLETE",
								},
						  ]
						: [{ key: "invoice_elegible_stub", type: "stub", label: "" }]),
					...(isEnabled("TRIP_BILLING") && billingModuleEnabled
						? [
								{
									key: "billing_eligible",
									type: "checkbox",
									label: "Billing Eligible",
								},
						  ]
						: [{ key: "billing_eligible_stub", type: "stub", label: "" }]),
				],
			},
		},
		TRUCK_DRIVER_SECTION,
		{
			type: "group",
			group: { classes: { child0: "w-full md:w-2/3", child1: "w-full md:w-1/3" } },
			content: {
				items: [
					CARRIER_TRUCK(role, roleType, hasSubaccounts, isPublicView),
					CARRIER_TRAILER(role, roleType, hasSubaccounts, isPublicView),
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					DRIVER_ASSIGNED(isPublicView),
					DRIVER_SECONDARY_ASSIGNED(isPublicView),
					INVESTOR_ASSIGNED(isPublicView),
				],
			},
		},
		{
			type: "group",
			group: { classes: { child0: "w-full md:w-2/3", child1: "w-full md:w-1/3" } },
			content: {
				items: [EMPTY_LOCATION(tripId, false, assignedTruckId, isPublicView), EMPTY_LOCATION_ZIP_CODE],
			},
		},
		BROKER_SECTION(
			showButtonSaveBroker,
			saveBroker,
			brokerMetadata,
			isTripSmartPayEligible,
			mode,
			smartPayModuleEnabled
		),
		{
			type: "group",
			content: {
				items: [
					BROKER_NAME_TRIP_DETAILS(role, roleType, carrierId, isPublicView),
					BROKER_MCNUMBER_TRIP_DETAILS,
					BROKER_EIN,
				],
			},
		},
		{
			type: "group",
			content: {
				items: [BROKER_CONTACT_TRIP_DETAILS, BROKER_PHONE_TRIP_DETAILS, BROKER_EMAIL_TRIP_DETAILS],
			},
		},
		{
			type: "section",
			label: "Extra Contacts",
		},
		{
			key: "extraContacts",
			type: "pattern",
			addButton: { label: "Add Contact" },
			pattern: { classes: { label: "-mt-8" }, showAllDefault: true, variant: "large" },
			content: {
				items: [
					{
						type: "group",
						content: {
							items: [
								{
									key: "contact",
									type: "text",
									label: "Contact Name",
									required: hasRequired,
								},
								{
									key: "phone",
									type: "number",
									maxCharacter: 20,
									label: "Phone",
								},
								{
									key: "email",
									type: "email",
									label: "Email",
								},
							],
						},
					},
				],
			},
		},
		BROKER_UPDATE,
		...(!disableBrokerUpdates
			? [
					{
						type: "message",
						label: "Send trip updates to the broker via email when",
						message: { classes: { root: "-mt-8 -mb-2" } },
					},
					{
						type: "group",
						content: {
							items: [BROKER_UPDATE_DISPATCHED(), BROKER_UPDATE_AT_PICKED_UP(), BROKER_UPDATE_AT_DELVERY()],
						},
					},
			  ]
			: []),
		...(disableBrokerUpdates
			? [
					{
						type: "message",
						builder: () => (
							<Alert severity="info">
								Want to send automatic broker updates?
								<Link className="cursor-pointer px-4" onClick={() => openPaywallView()}>
									<span className="text-primary font-semibold underline">Click here</span>
								</Link>
								to learn how you can save time with automated dispatching.
							</Alert>
						),
						message: { classes: { root: "-mt-16" } },
					},
			  ]
			: []),
		ORIGIN_DETAILS_SECTION,
		{
			type: "group",
			content: {
				items: [
					{
						key: "pickup_date",
						type: "picker",
						label: "Pickup Date",
						required: hasRequired,
						field: {
							classes: {},
							variant: undefined,
						},
						input: {
							classes: {},
						},
						picker: {
							disablePast: false,
							notClearable: false,
							type: "datetime",
							classes: {},
						},
					},
					{ key: "open_pickup_window", type: "checkbox", label: "Open pickup window" },
					{
						key: "pickup_window_date",
						type: "picker",
						label: "Pickup by Date",
						field: {
							classes: {},
							variant: undefined,
						},
						input: {
							classes: {},
						},
						picker: {
							disablePast: false,
							notClearable: false,
							type: "datetime",
							classes: {},
						},
						rules: {
							disabled: {
								ifEmptyRef: "open_pickup_window",
							},
						},
					},
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{
						key: "pickup_address",
						label: "Pickup Address",
						type: "autocomplete",
						required: hasRequired,
						autocomplete: {
							provider: "trimblemaps",
							params: { componentRestrictions: { country: "us" } },
							icon: "location",
							public: isPublicView,
						},
					},
				],
			},
		},
		{
			type: "group",
			group: { classes: { child0: "w-full md:w-1/3", child1: "w-full md:w-2/3" } },
			content: {
				items: [
					{ key: "zip_code_pickup_address", type: "zipcode", label: "Zip Code" },
					{ key: "pickup_shipper", type: "text", label: "Shipper" },
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{ key: "pickup_number", type: "text", label: "Pickup Number" },
					{ key: "pickup_contact", type: "text", label: "Contact" },
					{ key: "pickup_phone", type: "text", label: "Phone" },
				],
			},
		},
		{ key: "pickupComplete", type: "checkbox", label: "Completed" },
		STOPS_DETAILS_SECTION,
		{
			key: "locations",
			type: "pattern",
			addButton: { label: "Add a Stop" },
			pattern: { classes: { label: "-mt-8" }, showAllDefault: true, variant: "large" },
			content: {
				items: [
					{
						type: "group",
						content: {
							items: [
								{
									key: "location_date",
									type: "picker",
									label: "Early Date",
									required: hasRequired,
									field: {
										classes: {},
										variant: undefined,
									},
									input: {
										classes: {},
									},
									picker: {
										minDate: moment(data?.original?.pickup_date),
										disablePast: false,
										notClearable: false,
										type: "datetime",
										classes: {},
									},
								},
								{ key: "open_location_window", type: "checkbox", label: "Open location window" },
								{
									key: "location_window_date",
									type: "picker",
									label: "Late Date",
									field: {
										classes: {},
										variant: undefined,
									},
									input: {
										classes: {},
									},
									picker: {
										disablePast: false,
										notClearable: false,
										type: "datetime",
										classes: {},
									},
									rules: {
										disabled: {
											ifEmptyRef: "open_location_window",
										},
									},
								},
							],
						},
					},
					{
						type: "group",
						content: {
							items: [
								{
									key: "location",
									label: "Address",
									type: "autocomplete",
									required: hasRequired,
									autocomplete: {
										provider: "trimblemaps",
										params: { componentRestrictions: { country: "us" } },
										icon: "location",
										public: isPublicView,
									},
								},
							],
						},
					},
					{
						type: "group",
						group: { classes: { child0: "w-full md:w-1/3", child1: "w-full md:w-2/3" } },
						content: {
							items: [
								{ key: "zip_code_location", type: "zipcode", label: "Zip Code" },
								{ key: "location_shipper", type: "text", label: "Shipper" },
							],
						},
					},
					{
						type: "group",
						content: {
							items: [
								{ key: "location_number", type: "text", label: "Pickup/Delivery Number" },
								{ key: "location_contact", type: "text", label: "Contact" },
								{ key: "location_phone", type: "text", label: "Phone" },
							],
						},
					},
					{
						type: "group",
						content: {
							items: [
								{
									key: "type",
									type: "select",
									label: "Type",
									required: hasRequired,
									options: [
										{ value: "PICKUP", label: "Pickup" },
										{ value: "DELIVERY", label: "Delivery" },
									],
								},
								{ key: "complete", type: "checkbox", label: "Completed" },
								{ key: "", type: "stub", label: "" },
							],
						},
					},
				],
			},
		},
		DESTINATION_DETAILS_SECTION,
		{
			type: "group",
			content: {
				items: [
					{
						key: "delivery_date",
						type: "picker",
						label: "Delivery Date",
						required: hasRequired,
						field: {
							classes: {},
							variant: undefined,
						},
						input: {
							classes: {},
						},
						picker: {
							disablePast: false,
							notClearable: false,
							type: "datetime",
							classes: {},
						},
					},
					{ key: "open_delivery_window", type: "checkbox", label: "Open delivery window" },
					{
						key: "delivery_window_date",
						type: "picker",
						label: "Delivery by Date",
						field: {
							classes: {},
							variant: undefined,
						},
						input: {
							classes: {},
						},
						picker: {
							disablePast: false,
							notClearable: false,
							type: "datetime",
							classes: {},
						},
						rules: {
							disabled: {
								ifEmptyRef: "open_delivery_window",
							},
						},
					},
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{
						key: "delivery_address",
						label: "Address",
						type: "autocomplete",
						required: hasRequired,
						autocomplete: {
							provider: "trimblemaps",
							params: { componentRestrictions: { country: "us" } },
							icon: "location",
							public: isPublicView,
						},
					},
				],
			},
		},
		{
			type: "group",
			group: { classes: { child0: "w-full md:w-1/3", child1: "w-full md:w-2/3" } },
			content: {
				items: [
					{ key: "zip_code_delivery_address", type: "zipcode", label: "Zip code" },
					{ key: "delivery_shipper", type: "text", label: "Consignee" },
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{ key: "delivery_number", type: "text", label: "Delivery Number" },
					{ key: "delivery_contact", type: "text", label: "Contact" },
					{ key: "delivery_phone", type: "text", label: "Phone" },
				],
			},
		},
		TRIP_DETAILS_SECTION,
		...(hasRatesOnPermission
			? [
					{
						type: "group",
						content: {
							items: [
								EMPTY_MILES,
								{
									key: "miles",
									type: "number",
									label: "Loaded Miles",
									min: 0,
									rules: {
										disabled: {
											ifNotEmptyRef: "tonu",
										},
									},
								},
								{ key: "stub2", type: "stub", label: "" },
							],
						},
					},
					{
						type: "group",
						content: {
							items: [
								{
									key: "rate",
									type: "number",
									label: "Rate",
									min: hasRequired ? 1 : 0,
									required: hasRequired,
									builder: (item) => {
										// TODO This logic should be refactored
										const { showRate, rate, verifyDriverPayment } = item ?? {};
										const message = verifyDriverPayment ? "Verify driver payment" : "Paid by miles";
										if (showRate) return message;
										return createPriceView(rate);
									},
								},
								{
									key: "rpm",
									type: "number",
									label: "RPM",
									min: 0,
									builder: (item) =>
										item.showRate
											? createPriceView(item.rpm, 2)
											: item?.verifyDriverPayment
											? "Verify driver payment"
											: "Paid by miles",
									rules: {
										disabled: {
											ifNotEmptyRef: "tonu",
										},
									},
								},
								{
									key: "tonu",
									type: "checkbox",
									label: "TONU",
								},
							],
						},
					},
			  ]
			: [
					{
						type: "group",
						content: {
							items: [
								{
									key: "miles",
									type: "number",
									label: "Loaded Miles",
									min: 0,
									rules: {
										disabled: {
											ifNotEmptyRef: "tonu",
										},
									},
								},
								{ key: "emptymiles", type: "number", label: "Empty Miles", min: 0 },
								{ key: "tonu", type: "checkbox", label: "TONU" },
							],
						},
					},
			  ]),
		CARGO_DETAILS_SECTION,
		{
			type: "group",
			content: {
				items: [
					TRIP_DETAILS_CARGO_TYPE,
					{ key: "pallets", type: "text", label: "Pallets" },
					{ key: "weight", type: "number", label: "Weight", min: 0 },
				],
			},
		},
		{
			type: "group",
			content: {
				items: [
					{ key: "size", type: "text", label: "Size" },
					{
						key: "equipment",
						type: "select",
						required: hasRequired,
						label: "Equipment",
						options: getLoadEquipments(),
					},
					{ key: "temperature", type: "text", label: "Temperature" },
				],
			},
		},
		ADDITIONA_AND_DEDUCTION_SECTION,
		{
			key: "logistics_costs",
			type: "pattern",
			pattern: { classes: { label: "-mt-8" }, showAllDefault: true, variant: "large" },
			content: {
				items: [
					{
						type: "group",
						content: {
							items: [
								{
									key: "reason",
									type: "select",
									label: "Additions and Deductions",
									required: hasRequired,
									options: [
										{
											value: "Detention",
											label: "Detention",
										},
										{
											value: "Layover",
											label: "Layover",
										},
										{
											value: "Lumper",
											label: "Lumper",
										},
										{
											value: "Late Fee",
											label: "Late Fee",
										},
										{
											value: "Reimbursement",
											label: "Reimbursement",
										},
										{
											value: "Fuel Surge Charge",
											label: "Fuel Surge Charge",
										},
										{
											value: "Damage",
											label: "Damage",
										},
										{
											value: "Cash Advance",
											label: "Cash Advance",
										},
										{
											value: "Bonus",
											label: "Bonus",
										},
										// Deprecated -- Start
										{
											value: "Escrow",
											label: "Escrow",
										},
										{
											value: "Fuel",
											label: "Fuel",
										},
										{
											value: "Insurance",
											label: "Insurance",
										},
										// Deprecated -- End
										{
											value: "Other",
											label: "Other",
										},
									],
								},
								{
									key: "required_action",
									type: "select",
									label: "Required Action",
									required: hasRequired,
									default: "DRIVER_PAY",
									options: [
										{
											value: "DRIVER_PAY",
											label: "Driver Pay",
										},
										{
											value: "INVOICE_ONLY",
											label: "Invoice Only",
										},
										{
											value: "DRIVER_PAY_AND_INVOICE",
											label: "Driver Pay & Invoice",
										},
									],
								},
								{
									key: "direction",
									type: "select",
									label: "Direction",
									required: hasRequired,
									options: [
										{
											value: "CREDIT",
											label: "Addition",
										},
										{
											value: "DEBIT",
											label: "Deduction",
										},
									],
								},
							],
						},
					},
					{
						type: "group",
						group: { classes: { child0: "w-full md:w-1/3", child1: "w-full md:w-2/3" } },
						content: {
							items: [
								{
									key: "amount",
									type: "currency",
									label: "Amount",
									required: hasRequired,
									min: 0,
								},
								{
									key: "description",
									type: "text",
									label: "Description",
									rules: {
										required: { fieldKey: "reason", equalsTo: "Other" },
									},
								},
							],
						},
					},
				],
			},
		},
		NOTES_SECTION,
		{ key: "comment", type: "text", label: "", multiline: { rows: 10 } },
		DOCUMENT_SECTION,
		...uploadFile({
			internalConfig: true,
			type: "pod_files",
			noLabel: true,
			isPublicView,
			isNative,
			isEnabledAnalizeIcon:
				isEnabled("ANALIZE_FILES_UPLOADED") &&
				processTierRestrictions({ restrictions: ["TIER_PROFESSIONAL"], tier: userTier }),
		}),
		VERISIOCATION_SECTION,
		{
			key: "transcription_verified__flag",
			label: "RC Transcription has been Verified",
			type: "checkbox",
			disabled: !rcUploaded,
			description: "A Rate Confirmation needs to be uploaded in order to mark this trip as verified",
		},
		...(!processTierRestrictions({ restrictions: ["TIER_PROFESSIONAL"], tier: userTier })
			? [
					{
						type: "message",
						builder: () => (
							<Alert severity="info">
								Want to help validating RCs?
								<Link className="cursor-pointer px-4" onClick={() => openPaywallView()}>
									<span className="text-primary font-semibold underline">Click here</span>
								</Link>
								to learn how we can automate it for you.
							</Alert>
						),
						message: { classes: { root: "-mt-16" } },
					},
			  ]
			: []),
		{
			key: "payroll_has_approved",
			type: "checkbox",
			hidden: () => true,
		},
		{
			key: "invoice_has_generated",
			type: "checkbox",
			hidden: () => true,
		},
		{
			type: "action",
			action: "SUBMIT",
			label: mode === "CREATE" ? "Create" : "Update",
			button: {
				icon: "save",
				classes: { root: "w-full md:w-min px-40 whitespace-nowrap" },
				sticky: true,
				alwaysEnabledIfValid: true,
			},
		},
	];

	return {
		form: {
			preventClose: { enable: true },
		},
		...(!showRCPreview
			? // Showing traditionally structured form if screen is too small
			  { items: mainItems }
			: // Showing expanded view with file preview if sceen has space
			  {
					left: {
						classes: { root: "w-7/12", container: "pr-6 pl-4 pt-4" },
						items: mainItems,
					},
					right: {
						classes: { root: "w-5/12", container: "pl-4 pt-4" },
						items: [
							LOAD_DOCUMENTS_SECTION,
							{
								key: "rate_con_file",
								type: "upload",
								label: "Rate Confirmation",
								upload: {
									variant: "preview",
									url: isPublicView
										? "api/public/url/multi-upload/upload/users/:userId"
										: isNative
										? "api/booking/carriers/:carrierId/files"
										: "api/multi-upload/upload",
									documentType: "trip",
									fileName: "rate_confirmation",
									type: "rate_con_file",
									onFileUpload: isNative ? null : onFileUpload,
									extraFormData: extraFormData?.["rate_con_file"],
									public: isPublicView,
									params: {
										...(isPublicView ? { options_public_user_context: ":userId" } : {}),
									},
								},
							},
							{
								label: "",
								type: "component",
								builder: (item) => createAlertMessage(parsedMetadata),
							},
						],
					},
			  }),
	};
};

export { createContent, EMPTY_LOCATION, EMPTY_MILES, EMPTY_LOCATION_ZIP_CODE };
