import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUserId, isRoleExternal, isCarrier, getCarrierId } from "app/services/LoginService";
import { setConnected, setAuthenticated, initConnection } from "app/store/socket/connectionSlice";
import { addMessage, updateMessage, removeNotificaions } from "app/store/messenger/messageSlice";
import { addChat } from "app/store/messenger/chatSlice";
import { fetchChats } from "app/store/messenger/chatSlice";
import { fetchActions } from "app/store/actions/actionsUserSlice";
import { fetchTransactions } from "app/store/transactions/transactionsSlice";
import { fetchCredentialsStatus } from "app/store/search/credentialsSlice";
import { fetchFiles } from "app/store/upload/filesSlice";
import {
	fetchSearchResults,
	setSearchParams,
	addSearchStatistic,
	coverLoads,
	addSearchMultipleStatistics,
	updateLoads,
} from "app/store/search/searchV3Slice";
import { incrementDataRevision } from "app/store/tools/revisionSlice";
import { readURLParameters, convertURLParamsToModel } from "app/main/utils/urlUtils";

import { quickSnackbar, showSnackbar, showMessageSnackbar } from "../utils/snackbarUtil";
import { showBrowserNotification, requestBrowserNotificationPermission } from "../utils/browserUtil";

import { replaceFormDialog } from "app/store/tools/formDialogSlice";

const _CONNECT = "connect";
const _DISCONNECT = "disconnect";
const _RECONNECT = "reconnect";
const _RECONNECTING = "reconnecting";
const _AUTHENTICATE = "authenticate";
const _AUTHENTICATED = "authenticated";
const _UNAUTHORIZED = "unauthorized";
const _MESSAGE = "message";

function ConnectionHandler(props) {
	// Overrides from URL that allows to log user in using just url params
	const dataIds = convertURLParamsToModel(readURLParameters(), {}, { notJson: true });

	const dispatch = useDispatch();
	const snackbar = useSnackbar();
	const userId = getUserId() ?? dataIds?.userId;
	const carrierId = getCarrierId();
	const roleExternal = isRoleExternal();
	const userIsCarrier = isCarrier();

	const isAuthenticated = useSelector(({ socket }) => socket.connection.isAuthenticated);
	const socket = useSelector(({ socket }) => socket.connection.socket);
	const token = useSelector(({ auth }) => auth.user.token) ?? dataIds?.token;
	const formStack = useSelector(({ tools }) => tools.formDialog.formStack ?? []);
	const [revisionPriceLoadMessage, setRevisionPriceLoadMessage] = useState({ data: null, revisionPriceLoad: 0 });

	// Disable web notifications for all native mobile pages
	const disableNotifications = window?.location?.href?.includes("/native/");

	const subAccounts = useMemo(() => {
		return JSON.parse(localStorage.getItem("subAccounts"));
	}, []);

	// Checking notification permission
	useMemo(() => {
		requestBrowserNotificationPermission();
	}, []);

	// Disable websocket for all native mobile pages (except for chat)
	const disableWebsocket =
		window?.location?.href?.includes("/native/") && !window?.location?.href?.includes("CHAT_APP");

	const notifications = useSelector(({ messenger }) => messenger.messages.notifications);
	useEffect(() => {
		if (disableNotifications) {
			return;
		}
		if (!notifications?.length) {
			return;
		}
		let ids = [];
		notifications?.forEach((data) => {
			ids.push(data._id);
			// Showing only incomient message
			if (data.user !== userId) {
				showMessageSnackbar(snackbar, data);
				showBrowserNotification(data);
			}
		});

		dispatch(removeNotificaions(ids));
	}, [disableNotifications, notifications, snackbar, userId, dispatch]);

	useEffect(() => {
		if (!revisionPriceLoadMessage?.data) return;
		const suggestLoadFormOpened = formStack?.filter(
			(form) => form?.dataIds?.loadId === revisionPriceLoadMessage.data && form?.viewId === "LOAD_SUGGEST_VIEW"
		)?.[0];
		if (!!suggestLoadFormOpened) {
			dispatch(
				replaceFormDialog({
					...suggestLoadFormOpened,
					dataIds: {
						...suggestLoadFormOpened.dataIds,
						revisionPriceLoad: revisionPriceLoadMessage.revisionPriceLoad,
					},
				})
			);
			showSnackbar(
				snackbar,
				`The information of load (SmartHop Load ID: ${revisionPriceLoadMessage?.data}) has been updated.`,
				"warning",
				{ persist: true }
			);
		}
		return () => setRevisionPriceLoadMessage({ data: null, revisionPriceLoad: 0 });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [revisionPriceLoadMessage]);

	useEffect(() => {
		if (disableWebsocket) {
			return;
		}
		if (!socket) {
			dispatch(initConnection());
			return;
		}

		if (!token) {
			if (socket.connected) {
				console.log("[ConnectionHandler] disconnecting socket...");
				socket.disconnect();
			}
			return;
		}

		// Subscibe for event
		socket.on(_CONNECT, () => {
			console.log("[ConnectionHandler] connected");
			dispatch(setConnected(true));

			console.log("[ConnectionHandler] sending auth token...");
			socket.emit(_AUTHENTICATE, { token: token });
		});
		socket.on(_DISCONNECT, (msg) => {
			console.error(`[ConnectionHandler] disconnected, error '${msg}'`);
			dispatch(setConnected(false));
			dispatch(setAuthenticated(false));
			if (msg === "io server disconnect") {
				// Connection killed from the server side, need to re-conncet manually, auto-reconnect
				// won't work in case of this error, usually this happens when auth was not send to
				// the server specified timeout on the server
				socket.connect();
			}
		});
		socket.on(_UNAUTHORIZED, () => {
			console.error("[ConnectionHandler] unathorized");
		});
		socket.on(_RECONNECT, (msg) => {
			console.log(`[ConnectionHandler] reconnected, attempt ${msg}`);
			dispatch(setConnected(true));
		});
		socket.on(_RECONNECTING, (msg) => {
			console.warn(`[ConnectionHandler] reconnecting, attempt ${msg}`);
		});
		socket.on(_AUTHENTICATED, () => {
			console.log("[ConnectionHandler] authenticated");
			dispatch(setAuthenticated(true));
			dispatch(setConnected(true));
			dispatch(fetchChats({ userId }));
			dispatch(fetchTransactions({ userId }));
			dispatch(fetchFiles());
			if (roleExternal && !subAccounts?.length > 0) {
				dispatch(fetchCredentialsStatus({ carrierId }));
			}
			if (userIsCarrier) {
				dispatch(fetchActions({ carrierId }));
			}
		});
		socket.on(_MESSAGE, (msg) => {
			console.log("[ConnectionHandler] received message", msg);
			if (msg.type === "PING") {
				showSnackbar(snackbar, "Ping: " + (msg.data?.message ?? "-"), "info");
			} else if (msg.type === "ERROR") {
				let errors = msg.data?.errors;
				let message = errors && errors[0] ? errors[0].message : "Oops...";
				quickSnackbar(snackbar, message, "error");
			} else if (msg.type === "MESSAGE_CREATED") {
				dispatch(addMessage(msg.data));
			} else if (msg.type === "CHAT_REFRESH") {
				dispatch(fetchChats({ userId }));
			} else if (msg.type === "MESSAGE_UPDATED") {
				dispatch(updateMessage(msg.data));
			} else if (msg.type === "CHAT_UPDATED") {
				dispatch(addChat(msg.data));
			} else if (msg.type === "UPDATE_TRANSACTION") {
				dispatch(fetchTransactions({ userId }));
			} else if (msg.type === "LOAD_PRICE_CHANGED") {
				setRevisionPriceLoadMessage((state) => ({ data: msg.data, revisionPriceLoad: state.revisionPriceLoad + 1 }));
			} else if (msg.type === "UPDATE_UPLOAD_LIST") {
				dispatch(fetchFiles());
				dispatch(incrementDataRevision({ event: "tripsRevision" }));
			} else if (msg.type === "SEARCH_FOUND_NEW") {
				console.log(
					`[SEARCH][ConnectionHandler] Message SEARCH_FOUND_NEW received, new loads ${msg.data.new} for search ${msg.data.searchId}`
				);
				dispatch(fetchSearchResults({ searchId: msg.data.searchId, userId }));
			} else if (msg.type === "SEARCH_FOUND_NEW_PAYLOAD") {
				console.log(
					`[SEARCH][ConnectionHandler] Message SEARCH_FOUND_NEW_PAYLOAD received, new loads ${msg.data.new} for search ${msg.data.searchId}`
				);
				dispatch(
					addSearchStatistic({
						searchId: msg.data.searchId,
						statName: "new",
						value: msg.data.new,
						userId,
						data: msg.data.payload,
					})
				);
			} else if (msg.type === "SEARCH_STATE_CHANGED") {
				console.log(
					`[SEARCH][ConnectionHandler] Message SEARCH_STATE_CHANGED received for search ${msg.data.searchId}, state ${
						msg.data.state
					} ${msg.data.processing ? "(Processing)" : ""}`
				);
				dispatch(
					setSearchParams({
						searchId: msg.data.searchId,
						searchState: msg.data.state,
						processing: msg.data.processing,
						versions: msg.data.versions,
						pipelineErrors: msg.data.pipelineErrors,
						pipelineWarnings: msg.data.pipelineWarnings,
						pipelinesStates: msg.data.pipelinesStates,
					})
				);
			} else if (msg.type === "SEARCH_LOADS_COVERED") {
				console.log(
					`[SEARCH][ConnectionHandler][checkLoadAvailability] Message SEARCH_LOADS_COVERED received for search ${
						msg.data.searchId
					}, ${Object.keys(msg.data.loads)?.length} covered loads `,
					msg.data
				);
				dispatch(coverLoads({ searchId: msg.data.searchId, loads: msg.data.loads }));
			} else if (msg.type === "SEARCH_LOADS_UPDATED") {
				console.log(
					`[SEARCH][ConnectionHandler] Message SEARCH_LOADS_UPDATED received for search ${msg.data.searchId}, ${
						Object.keys(msg.data.loads)?.length
					} updated loads `,
					msg.data
				);
				dispatch(updateLoads({ searchId: msg.data.searchId, loads: msg.data.loads }));
			} else if (msg.type === "UPDATED_FILE_LIST") {
				dispatch(incrementDataRevision({ event: "tripsRevision" }));
				dispatch(incrementDataRevision({ event: "filesRevision" }));
			} else if (msg.type === "SEARCH_NEW_PAGE") {
				console.log(
					`[SEARCH][ConnectionHandler] Message SEARCH_NEW_PAGE received for search ${msg.data.searchId}, ${
						Object.keys(msg.data.loads)?.length
					} loads `,
					msg.data
				);
				dispatch(
					addSearchStatistic({
						searchId: msg.data.searchId,
						data: msg.data.loads,
					})
				);
			} else if (msg.type === "SEARCH_QA_ACK") {
				console.log(
					`[SEARCH][ConnectionHandler] Message SEARCH_QA_ACK ${msg.data.type} received`,
					msg.data.message,
					msg.data
				);
				if (msg.data.type === "SEARCH_PULSE") {
					const searches = msg.data.searches;
					searches.forEach((search) => {
						const searchId = search.searchId;
						let stats = { ...search };
						delete stats.searchId;
						dispatch(
							addSearchMultipleStatistics({
								searchId,
								stats,
							})
						);
					});
				}
			}
		});

		if (!socket.connected) {
			// Trigger connect when socket is not connected
			console.log("[ConnectionHandler] connecting... ");
			socket.connect();
		} else if (!isAuthenticated) {
			// Trigger re-connect when socket is invalid state, connected but not authenticated
			console.log("[ConnectionHandler] authentificating...");
			socket.emit(_AUTHENTICATE, { token: token });
		}

		return () => {
			// Unsubscribe from all events
			socket.disconnect();
			socket.off(_CONNECT);
			socket.off(_DISCONNECT);
			socket.off(_UNAUTHORIZED);
			socket.off(_RECONNECT);
			socket.off(_RECONNECTING);
			socket.off(_AUTHENTICATED);
			socket.off(_MESSAGE);
		};
		// eslint-disable-next-line
	}, [token, socket]);

	return props.children;
}

export default ConnectionHandler;
