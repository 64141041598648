import { createRequest, throwResErrors, createHeaders } from "./requestUtil";

export const getCsvBrokers = () => {
	return createRequest()
		.get("api/brokers/download", { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const uploadCsvAllBrokers = (data) => {
	return createRequest()
		.post("api/brokers/upload", data, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const updateBrokerFromSearch = (data) => {
	return createRequest()
		.put(`api/brokers/${data._id}/details/search`, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const saveBrokerTripCarrier = (data) => {
	return createRequest()
		.post(`api/brokers/create`, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getBrokerInformation = (brokerId, carrierId) => {
	let url = "api/";
	if (brokerId && carrierId) {
		url = `${url}customers/${brokerId}`;
	} else if (brokerId) {
		url = `${url}brokers/${brokerId}/details/information`;
	}
	return createRequest()
		.get(url, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const saveBrokerInformation = (brokerId, carrierId, data) => {
	let url = "api/";
	if (brokerId && carrierId) {
		url = `${url}customers/${brokerId}/carrier/${carrierId}`;
	} else if (brokerId) {
		url = `${url}brokers/${brokerId}/details/information`;
	}

	return createRequest()
		.put(url, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const createBroker = (data) => {
	let url = "api/";
	if (!data.carrier) {
		url = `${url}brokers/details/information`;
	} else {
		url = `${url}customers/carrier/${data.carrier}`;
	}

	return createRequest()
		.post(url, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const getBrokerSearch = (mcnumber, carrier) => {
	return createRequest()
		.get("api/customers/search/broker", { params: { mcnumber, carrier }, headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const deleteCustomer = (customerId) => {
	return createRequest()
		.delete(`api/customers/${customerId}`, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const createInternalLoads = (data) => {
	let url = "api/brokers/loads/create";
	return createRequest()
		.post(url, { data }, { headers: createHeaders() })
		.then((res) => res.data)
		.catch((error) => throwResErrors(error));
};

export const initiatePhoneCall = async (userId, contactNumber, loadId, carrierId, searchId, truckId) => {
	try {
		const request = await createRequest();
		const response = await request.post(
			"api/communications/initiate-call",
			{ userId, contactNumber, loadId, carrierId, searchId, truckId },
			{ headers: createHeaders() }
		);
		return response.data;
	} catch (err) {
		return throwResErrors(err, "initiatePhoneCall");
	}
};
