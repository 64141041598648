import { isEnabled } from "app/services/featureStorageService";

const SECTION_SEARCH_TITLE = {
	type: "section",
	label: "",
	section: { classes: { label: "mt-32" } },
};

const SECTION_TRUCK_TITLE = {
	type: "section",
	label: "Truck",
	section: { classes: { label: "mt-6" } },
};

const SECTION_TRUCK_SUBTITLE = {
	type: "message",
	label: "Please select existing truck or create new one",
	message: { color: "textSecondary", classes: { root: "-mt-16" } },
};

const SECTION_TRAILER_TITLE = {
	type: "section",
	label: "Trailer",
	section: { classes: { label: "mt-6" } },
};

const SECTION_TRAILER_SUBTITLE = {
	type: "message",
	label:
		"Default filters selected from configuration of the selected Truck's Trailer specified in your profile, you can always override these filters, to see more loads.",
	message: { color: "textSecondary", classes: { root: "-mt-16" } },
};

const SECTION_DRIVER_TITLE = {
	type: "section",
	label: "Driver",
	section: { classes: { label: "mt-6" } },
};

const SECTION_DRIVER_SUBTITLE = {
	type: "message",
	label:
		"Default filters selected from configuration of the Driver assigned to the selected Truck specified in your profile, you can always override these filters, to see more loads",
	message: { color: "textSecondary", classes: { root: "-mt-16" } },
};

const SECTION_TRIP_TITLE = {
	type: "section",
	label: "Origin",
	section: { classes: { label: "mt-6" } },
};

const SECTION_ROUTE_TITLE = {
	type: "section",
	label: "Route",
	section: { classes: { label: "mt-6" } },
};

const SECTION_TRIP_SUBTITLE = {
	type: "message",
	label: "Configure your trip origin details to get more specific search results",
	message: { color: "textSecondary", classes: { root: "-mt-16" } },
};

const GENERIC_TRUCKS_SPECIAL = [
	{
		value: "ANY_SPECIAL_Tanker",
		label: "Tanker",
		description: "All Tanker Loads",
		metadata: {
			truck: {
				equipment: "SPECIAL",
				max_weight: 70000,
				load_type: "FULL",
				trailer: 53,
				subtype: "Tanker",
			},
		},
		icon: "local_shipping",
		selectable: true,
	},
	{
		value: "ANY_SPECIAL_Deck",
		label: "Deck",
		description: "All Deck Loads",
		metadata: {
			truck: {
				equipment: "SPECIAL",
				max_weight: 55000,
				load_type: "FULL",
				trailer: 53,
				subtype: "Deck",
			},
		},
		icon: "local_shipping",
		selectable: true,
	},
	{
		value: "ANY_SPECIAL_HotShot",
		label: "HotShot",
		description: "All HotShot Loads",
		metadata: {
			truck: {
				equipment: "SPECIAL",
				max_weight: 30000,
				load_type: "FULL",
				trailer: 53,
				subtype: "HotShot",
			},
		},
		icon: "local_shipping",
		selectable: true,
	},
	{
		value: "ANY_SPECIAL_Conestoga",
		label: "Conestoga",
		description: "All Conestoga Loads",
		metadata: {
			truck: {
				equipment: "SPECIAL",
				max_weight: 48000,
				load_type: "FULL",
				trailer: 53,
				subtype: "Conestoga",
			},
		},
		icon: "local_shipping",
		selectable: true,
	},
	{
		value: "ANY_SPECIAL_Cargo Van",
		label: "Cargo Van",
		description: "All Cargo Van Loads",
		metadata: {
			truck: {
				equipment: "SPECIAL",
				max_weight: 7500,
				load_type: "BOTH",
				trailer: 15,
				subtype: "Cargo Van",
			},
		},
		icon: "local_shipping",
		selectable: true,
	},
	{
		value: "ANY_SPECIAL_Box Truck",
		label: "Box Truck",
		description: "All Box Truck Loads",
		metadata: {
			truck: {
				equipment: "SPECIAL",
				max_weight: 26000,
				load_type: "BOTH",
				trailer: 26,
				subtype: "Box Truck",
			},
		},
		icon: "local_shipping",
		selectable: true,
	},
];

const SECTION_PREFERENCES_TITLE = (onSave, onResetPref) => {
	const actions = [];
	if (onSave) {
		actions.push({
			label: "Update Plan",
			onClick: onSave,
			description: "Save search preferences to Truck Plan",
		});
	}

	if (onResetPref) {
		actions.push({
			icon: "replay",
			onClick: onResetPref,
		});
	}

	return {
		type: "section",
		label: "Preferences",
		section: { classes: { label: "mt-6" } },
		actions: actions,
	};
};

const SECTION_PREFERENCES_SUBTITLE = {
	type: "message",
	label: "Edit your preferences to quickly narrow down the search results",
	message: { color: "textSecondary", classes: { root: "-mt-16" } },
};

const SECTION_FILTERS_TITLE = {
	type: "section",
	label: "Filters",
	section: { classes: { label: "mt-6" } },
};

const SECTION_FILTERS_SUBTITLE = {
	type: "message",
	label: "Edit filters to narrow down or expand the search results",
	message: { color: "textSecondary", classes: { root: "-mt-16" } },
};

const SECTION_DESTINATION_TITLE = {
	type: "section",
	label: "Destination",
	section: { classes: { label: "mt-6" } },
};

const SECTION_DESTINATION_SUBTITLE = {
	type: "message",
	label: "Configure your trip destination to arrive to a specific place in the end of your trip",
	message: { color: "textSecondary", classes: { root: "-mt-16" } },
};

const SECTION_INSTANT_BOOKING_TITLE = {
	type: "section",
	label: "Instant Booking",
	section: { classes: { label: "mt-6" } },
};

const SECTION_INSTANT_BOOKING_SUBTITLE = {
	type: "message",
	label: "Fully digital experience, get loads booked in a single click",
	message: { color: "textSecondary", classes: { root: "-mt-16" } },
};

const SECTION_INSTANT_BOOKING_BOOK_NOW_SUBTITLE = {
	type: "message",
	label: "Click to see only loads that can be booked isntantly",
	message: { color: "textSecondary", classes: { root: "-mt-16" } },
};

const SECTION_INSTANT_BOOKING_BID_NOW_SUBTITLE = {
	type: "message",
	label:
		"Click to see only loads that allow instant digital negotiation, submit your rate, and if your rate is accepted load will get booked automatically",
	message: { color: "textSecondary", classes: { root: "-mt-16" } },
};

const CARRIER_ASSIGNED = (internalDispatcher, showMC = false) => ({
	key: "carrier",
	type: "autocomplete",
	label: "Carrier",
	required: true,
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/carriers",
		preloadDefault: true,
		preloadImmedately: true,
		listenEvent: "profileRevision",
		params: {
			options_active_only: true,
			options_onboarding_completed: true,
			options_my_assigned: internalDispatcher,
			options_disabled_search_trial_expired: true,
		},
		showDescription: showMC,
	},
});

const CARRIER_ALL = (showMC) => ({
	key: "carrier",
	type: "autocomplete",
	label: "Carrier",
	required: true,
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/carriers",
		preloadDefault: true,
		preloadImmedately: true,
		listenEvent: "profileRevision",
		params: {
			options_active_only: true,
			options_onboarding_completed: true,
		},
		showDescription: showMC,
	},
});

const CARRIER_TRUCKS = (isDispatcher = false, isInvestor = false, showCapacity = false) => {
	return {
		key: "truck",
		type: "autocomplete",
		label: "Truck",
		required: true,
		field: {
			noErrorMessage: true,
			classes: {},
			variant: undefined,
		},
		autocomplete: {
			provider: "smarthop",
			url: "api/profile/carriers/:carrierId/trucks",
			params: {
				options_search_truck_plan: true,
				options_metadata_truck: true,
				options_metadata_driver: true,
				options_active_only: true,
				label_format_show_driver: true,
				options_format_show_capacity_type: showCapacity,
				options_metadata_investor: true,
			},
			preloadDefault: true,
			preloadImmedately: true,
			listenEvent: "profileRevision",
			resource: "SEARCH_TRUCK_POPUP",
			showDescription: showCapacity,
			actionOptions: [
				{
					value: "ANY_REEFER",
					label: "Reefer",
					description: "All Reefer Loads",
					metadata: {
						truck: {
							equipment: "REEFER",
							max_weight: 44000,
							load_type: "FULL",
							trailer: 53,
						},
					},
					icon: "local_shipping",
					selectable: true,
				},
				{
					value: "ANY_VAN",
					label: "Van",
					description: "All Van Loads",
					metadata: {
						truck: {
							equipment: "VAN",
							max_weight: 45000,
							load_type: "FULL",
							trailer: 53,
						},
					},
					icon: "local_shipping",
					selectable: true,
				},
				{
					value: "ANY_FLATBED",
					label: "Flatbed",
					description: "All Flatbed Loads",
					metadata: {
						truck: {
							equipment: "FLATBED",
							max_weight: 48000,
							load_type: "FULL",
							trailer: 53,
						},
					},
					icon: "local_shipping",
					selectable: true,
				},
				{
					value: "ANY_PO",
					label: "Power Only",
					description: "All Power Only Loads",
					metadata: {
						truck: {
							equipment: "PO",
						},
					},
					icon: "local_shipping",
					selectable: true,
				},
				...(isEnabled("ENABLE_SPECIAL_EQUIPMENTS") ? GENERIC_TRUCKS_SPECIAL : []),
				{
					value: "ADD_NEW_TRUCK",
					label: "Add New Truck",
					icon: "add",
					disabled: isDispatcher || isInvestor ? true : false,
					dialog: { viewId: "TRUCK_VIEW", dataIds: { mode: "CREATE" }, mode: "CREATE" },
				},
			],
		},
	};
};

const DRIVER_TRUCKS = {
	key: "truck",
	type: "autocomplete",
	label: "Truck",
	required: true,
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/carriers/:carrierId/drivers/:driverId/trucks",
		params: {
			options_metadata_truck: true,
			options_metadata_driver: true,
			options_active_only: true,
			label_format_show_driver: true,
		},
		preloadDefault: true,
		preloadImmedately: true,
		listenEvent: "profileRevision",
	},
};

const TRUCK_ASSIGNED = (internalDispatcher, showCapacity, isMainHandler = false) => ({
	key: "truck",
	type: "autocomplete",
	label: "Truck",
	required: true,
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/trucks",
		params: {
			options_search_truck_plan: true,
			options_metadata_truck: true,
			options_metadata_driver: true,
			options_active_only: true,
			label_format_show_driver: true,
			options_my_assigned: !internalDispatcher ? false : true,
			options_format_show_capacity_type: showCapacity,
			options_metadata_investor: true,
		},
		preloadDefault: true,
		preloadImmedately: true,
		listenEvent: "profileRevision",
		resource: "SEARCH_TRUCK_POPUP",
		showDescription: showCapacity,
		actionOptions: [
			...(isMainHandler
				? [
						{
							value: "ANY_REEFER",
							label: "Reefer",
							description: "All Reefer Loads",
							metadata: {
								truck: {
									equipment: "REEFER",
									max_weight: 55000,
									load_type: "FULL",
									trailer: 53,
								},
							},
							icon: "local_shipping",
							selectable: true,
						},
						{
							value: "ANY_VAN",
							label: "Van",
							description: "All Van Loads",
							metadata: {
								truck: {
									equipment: "VAN",
									max_weight: 55000,
									load_type: "FULL",
									trailer: 53,
								},
							},
							icon: "local_shipping",
							selectable: true,
						},
						{
							value: "ANY_FLATBED",
							label: "Flatbed",
							description: "All Flatbed Loads",
							metadata: {
								truck: {
									equipment: "FLATBED",
									max_weight: 55000,
									load_type: "FULL",
									trailer: 53,
								},
							},
							icon: "local_shipping",
							selectable: true,
						},
						{
							value: "ANY_PO",
							label: "Power Only",
							description: "All Power Only Loads",
							metadata: {
								truck: {
									equipment: "PO",
								},
							},
							icon: "local_shipping",
							selectable: true,
						},
						...(isEnabled("ENABLE_SPECIAL_EQUIPMENTS") ? GENERIC_TRUCKS_SPECIAL : []),
				  ]
				: [{}]),
		],
	},
	rules: {
		params: {
			valueRef: "carrier",
			paramKey: "options_carrier_id_search",
		},
	},
});

const TRUCK_ALL = (showCapacity) => ({
	key: "truck",
	type: "autocomplete",
	label: "Truck",
	required: true,
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/trucks",
		params: {
			options_metadata_truck: true,
			options_search_truck_plan: true,
			options_metadata_driver: true,
			options_active_only: true,
			label_format_show_driver: true,
			options_format_show_capacity_type: showCapacity,
			options_metadata_investor: true,
		},
		preloadDefault: true,
		preloadImmedately: true,
		listenEvent: "profileRevision",
		showDescription: showCapacity,
	},
	rules: {
		params: {
			valueRef: "carrier",
			paramKey: "options_carrier_id_search",
		},
	},
});

const DRIVER_MY = {
	key: "driver",
	type: "autocomplete",
	label: "Driver",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/profile/carriers/:carrierId/drivers",
		params: { options_metadata_driver: true, options_active_only: true },
		preloadDefault: true,
		listenEvent: "profileRevision",
		actionOptions: [
			{
				value: "ADD_NEW_DRIVER",
				label: "Add New Driver",
				icon: "add",
				dialog: {
					viewId: "CARRIER_USER_EDIT_VIEW",
					mode: "CREATE",
					dataIds: { origin: "DRIVER" },
				},
			},
		],
	},
};

const DRIVER_VIEW = {
	key: "driver",
	type: "autocomplete",
	label: "Driver Name",
	viewOnly: true,
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	autocomplete: {},
};

const LOCATION_ORIGIN = {
	key: "location_origin",
	type: "autocomplete",
	label: "Origin",
	required: true,
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "trimblemaps",
		formatST: true,
		params: {
			componentRestrictions: { country: "us", includeOnly: "city" },
			types: ["(cities)"],
		},
		multiple: false,
		anyValue: false,
		icon: "location",
	},
};

const DH_ORIGIN = {
	key: "dh_origin",
	type: "slider",
	defaultValue: 250,
	label: "DH-Origin (Maximum)",
	slider: {
		type: "input",
		prefix: "",
		postfix: " mi",
		step: 10,
		min: 1,
		max: 500,
		noErrorMessage: true,
		classes: { root: "-mt-2" },
		variant: undefined,
	},
};

const LOCATION_DESTINATION = {
	key: "location_destination",
	type: "autocomplete",
	label: "Destination",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "trimblemaps",
		formatST: true,
		params: {
			componentRestrictions: { country: "us", includeOnly: "city" },
			types: ["(cities)"],
		},
		multiple: false,
		anyValue: false,
		icon: "location",
	},
	rules: {
		disabled: {
			ifNotEmptyRef: "destinationStates",
		},
	},
};

const LOCATION_MULTI_DESTINATION = {
	key: "location_destination",
	type: "autocomplete",
	label: "Multi Destination",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "trimblemaps",
		formatST: true,
		params: {
			componentRestrictions: { country: "us", includeOnly: "city" },
			types: ["(cities)"],
		},
		multiple: true,
		anyValue: false,
		icon: "location",
	},
};

const DH_DESTINATION = {
	key: "dh_destination",
	type: "slider",
	label: "DH-Destination (Maximum)",
	defaultValue: 250,
	slider: {
		type: "input",
		postfix: " mi",
		step: 10,
		min: 1,
		max: 500,
		noErrorMessage: true,
		classes: { root: "-mt-2" },
		variant: undefined,
	},
	rules: {
		disabled: {
			ifEmptyRef: "location_destination",
		},
	},
};

const DESTINATION_STATES = {
	key: "destinationStates",
	type: "autocomplete",
	label: "Destination States",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/consts/states",
		types: [],
		multiple: true,
		anyValue: false,
		preloadDefault: true,
		icon: "location",
		maxValues: 7,
	},
	rules: {
		disabled: {
			ifNotEmptyRef: "location_destination",
		},
	},
};

const PICKUP_AFTER_DS = {
	key: "pickup_ds__after",
	type: "picker",
	label: "Available",
	required: true,
	defaultValue: "NOW",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	input: {
		classes: {},
	},
	picker: {
		disablePast: true,
		notClearable: true,
		type: "date",
		classes: {},
	},
};

const PICKUP_BEFORE_DS = {
	key: "pickup_ds__before",
	type: "picker",
	label: "Latest Pick Up Date",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	picker: {
		disablePast: true,
		type: "date",
		classes: {},
	},
};

const DELIVERY_AFTER_DS = {
	key: "delivery_ds__after",
	type: "picker",
	label: "Delivery After",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	picker: {
		disablePast: true,
		type: "date",
		classes: {},
	},
	rules: {
		disabled: {
			ifEmptyRef: "location_destination",
		},
	},
};

const DELIVERY_BEFORE_DS = {
	key: "delivery_ds__before",
	type: "picker",
	label: "Delivery Before",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	picker: {
		disablePast: true,
		type: "date",
		classes: {},
	},
	rules: {
		disabled: {
			ifEmptyRef: "location_destination",
		},
	},
};

const RATE_TOTAL_PREFERRED = {
	key: "rate_total_preferred",
	type: "slider",
	label: "Rate Total (Minimum)",
	defaultValue: 0,
	slider: {
		inverted: true,
		type: "input",
		prefix: "$",
		postfix: "+",
		step: 5,
		min: 0,
		max: 5000,
		noErrorMessage: true,
		classes: { root: "-mt-2" },
		variant: undefined,
	},
};

const RATE_PER_MILE_PREFERRED = {
	key: "rate_per_mile_preferred",
	type: "slider",
	label: "Rate Per Mile (Minimum)",
	defaultValue: 0,
	slider: {
		inverted: true,
		type: "input",
		prefix: "$",
		postfix: "+",
		step: 0.1,
		min: 0,
		max: 5,
		noErrorMessage: true,
		classes: { root: "-mt-2" },
		variant: undefined,
	},
};

const MILES_PREFERRED = {
	key: "miles_preferred",
	type: "slider",
	label: "Trip Miles + DH (Range)",
	defaultValueMin: 0,
	defaultValueMax: 4000,
	slider: {
		type: "range",
		postfix: " mi",
		min: 0,
		max: 4000,
		step: 50,
		noErrorMessage: true,
		classes: { root: "-mt-2" },
		variant: undefined,
	},
};

const PROFIT_PREFERRED = {
	key: "profit_preferred",
	type: "slider",
	label: "Profit (Minimum)",
	defaultValue: -1000,
	slider: {
		builder: (value) => {
			if (value === -1000) {
				return { label: "Any" };
			} else if (value < 0) {
				return { label: "-$" + value * -1, classes: "text-red-600" };
			} else {
				return { label: "$" + value + "+", classes: "text-green-600" };
			}
		},
		inverted: true,
		type: "input",
		prefix: "$",
		step: 25,
		min: -1000,
		max: 1000,
		noErrorMessage: true,
		classes: { root: "-mt-2" },
		variant: undefined,
	},
};

const maxAgeValue = 2 * 60;
const AGE_PREFERRED = {
	key: "age_mins_max_preferred",
	type: "slider",
	label: "Age (Maximum)",
	defaultValue: maxAgeValue,
	slider: {
		type: "input",
		postfix: " min",
		builder: (value) => {
			if (value === maxAgeValue) return { label: "2h+" };
			if (value < 10) return { label: value + "m" };
			let mins = parseInt(value % 60);
			let hourse = parseInt((value / 60) % 24);
			let days = parseInt(value / 60 / 24);
			let label =
				(days > 0 ? days + "d:" : "") +
				(hourse > 0 || days > 0 ? hourse + "h:" : "") +
				(mins < 10 ? "0" : "") +
				mins +
				"m";
			return { label };
		},
		min: 1,
		max: maxAgeValue,
		step: 1,
		scale: (x) => Math.exp(x),
		noErrorMessage: true,
		classes: { root: "-mt-2" },
		variant: undefined,
	},
};

const MIN_RANK_MCI = {
	key: "min_rank_mci",
	type: "slider",
	label: "MPI (Minimum)",
	defaultValue: 0,
	slider: {
		inverted: true,
		type: "input",
		prefix: "",
		postfix: "+",
		step: 5,
		min: 0,
		max: 100,
		noErrorMessage: true,
		classes: { root: "-mt-2" },
		variant: undefined,
	},
};

const MIN_CREDIT_SCORE = {
	key: "min_credit_score",
	type: "slider",
	label: "Broker Credit Score (Minimum)",
	defaultValue: 0,
	slider: {
		inverted: true,
		type: "input",
		prefix: "",
		postfix: "+",
		step: 1,
		min: 0,
		max: 100,
		noErrorMessage: true,
		classes: { root: "-mt-2" },
		variant: undefined,
		builder: (value) => {
			if (value === 0) {
				return { label: "Any" };
			} else if (value < 67) {
				return { label: value, value: value, classes: "text-red-600" };
			} else if (value < 87) {
				return { label: value, value: value, classes: "text-orange-600" };
			} else {
				return { label: value, value: value, classes: "text-green-600" };
			}
		},
	},
};

const EXTRA_STOPS_PREFERRED = {
	key: "extra_stops_preferred",
	type: "slider",
	label: "Extra Stops (Maximum)",
	defaultValue: 5,
	slider: {
		inverted: false,
		type: "input",
		step: 1,
		min: 0,
		max: 5,
		noErrorMessage: true,
		classes: { root: "-mt-2" },
		variant: undefined,
		builder: (value) => {
			if (value === 5) {
				return { label: "5+" };
			} else {
				return { label: value, value: value };
			}
		},
	},
};

const DRIVER_AVAILABLE_HOURSE = {
	key: "driver_daily_hos",
	type: "number",
	label: "HOS Daily",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
};

const DRIVER_WEEKLY_HOURSE = {
	key: "driver_weekly_hos",
	type: "number",
	label: "HOS Weekly",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
};

const DRIVER_BLAKLISTED_STATES = {
	key: "blacklisted_states_ids",
	type: "autocomplete",
	label: "Blacklist State",
	translate: "BLACKLISED_STATES",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	autocomplete: {
		provider: "smarthop",
		url: "api/consts/states",
		types: [],
		multiple: true,
		anyValue: false,
		preloadDefault: true,
		icon: "location",
	},
};

const TRAILER_TYPE = {
	key: "trailer",
	type: "select",
	label: "Length",
	translate: "TYPE",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	options: [
		{
			value: 53,
			label: "53'",
			translate: "53_WEDGE",
		},
		{
			value: 48,
			label: "48'",
			translate: "48_WEDGE",
		},
		{
			value: 45,
			label: "45'",
			translate: "45_WEDGE",
		},
		{
			value: 28,
			label: "28'",
			translate: "28_WEDGE",
		},
		{
			value: 26,
			label: "26'",
			translate: "26_WEDGE",
		},
		{
			value: 15,
			label: "15'",
			translate: "15_WEDGE",
		},
	],
};

const TRAILER_MAX_WEIGHT = {
	key: "max_weight",
	type: "number",
	label: "Max Weight",
	translate: "MAX_WEIGHT",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
};
const TRAILER_SUBTYPE = {
	key: "subtype",
	type: "select",
	label: "Subtype",
	required: true,
	options: [
		{
			value: "Box Truck",
			label: "Box Truck",
		},
		{
			value: "Cargo Van",
			label: "Cargo Van",
		},
		{
			value: "Conestoga",
			label: "Conestoga",
		},
		{
			value: "Deck",
			label: "Deck",
		},
		{
			value: "HotShot",
			label: "HotShot",
		},
		{
			value: "Tanker",
			label: "Tanker",
		},
	],
};

const SEARCH_TRAILER_FIELDS = [
	{
		type: "group",
		content: {
			items: [TRAILER_TYPE, TRAILER_MAX_WEIGHT],
		},
	},
];

const SEARCH_TRAILER_SPECIAL_FIELDS = [
	TRAILER_SUBTYPE,
	{
		type: "group",
		content: {
			items: [TRAILER_TYPE, TRAILER_MAX_WEIGHT],
		},
	},
];

const TRAILER_EQUIPMENT = {
	key: "equipment",
	type: "select",
	label: "Equipment",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	options: [
		{
			value: "VAN",
			label: "Van",
			translate: "VAN",
			showItems: SEARCH_TRAILER_FIELDS,
		},
		{
			value: "REEFER",
			label: "Reefer",
			translate: "REEFER",
			showItems: SEARCH_TRAILER_FIELDS,
		},
		{
			value: "FLATBED",
			label: "Flatbed",
			showItems: SEARCH_TRAILER_FIELDS,
		},
		{
			value: "PO",
			label: "Power Only",
		},
		...(isEnabled("ENABLE_SPECIAL_EQUIPMENTS") ? GENERIC_TRUCKS_SPECIAL : []),
	],
};

const SECTION_SYSTEM_TITLE = {
	type: "section",
	label: "System",
	section: { classes: { label: "mt-6" } },
};

const SECTION_SYSTEM_SUBTITLE = {
	type: "message",
	label: "System config allows to access additional information",
	message: { color: "textSecondary", classes: { text: "-mt-16" } },
};

const SYSTEM_SHOW_DETAILED_CONTENT = {
	key: "system_show_system_info",
	type: "checkbox",
	label: "Show system information",
	checkbox: { noErrorMessage: true, classes: { root: " -mt-1 " } },
};

const SYSTEM_ON_DEMAND_RESULT_ONLY = {
	key: "system_show_ondeman_only",
	type: "checkbox",
	label: "Show only on-demand loads",
	checkbox: { noErrorMessage: true, classes: { root: " -mt-1 " } },
};

const SYSTEM_SHOW_ESTIMATE_RATES = {
	key: "system_show_estimate_rates",
	type: "checkbox",
	label: "Show estimated rates column",
	checkbox: { noErrorMessage: true, classes: { root: " -mt-1 " } },
};

const SYSTEM_SHOW_OUTLIERS_ONLY = {
	key: "system_show_outliers_only",
	type: "checkbox",
	label: "Show only outlier loads",
	checkbox: { noErrorMessage: true, classes: { root: " -mt-1 " } },
};

const SYSTEM_BID_NOW_RESULT_ONLY = {
	key: "system_show_bid_now_only",
	type: "checkbox",
	label: "Book/Bid Online - Show loads that can be booked digitally ",
	checkbox: { noErrorMessage: true, classes: { root: " -mt-1 " } },
};

const SYSTEM_SHOW_FLEXIBLE = {
	key: "system_hide_all_flexible",
	type: "checkbox",
	label: "Hide all flexible loads",
	checkbox: { noErrorMessage: true, classes: { root: " -mt-1 " } },
};

const SYSTEM_SHOW_ONLY_ONBOARDED = {
	key: "system_show_onboarded_only",
	type: "checkbox",
	label: "Show only loads from my preferred brokers",
	checkbox: { noErrorMessage: true, classes: { root: " -mt-1 " } },
};

const SYSTEM_SHOW_TEAM_LOADS_ONLY = {
	key: "system_show_team_loads_only",
	type: "checkbox",
	label: "Show only team loads",
	checkbox: { noErrorMessage: true, classes: { root: " -mt-1 " } },
};

const SYSTEM_SHOW_SPECIAL_LOAD = {
	key: "system_show_special_loads_only",
	type: "checkbox",
	label: "Show only special loads ",
	checkbox: { noErrorMessage: true, classes: { root: " -mt-1 " } },
};

const SYSTEM_SHOW_WITH_PRICE_ONLY = {
	key: "system_show_with_price_only",
	type: "checkbox",
	label: "Show only loads with rates",
	checkbox: { noErrorMessage: true, classes: { root: " -mt-1 " } },
};

const SYSTEM_SPECIFIC_OWNERS_ONLY = {
	key: "system_show_specific_owners_only",
	type: "autocomplete",
	label: "Loads Source",
	translate: "ONBOARDED_BROKERS",
	disabled: "",
	autocomplete: {
		provider: "smarthop",
		url: "api/consts/owners",
		multiple: true,
		preloadDefault: true,
	},
};

const SYSTEM_NEW_LOADS_ONLY = {
	key: "system_show_new_loads_only",
	type: "checkbox",
	label: "Show only new loads",
	checkbox: { noErrorMessage: true, classes: { root: " -mt-1 " } },
};

const SYSTEM_SHOW_PLAN_MATCH_ONLY = {
	key: "system_show_plan_match_only",
	type: "checkbox",
	label: "Show only loads that match my current truck plan",
	checkbox: { noErrorMessage: true, classes: { root: " -mt-1 " } },
};

const SYSTEM_SHOW_STRATEGIC_ONLY = {
	key: "system_show_strategic_only",
	type: "checkbox",
	label: "Show only loads that match my current strategy",
	checkbox: { noErrorMessage: true, classes: { root: " -mt-1 " } },
};

const SYSTEM_SHOW_ONLY_FACTORABLE = {
	key: "system_show_factorable_only",
	type: "checkbox",
	label: "Show only factorable loads",
	checkbox: { noErrorMessage: true, classes: { root: " -mt-1 " } },
};

const SYSTEM_SHOW_HAZMAT_LOADS_ONLY = {
	key: "system_show_hazmat_loads_only",
	type: "checkbox",
	label: "Show only hazmat loads",
	checkbox: { noErrorMessage: true, classes: { root: " -mt-1 " } },
};
const HIDE_INTERNATIONAL_LOADS = {
	key: "no_show_international_load",
	type: "checkbox",
	label: "Hide International loads",
	checkbox: { noErrorMessage: true, classes: { root: " -mt-1 " } },
};

const SECTION_BROKERS_TITLE = {
	type: "section",
	label: "Brokers",
	section: { classes: { label: "mt-6" } },
};

const SEARCH_BROKERS = {
	key: "brokers_search",
	type: "text",
	label: "Brokers",
	resource: "SEARCH_BROKERS_POPUP",
	field: { noErrorMessage: true },
};

const SECTION_TYPE_TITLE = {
	type: "section",
	label: "Trailer",
	section: { classes: { label: "mt-6" } },
};

const LOAD_TYPE = {
	key: "load_type",
	type: "select",
	label: "Capacity",
	defaultValue: "FULL",
	field: {
		noErrorMessage: true,
		classes: {},
		variant: undefined,
	},
	options: [
		{
			value: "FULL",
			label: "Only Full",
		},
		{
			value: "PARTIAL",
			label: "Only Partial",
		},
		{
			value: "BOTH",
			label: "Partial and Full",
		},
	],
};

export {
	SECTION_SEARCH_TITLE,
	SECTION_TRUCK_TITLE,
	SECTION_TRUCK_SUBTITLE,
	SECTION_TRAILER_TITLE,
	SECTION_TRAILER_SUBTITLE,
	SECTION_DRIVER_TITLE,
	SECTION_DRIVER_SUBTITLE,
	SECTION_TRIP_TITLE,
	SECTION_ROUTE_TITLE,
	SECTION_TRIP_SUBTITLE,
	SECTION_PREFERENCES_TITLE,
	SECTION_PREFERENCES_SUBTITLE,
	SECTION_DESTINATION_TITLE,
	SECTION_DESTINATION_SUBTITLE,
	SECTION_INSTANT_BOOKING_TITLE,
	SECTION_INSTANT_BOOKING_SUBTITLE,
	SECTION_INSTANT_BOOKING_BOOK_NOW_SUBTITLE,
	SECTION_INSTANT_BOOKING_BID_NOW_SUBTITLE,
	SECTION_FILTERS_TITLE,
	SECTION_FILTERS_SUBTITLE,
	CARRIER_ASSIGNED,
	CARRIER_ALL,
	CARRIER_TRUCKS,
	DRIVER_TRUCKS,
	TRUCK_ASSIGNED,
	TRUCK_ALL,
	DRIVER_MY,
	DRIVER_VIEW,
	LOCATION_ORIGIN,
	DH_ORIGIN,
	LOCATION_DESTINATION,
	LOCATION_MULTI_DESTINATION,
	DH_DESTINATION,
	DESTINATION_STATES,
	PICKUP_AFTER_DS,
	PICKUP_BEFORE_DS,
	DELIVERY_AFTER_DS,
	DELIVERY_BEFORE_DS,
	DRIVER_BLAKLISTED_STATES,
	DRIVER_AVAILABLE_HOURSE,
	DRIVER_WEEKLY_HOURSE,
	TRAILER_EQUIPMENT,
	TRAILER_TYPE,
	TRAILER_MAX_WEIGHT,
	RATE_TOTAL_PREFERRED,
	RATE_PER_MILE_PREFERRED,
	MILES_PREFERRED,
	PROFIT_PREFERRED,
	AGE_PREFERRED,
	MIN_RANK_MCI,
	MIN_CREDIT_SCORE,
	EXTRA_STOPS_PREFERRED,
	SECTION_SYSTEM_TITLE,
	SECTION_SYSTEM_SUBTITLE,
	SYSTEM_SHOW_FLEXIBLE,
	SYSTEM_SHOW_DETAILED_CONTENT,
	SYSTEM_ON_DEMAND_RESULT_ONLY,
	SYSTEM_SHOW_ESTIMATE_RATES,
	SYSTEM_SHOW_OUTLIERS_ONLY,
	SYSTEM_BID_NOW_RESULT_ONLY,
	SYSTEM_SHOW_ONLY_ONBOARDED,
	SYSTEM_SHOW_TEAM_LOADS_ONLY,
	SYSTEM_SHOW_SPECIAL_LOAD,
	SYSTEM_SPECIFIC_OWNERS_ONLY,
	SYSTEM_NEW_LOADS_ONLY,
	SYSTEM_SHOW_WITH_PRICE_ONLY,
	SYSTEM_SHOW_PLAN_MATCH_ONLY,
	SYSTEM_SHOW_STRATEGIC_ONLY,
	SYSTEM_SHOW_ONLY_FACTORABLE,
	SYSTEM_SHOW_HAZMAT_LOADS_ONLY,
	SECTION_BROKERS_TITLE,
	SEARCH_BROKERS,
	SECTION_TYPE_TITLE,
	LOAD_TYPE,
	HIDE_INTERNATIONAL_LOADS,
	SEARCH_TRAILER_SPECIAL_FIELDS,
};
