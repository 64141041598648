import moment from "moment";
import { Icon, Typography } from "@material-ui/core";
import internalQueueListFilters from "./internalQueueListFilters";
import { authRoles } from "app/main/consts";
import { formatAge } from "app/main/utils/dateUtils";

const PROD_DB = process.env.REACT_APP_ENV === "PRD" || process.env.REACT_APP_ENV === "TEST";

const internalQueueList = (
	role,
	minified = false,
	readOnly = false,
	isMainHandler = false,
	hasPermissionSearch = true,
	diffHeight = null
) => {
	const isInternal = authRoles.internalUsers.includes(role);
	const isCarrier = authRoles.externalUsers.includes(role);
	const isDriver = authRoles.driver.includes(role);

	const filters = internalQueueListFilters(isInternal, isMainHandler);

	return {
		urlGET: "api/profile/trucks",
		idKey: "_id",
		listenEvent: "profileRevision",
		segmentKey: "INTERNAL_QUEUE",
		content: {
			grid: {
				tilesPerRow: 2,
				itemsPerRow: 2,
			},
			table: {
				variant: "skinny",
				orderBy: [
					"plan__view.metadata.createdAt",
					"plan__view.metadata.current_available_date",
					"carrier__view.label",
					"dispatcher__view.label",
					"plan__view.metadata.bestTimeToBook",
				],
				diffHeight: diffHeight,
			},
			pagination: {
				defaut: { size: 10 },
				enabled: true,
				resetScroll: true,
			},
			order: {
				defaut: {
					key: isCarrier ? "dispatcher__view.value" : "plan__view.metadata.current_available_date",
					dir: isCarrier ? "desc" : "asc",
				},
			},
			defaults: {
				// Initial value of fields
				initial: {
					filters: {
						truck_active: "ACTIVE",
						trip_availability: "__NOT_SELECTED__",
						...(isCarrier || isDriver
							? {}
							: {
									carrier_active: "ACTIVE",
									carriers_type: PROD_DB ? "REAL_CARRIERS" : "__NOT_SELECTED__",
									status_truck_plan: "__CURRENT__",
									truck_verified: "__NOT_SELECTED__",
							  }),
					},
					groups: {},
				},
				// Additional quick filters
				items: minified
					? []
					: [
							{
								label: "All",
								filters: {
									trip_availability: "__NOT_SELECTED__",
									...(isCarrier ? {} : { status_truck_plan: "__CURRENT__" }),
								},
								sort: { key: "plan__view.metadata.current_available_date", dir: "asc" },
							},
							{
								label: "Upcoming",
								filters: {
									trip_availability: "UPCOMING",
									...(isCarrier ? {} : { status_truck_plan: "__CURRENT__" }),
								},
								sort: { key: "plan__view.metadata.current_available_date", dir: "asc" },
							},
							{
								label: "Outdated",
								filters: {
									trip_availability: "PAST",
									...(isCarrier ? {} : { status_truck_plan: "__CURRENT__" }),
								},
								sort: { key: "plan__view.metadata.current_available_date", dir: "asc" },
							},
					  ],
			},
			filters: {
				search: !minified,
				items: minified ? [] : filters,
			},
			export: minified
				? null
				: {
						fileName: "Load Dashboard - Queue",
				  },
			groups: {
				items:
					minified || isCarrier
						? []
						: [
								{
									key: "internal",
									type: "checkbox",
									label: "Show Internal Fields",
								},
						  ],
			},
			items: [
				{ key: "_id", type: "id", label: "Truck ID", group: "internal" },
				{ key: "carrier", type: "id", label: "Carrier ID", group: "internal" },
				...(isInternal || isMainHandler
					? [
							{
								key: "carrier__view.label",
								builder: (item) => item.carrier__view,
								type: "carrier__view",
								label: "Carrier",
								viewConfig: {
									readOnly,
									showMcNumber: true,
									showCarrierAccountType: true,
									showTestCarrier: true,
								},
								table: {
									width: 240,
								},
							},
					  ]
					: []),
				{
					key: "truck__view",
					type: "truck__view",
					label: "Truck",
					viewConfig: {
						readOnly,
						showPhone: false,
						showTeam: true,
						showIfNew: !isCarrier,
						showStatus: !isCarrier,
					},
					table: { width: 260 },
				},
				{ type: "separator" },
				{
					key: "active_trips__view",
					builder: (item) => item.active_trips__view,
					type: "trips__view",
					label: "Active Trips",
					viewConfig: {
						readOnly,
						showStatus: true,
					},
				},
				{ type: "separator" },
				{ key: "plan__view.value", type: "id", label: "Plan ID", group: "internal" },
				{ key: "plan__view.metadata.id", type: "text", label: "Plan Id", group: "internal" },
				{ key: "plan__view.metadata.createdAt", type: "date", label: "Plan Created", group: "internal" },
				{
					key: "plan__view",
					builder: (item) => (item.plan__view?.value ? item.plan__view : { carrier: item.carrier, truck: item.truck }),
					type: "plan__view",
					label: "Next Plan",
					viewConfig: {
						readOnly,
						showStatus: true,
						showMPI: true,
					},
					table: { width: 220 },
				},
				{
					key: "chat__view",
					type: "chat__view",
					label: "Chat Overview",
					viewConfig: {
						readOnly,
						showMetadata: true,
					},
					group: "chat_info",
					hidden: isCarrier,
				},
				{
					key: "searches__view",
					type: "searches__view",
					label: "Search Overview",
					viewConfig: {
						readOnly,
						showMetadata: true,
					},
					group: "chat_info",
					hidden: isCarrier,
				},
				{
					key: "plan__view.metadata.reminder_sent_ds",
					type: "date",
					group: "plan_reminder",
					label: "Plan Reminder",
				},
				{
					key: "plan__view.metadata.confirmation_sent_ds",
					type: "date",
					group: "plan_confirm",
					label: "Plan Confirmed",
				},
				{
					key: "lastLocation.location",
					type: "component",
					label: "Last Location",
					builder: (item) => {
						if (!item?.lastLocation?.lastUpdated || !item?.lastLocation?.location) {
							return "";
						}
						return (
							<div className="flex flex-col px-8 py-2 w-full justify-left">
								<Typography className="text-12 ml:text-13">{item?.lastLocation?.location}</Typography>
								<div className="flex flex-row justify-left items-center w-full">
									<Icon className="text-12 ml:text-13 mr-4">cached</Icon>
									<Typography className="text-12 ml:text-13">{formatAge(item.lastLocation.lastUpdated)}</Typography>
								</div>
							</div>
						);
					},
				},
				{
					key: "plan__view.metadata.current_available_date",
					type: "component",
					label: "Available",
					table: { width: 150 },
					builder: (item) => {
						const available = item.plan__view?.metadata?.current_available_date
							? moment.utc(item.plan__view?.metadata?.current_available_date)
							: null;

						const thisYear = moment().isSame(available, "year");

						const daysDiff = available
							? moment().utc().startOf("day").diff(moment(available).startOf("day"), "days", false)
							: null;

						return available ? (
							<div className="flex flex-col">
								<div className="flex flex-row justify-end">
									<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 text-12">
										{available.format(thisYear ? "MM/DD" : "MM/DD/YYYY")}
									</Typography>
									{thisYear && (
										<Typography className="break-words text-grey whitespace-normal tracking-wide leading-4 mb-2 text-12 ml:text-13 ml-5">
											{available.format("ha")}
										</Typography>
									)}
								</div>
								<Typography
									className={
										"break-words whitespace-normal tracking-wide text-grey text-11 ml:text-12 -mt-4" +
										(daysDiff === 0
											? " text-green-700 "
											: daysDiff === 1
											? " text-orange-700 "
											: daysDiff > 1
											? " text-red "
											: " text-grey ")
									}
								>
									{daysDiff < -1
										? available.format("dddd")
										: daysDiff === -1
										? "Tomorrow"
										: daysDiff === 0
										? "Today"
										: daysDiff === 1
										? "Yesterday"
										: "Outdated"}
								</Typography>
							</div>
						) : null;
					},
				},
				...(isInternal
					? [
							{ type: "separator" },
							{
								key: "truck__view.metadata.strategy_id",
								dialogBuilder: (item) => ({
									viewId: "STRATEGY_VISUALIZER",
									dataIds: {
										strategyId: item.truck__view?.metadata?.strategy_id,
										truckId: item.truck,
										carrierId: item.carrier,
										entryPoint: "savedList",
									},
								}),
								builder: (item) => ({
									strategy: item.truck__view?.metadata?.strategy_id ? item.truck__view?.metadata?.strategy_id : null,
								}),
								label: "Current Strategy",
								maxLengthStart: 25,
								export: {
									skip: true,
								},
							},
					  ]
					: []),
				{ type: "separator" },
				{
					key: "plan__view.metadata.bestTimeToBook",
					type: "component",
					label: "Best To Book",
					table: { width: 140 },
					builder: (item) => {
						const bestTimeToBook = item?.plan__view?.metadata?.bestTimeToBook
							? moment(item?.plan__view?.metadata?.bestTimeToBook)
							: null;
						const available = item.plan__view?.metadata?.current_available_date__pretty
							? moment(item.plan__view?.metadata?.current_available_date__pretty)
							: null;

						const daysDiff =
							available && bestTimeToBook
								? bestTimeToBook.startOf("day").diff(moment().startOf("day"), "days", false)
								: null;

						return bestTimeToBook && !item.plan__view?.metadata?.outdated__flag ? (
							<div className="flex flex-col">
								<Typography className="break-words whitespace-normal tracking-wide leading-4 mb-2 text-12 ml:text-13">
									{bestTimeToBook.format("MM/DD")}
								</Typography>
								<Typography
									className={
										"break-words whitespace-normal tracking-wide text-11 ml:text-12 -mt-4" +
										(daysDiff === 0
											? " text-green-700 "
											: daysDiff === -1
											? " text-orange-700 "
											: daysDiff < -1
											? " text-red "
											: " text-grey ")
									}
								>
									{daysDiff < -1
										? `${daysDiff * -1} Days Ago`
										: daysDiff === -1
										? "Yesterday"
										: daysDiff === 0
										? "Today"
										: daysDiff === 1
										? "Tomorrow"
										: bestTimeToBook.format("dddd")}
								</Typography>
							</div>
						) : null;
					},
				},
				{ type: "separator" },
				{
					key: "postings",
					type: "plan__view",
					label: "Postings",
					builder: (item) => item.plan__view,
					viewConfig: {
						readOnly,
						mode: "POSTINGS",
					},
					table: { width: 140 },
				},
				{ type: "separator" },
				{
					key: "load_scout",
					type: "plan__view",
					label: "Load Scout",
					builder: (item) => item.plan__view,
					viewConfig: {
						readOnly,
						mode: "LOAD_SCOUNT",
					},
					table: { width: 140 },
				},
				...(readOnly || !hasPermissionSearch
					? []
					: [
							{ type: "separator" },
							{
								key: "menu",
								type: "menu",
								table: {
									width: 50,
									sticky: true,
								},
								items: [
									{
										key: "search_load",
										label: "Search",
										dialogBuilder: (item) => ({
											viewId: "SEARCH_REQUEST",
											dataIds: {
												carrierId: item.carrier,
												truckId: item?._id.toString(),
												eventType: "SEARCH_SIMILAR",
											},
										}),
									},
									{
										variant: "divider",
									},
									{
										key: "edit_plan",
										label: "Edit Plan",
										dialogBuilder: (item) => ({
											viewId: "TRUCK_PLAN",
											mode: "EDIT",
											dataIds: {
												carrierId: item.carrier,
												truckId: item?._id.toString(),
												planId: item.plan__view?.value,
											},
										}),
									},
									{
										key: "edit_truck",
										label: "Edit Truck",
										dialogBuilder: (item) => ({
											viewId: "TRUCK_VIEW",
											mode: "EDIT",
											dataIds: {
												carrierId: item.carrier,
												truckId: item?._id.toString(),
												id: item?._id.toString(),
											},
										}),
									},
								],
							},
					  ]),
			],
		},
	};
};

export default internalQueueList;
